import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

/**
 * @return {null}
 */
export default function MessageModal({heading, onDismissed, children, size, show = true}) {
  return (
    <Modal size={size || 'lg'} onHide={onDismissed} centered scrollable show={show}>
      <Modal.Header><h3 className="fw-bold">{heading}</h3></Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onDismissed} variant="outline-primary">OK</Button>
      </Modal.Footer>
    </Modal>
  );
}
