import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';

import useConvertVendor from '../hooks/useConvertVendor';

import FormGroup from './FormGroup';
import VendorLookup from './VendorLookup';

export default function VendorConversionDialog({vendor, onClose}) {
  const [validated, setValidated] = useState(false);
  const [newVendor, setNewVendor] = useState(null);
  const {convertVendorToAlias, convertAliasToVendor, conversionLoading} = useConvertVendor();

  const {name, isAlias, purchaseStats, aliases} = vendor;

  async function handleSubmit(e) {
    e.preventDefault();
    setValidated(true);

    if (!isAlias && !newVendor) {
      return;
    }

    let promise;
    if (!isAlias) {
      promise = convertVendorToAlias(vendor, newVendor);
    } else {
      promise = convertAliasToVendor(vendor);
    }

    const {error} = await promise;
    if (!error) {
      onClose();
    }
  }

  const title = `Convert ${isAlias ? 'Alias' : 'Vendor'} to ${isAlias ? 'Vendor' : 'Alias'}`;
  return (
    <Modal onHide={onClose} show animation>
      <Form validated={validated} onSubmit={handleSubmit} noValidate>
        <Modal.Header>
          <Modal.Title><h4 className="fw-bold">{title}</h4></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row as="dl">
            <Col as="dt" sm={4}>{isAlias ? 'Alias' : 'Vendor'}</Col>
            <Col as="dd" sm={8}>{name}</Col>
            <Col as="dt" sm={4}>Purchase Count</Col>
            <Col as="dd" sm={8}>{purchaseStats.purchaseCount}</Col>
          </Row>
          {
            !isAlias &&
            <Row>
              <FormGroup controlId="vendor" label="Target vendor">
                <VendorLookup vendor={newVendor} onVendorSelect={setNewVendor} required
                              filter={aVendor => !aVendor.isAlias && aVendor.id !== vendor.id}/>
                <Form.Control.Feedback type="invalid">Vendor is required</Form.Control.Feedback>
              </FormGroup>
            </Row>
          }
          {
            aliases.length > 0 &&
              <Card>
                <Card.Body>
                  This vendor has {aliases.length} aliases associated with it. Converting it to an alias
                  will re-associate those aliases with the target vendor.
                </Card.Body>
              </Card>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" disabled={conversionLoading}>
            Convert to {isAlias ? 'Vendor' : 'Alias'}
          </Button>
          <Button variant="outline-primary" onClick={onClose} disabled={conversionLoading}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
