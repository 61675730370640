import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Logo from './Logo';

function UnauthRow({children}) {
  return (<Row className="justify-content-center mt-5" children={children}/>);
}

export default function UnathenticatedPage({children}) {
  return (
    <Container className="justify-content-center align-items-end vh-10">
      <UnauthRow>
        <Col xs={4} sm={4} md={3} lg={2} xl={2}>
          <Logo/>
        </Col>
      </UnauthRow>
      {children}
    </Container>
  );
}

UnathenticatedPage.Row = UnauthRow;

UnathenticatedPage.Card = function UnauthCard({children}) {
  return (
    <UnathenticatedPage.Row>
      <Col className="bg-white border rounded" xs={10} sm={10} md={8} lg={6} xl={4} children={children}/>
    </UnathenticatedPage.Row>
  );
};
