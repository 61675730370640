import React, {useCallback, useEffect} from 'react';

import useSchools from '../hooks/useSchools';
import ACMSelect from './ACMSelect';

export default function ClientLookup({client, onClientSelect, disabled, abbreviated, ...props}) {
  const {schools, schoolsLoading} = useSchools();

  useEffect(() => {
    if (!schools) {
      return;
    }

    if (schools.length === 1) {
      onClientSelect(schools[0]);
    }
  }, [schools, onClientSelect]);

  const renderLabel = useCallback(function _renderLabel({name, code}) {
    return abbreviated
           ? code
           : name;
  }, [abbreviated]);

  const vDisabled = disabled || schools?.length === 1;
  return (
    <ACMSelect placeholder="Select Client" selected={client} values={schools} disabled={vDisabled}
               isLoading={schoolsLoading} onSelect={onClientSelect} renderLabel={renderLabel} {...props}/>
  );
}
