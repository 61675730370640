import React, {useContext} from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import FormCheck from 'react-bootstrap/FormCheck';
import {faLock} from '@fortawesome/free-solid-svg-icons';
import {faUnlock, faWrench} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom'

import SessionContext from '../context/session';

import FormattedDate from './FormattedDate';
import PurchaseTypeIcon, {SFM_CC_PURCHASE_TYPE_ID} from './PurchaseTypeIcons';
import RouterButton from './RouterButton';
import SchoolCode from './SchoolCode';
import USCurrency from './USCurrency';
import EditButton from './EditButton';
import TrashButton from './TrashButton';
import PurchaseImage from './PurchaseImage';
import TruncatedText from './TruncatedText';

/**
 * @return {null}
 */
export default function PurchaseTableRow(
  {purchase, selected, vendorCell, showTech, onImageClick, onEdit, onDelete, onSelect}
) {
  const {user} = useContext(SessionContext);
  if (!user) {
    return null;
  }
  const {
    id, isSplit, date, school, job, task, amount, notes, purchaseImages, technician, purchaseType, bank
  } = purchase;
  const {code} = school || {};
  const {name:jobName} = job || {};
  const {assignment} = task || {};
  const {workOrder} = assignment || {};
  const {id:workOrderID, abbreviation, number} = workOrder || {};

  let bankName = null;
  if (SFM_CC_PURCHASE_TYPE_ID === purchaseType.id && user.canQueryAllPurchases()) {
    bankName = bank && bank.name;
  }

  return (
    <tr>
      {
        onSelect &&
        <td>
          <FormCheck checked={selected} onChange={({target}) => onSelect(purchase, target.checked)}/>
        </td>
      }
      <td><FormattedDate date={date}/></td>
      {showTech && <TechCell technician={technician}/>}
      {isSplit && <td className="text-center text-danger" colSpan={2}>Split</td>}
      {!isSplit && (
        <>
          <td style={{maxWidth: '8em'}}>
            <SchoolCode code={code}/>
            <TruncatedText className="mb-0" id={`tool-tip-job-${id}`} maxWidth="8em" text={jobName}/>
          </td>
          <td>
            <Link to={`/app/work-orders/${workOrderID}`}>
              <SchoolCode code={abbreviation}/>
              {number}
            </Link>
          </td>
        </>
      )}
      <td style={{color: amount < 0 ? 'red' : 'black'}}><USCurrency amount={amount}/></td>
      <td>
        <PurchaseTypeIcon className="d-flex justify-content-center flex-shrink-1" size="lg"
                          purchaseType={purchaseType} id={`${id}-${purchaseType.id}`}/>
        <div className="fw-bold text-primary text-center">{bankName}</div>
      </td>
      <td>
        <TruncatedText id={`tool-tip-notes-${id}`} maxWidth="8em" text={notes}/>
      </td>
      <td>
        <PurchaseImage id={`purchase-images-${id}`} purchaseImages={purchaseImages}
                       onClick={onImageClick}/>
      </td>
      {vendorCell}
      <ActionsCell purchase={purchase} onEdit={onEdit} onDelete={onDelete}/>
    </tr>
  );
}

function TechCell({technician}) {
  const {firstName, lastName} = technician || {};

  return (<td>{firstName} {lastName}</td>);
}

function ActionsCell({purchase, onEdit, onDelete}) {
  const {user} = useContext(SessionContext);
  const {id, isSplit, isLocked} = purchase;

  let lockedIcon = (<FontAwesomeIcon icon={faUnlock}/>);
  if (isLocked) {
    lockedIcon = (<FontAwesomeIcon key="locked" icon={faLock}/>);
  }
  let editCell;
  if (onEdit && !isLocked && user.canUpdatePurchase(purchase, isLocked)) {
    editCell = (<EditButton key="edit" id={`edit-${id}`} onClick={() => onEdit(purchase)}/>);
  }
  let manageCell;
  if (isSplit && user.canSplitPurchases()) {
    manageCell = (
      <OverlayTrigger key="manage" placement="auto" flip
                      overlay={<Tooltip id={`copy-purchase-${id}`}>Manage Split</Tooltip>}>
        <RouterButton variant="outline-light" to={`/app/purchases/manageSplit/${id}`}>
          <FontAwesomeIcon className="text-primary" size="lg" icon={faWrench}/>
        </RouterButton>
      </OverlayTrigger>
    );
  }

  let trashCell;
  if (onDelete && user.canDeletePurchase(purchase, isLocked)) {
    trashCell = (<TrashButton key="delete" id={`delete-${id}`} onClick={() => onDelete(purchase)}/>);
  }
  return (
    <td className="text-nowrap">
      {[editCell, manageCell, trashCell, isLocked && lockedIcon]}
    </td>
  );
}

