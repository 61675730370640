import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';
import {Navigate} from 'react-router-dom';
import {faExchangeAlt, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import DeleteVendorDialog from '../../components/DeleteVendorDialog';
import VendorConversionDialog from '../../components/VendorConversionDialog';

function AliasRow({vendor, onDelete, onConvert}) {
  const {purchaseStats, id} = vendor;
  const [aliasID, setAliasID] = useState(null);

  if(aliasID){
    return (<Navigate to={`/app/purchases/vendor/${aliasID}`}/>);
  }

  return (
    <tr onClick={() => setAliasID(id)}>
      <td>{vendor.name}</td>
      <td>{purchaseStats.purchaseCount}</td>
      <td>
        <OverlayTrigger placement="auto" flip
                        overlay={(<Tooltip id={`delete-${id}`}>Delete</Tooltip>)}>
          <Button variant="outline-light" type="button" onClick={onDelete}>
            <FontAwesomeIcon className="text-primary" size="lg" icon={faTrashAlt}/>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger placement="auto" flip
                        overlay={(<Tooltip id={`convert-${id}`}>Convert to Vendor</Tooltip>)}>
          <Button variant="outline-light" type="button" onClick={onConvert}>
            <FontAwesomeIcon className="text-primary" size="lg" icon={faExchangeAlt}/>
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
}

export default function AliasList({aliases, onDelete, onConvert}) {
  const [vendorToDelete, setVendorToDelete] = useState(null);
  const [vendorToConvert, setVendorToConvert] = useState(null);

  function handleDelete(vendor) {
    return event => {
      event.stopPropagation();
      setVendorToDelete(vendor);
    };
  }

  function handleConvert(vendor) {
    return event => {
      event.stopPropagation();
      setVendorToConvert(vendor);
    };
  }

  function handleDeleted() {
    setVendorToDelete(null);
    onDelete();
  }

  function handleConverted() {
    setVendorToConvert(null);
    onConvert();
  }

  return (
    <>
      <Table responsive hover>
        <thead>
        <tr className="bg-secondary">
          <th>Alias</th>
          <th># of Purchases</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {
          aliases &&
          aliases.map(alias => (<AliasRow key={alias.id} vendor={alias} onDelete={handleDelete(alias)}
                                          onConvert={handleConvert(alias)}/>))
        }
        </tbody>
      </Table>
      {vendorToDelete && (<DeleteVendorDialog vendor={vendorToDelete} onClose={handleDeleted}/>)}
      {vendorToConvert && (<VendorConversionDialog vendor={vendorToConvert} onClose={handleConverted}/>)}
    </>
  );
}
