import React, {useContext, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {useLocation} from 'react-router-dom';
import {BooleanParam, encodeArray, encodeQueryParams} from 'use-query-params';
import queryParams from 'query-string';

import SessionContext from '../../context/session';

import PurchaseFilterForm from '../../components/PurchaseFilterForm';
import PurchaseFilterPills from '../../components/PurchaseFilterPills';
import {purchaseOrderBy} from '../../components/PurchaseTable';
import FilteredListHeader from '../../components/FilteredListHeader';
import USCurrency from '../../components/USCurrency';

import MinPurchaseDateDialog from './MinPurchaseDateDialog';

const OrderByParam = {
  encode(value) {
    return value && encodeArray(value.map(({attribute, direction}) => `${attribute}~${direction}`));
  }
};

const PARAM_CONFIG = {
  orderBy: OrderByParam,
  resolveAliases: BooleanParam
};

/**
 * @return {null}
 */
export default function Header({orderBy, amountSum, billingTotalSum, bulkDeleteEnabled, onNewPurchase, onBulkDelete}) {
  const [showMinPurchaseDateDialog, setShowMinPurchaseDateDialog] = useState(false);
  const {search} = useLocation();
  const {user} = useContext(SessionContext);

  if (!user) {
    return null;
  }
  const encodedQPs = encodeQueryParams(PARAM_CONFIG, {resolveAliases: true, orderBy: purchaseOrderBy(orderBy)});
  const orderByQS = queryParams.stringify(encodedQPs);
  let exportQS = search || '?';
  if (search) {
    exportQS += '&';
  }
  exportQS += orderByQS;

  return (
    <FilteredListHeader title="Purchases"
                        FilterPills={<PurchaseFilterPills/>}
                        FilterForm={<PurchaseFilterForm/>}
                        Actions={
                          <DropdownButton id="actions" title="Actions" drop="start">
                            <Dropdown.Item href={`/rest/purchases${exportQS}`}>Export</Dropdown.Item>
                            {
                              user.canSetEarliestPurchaseDate() &&
                              <Dropdown.Item as="button" onClick={() => setShowMinPurchaseDateDialog(true)}>
                                Disallow Purchases Before...
                              </Dropdown.Item>
                            }
                            {
                              user.canDeleteAnyPurchase() &&
                              <Dropdown.Item as="button" onClick={onBulkDelete} disabled={!bulkDeleteEnabled}>
                                Delete Selected Purchases...
                              </Dropdown.Item>
                            }
                            <Dropdown.Item as="button" onClick={onNewPurchase}>
                              New Purchase
                            </Dropdown.Item>
                          </DropdownButton>
                        }>
      <Row>
        <Col sm={{span: 3, offset: 6}}>
          <span className="fw-bold">Total Receipts: </span><USCurrency amount={amountSum}/>
        </Col>
        <Col sm={3}>
          <span className="fw-bold">Total Receipts plus Markup: </span><USCurrency amount={billingTotalSum}/>
        </Col>
      </Row>
      {
        showMinPurchaseDateDialog &&
        <MinPurchaseDateDialog onClose={() => setShowMinPurchaseDateDialog(false)}/>
      }
    </FilteredListHeader>
  );
}
