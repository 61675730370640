import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Views} from 'react-big-calendar';

import TimeSheetsContext from '../context/TimeSheetsContext';

const DEFAULT_GUTTER_SIZE = 81;
const DEFAULT_EVENT_CONTAINER_SIZE = 167;

function getGutterWidth(gutter) {
  return gutter?.clientWidth ?? DEFAULT_GUTTER_SIZE;
}

function getEventContainerWidth(eventContainer) {
  if (!eventContainer) {
    return DEFAULT_EVENT_CONTAINER_SIZE;
  }
  const {marginRight, width} = window.getComputedStyle(eventContainer);
  return parseFloat(marginRight) + parseFloat(width);
}

export default function useCalendarViewSizeObserver(calendarContainerRef){
  const [gutterWidth, setGutterWidth] = useState(`${DEFAULT_GUTTER_SIZE}px`);
  const [eventContainerWidth, setEventContainerWidth] = useState(`${DEFAULT_EVENT_CONTAINER_SIZE}px`);
  const {calendarView} = useContext(TimeSheetsContext);

  const gutterObserverCallback = useCallback(function _gutterObserverCallback(entries) {
    for (const entry of entries) {
      setGutterWidth(`${getGutterWidth(entry.target)}px`);
    }
  }, [setGutterWidth]);

  const eventContainerObserverCallback = useCallback(function _eventContainerObserverCallback(entries) {
    for (const entry of entries) {
      setEventContainerWidth(`${getEventContainerWidth(entry.target)}px`);
    }
  }, [setEventContainerWidth]);

  useEffect(() => {
    const {current} = calendarContainerRef;
    let result;
    if (current && [Views.WEEK, Views.DAY].includes(calendarView)) {
      const gutter = current.querySelector('.rbc-time-header-gutter');
      const eventContainer = current.querySelector('.rbc-events-container');

      let gutterObserver;
      if (gutter) {
        setGutterWidth(`${getGutterWidth(gutter)}px`);
        gutterObserver = new ResizeObserver(gutterObserverCallback);
        gutterObserver.observe(gutter);
      }
      let eventContainerObserver;
      if (eventContainer) {
        setEventContainerWidth(`${getEventContainerWidth(eventContainer)}px`);
        eventContainerObserver = new ResizeObserver(eventContainerObserverCallback);
        eventContainerObserver.observe(eventContainer);
      }

      result = () => {
        gutterObserver?.disconnect();
        eventContainerObserver?.disconnect();
      };
    }
    return result;
  }, [
    calendarContainerRef, calendarView, gutterObserverCallback, eventContainerObserverCallback, setGutterWidth,
    setEventContainerWidth
  ]);

  return useMemo(
    () => ({gutterWidth, eventContainerWidth}),
    [gutterWidth, eventContainerWidth]
  );
}