import React from 'react';

import SFMFilterPill from './SFMFilterPill';
import FormattedDate from './FormattedDate';

export default function DateRangePill({key, startDate, endDate, onClearDateFilter}){
  if(!(startDate && endDate)){
    return null;
  }

  return (
    <SFMFilterPill key={key} onRemove={onClearDateFilter}>
      <FormattedDate date={startDate}/> - <FormattedDate date={endDate}/>
    </SFMFilterPill>
  );
}