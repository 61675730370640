import React, {useContext, useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row';

import LightboxContext from '../context/LightboxContext';

import ACMFileChooser from './ACMFileChooser';
import Thumbnail from './Thumbnail';

const FILE_TYPES = {'image/jpg': ['.jpeg', '.jpg'], 'application/pdf': ['.pdf']};

function areImageArraysEqual(imageArray1, imageArray2) {
  if (imageArray1 === imageArray2) {
    return true;
  }
  if (!imageArray1
    || !imageArray2
    || (imageArray1.length !== imageArray2.length)) {
    return false;
  }
  let result = true;
  for (let i = 0; i < imageArray1.length; i++) {
    if (!(result = imageArray1[i] === imageArray2[i])) {
      break;
    }
  }
  return result;
}

function createFileFromBlob(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    function loadListener() {
      reader.removeEventListener('load', loadListener);
      resolve(reader.result);
    }

    reader.addEventListener('load', loadListener);
    try {
      reader.readAsDataURL(blob);
    } catch (e) {
      reject(e);
    }
  });
}

async function createThumbnail(image, onDelete, onClick) {
  let id = image.id;
  let src = image.uri;
  if (image instanceof Blob) {
    const file = await createFileFromBlob(image);
    id = file;
    src = file;
  }
  return (
    <Thumbnail key={id} src={src} onDelete={() => onDelete(image)} onClick={() => onClick(image)}/>
  );
}

export default function ImageSelect({images, required = false, disabled, onSelect, onDelete}) {
  const {setLightboxImages, setActiveIndex} = useContext(LightboxContext);
  const [thumbnails, setThumbnails] = useState(new Map());

  useEffect(() => {
    if (areImageArraysEqual(images, Array.from(thumbnails.keys()))) {
      return;
    }

    function handleClickThumbnails(activeIndex) {
      return async () => {
        let lImages = await Promise.all(images?.map(i => i instanceof Blob ? createFileFromBlob(i) : i));
        setActiveIndex(activeIndex);
        setLightboxImages(lImages);
      };
    }

    async function createThumbnails() {
      const thumbnailPromises = images.map(async (image, i) =>
        [image, await createThumbnail(image, onDelete, handleClickThumbnails(i))]);
      setThumbnails(new Map(await Promise.all(thumbnailPromises)));
    }

    // noinspection JSIgnoredPromiseFromCall
    createThumbnails();
  }, [images, thumbnails, onDelete, setActiveIndex, setLightboxImages]);


  let validity = '';
  if (required) {
    validity = images && images.length ? 'is-valid' : 'is-invalid';
  }
  return (
    <ACMFileChooser className={`sfm-file sfm-${validity}`} fileType={FILE_TYPES}
                    disabled={disabled} onChosen={onSelect} multiple
                    displayComponent={<DisplayComponent thumbnails={thumbnails}/>}/>
  );
}

function DisplayComponent({thumbnails}) {

  return (
    <Row className="py-2 w-100 justify-content-center">
      {Array.from(thumbnails.values())}
    </Row>
  );
}