import React from 'react';
import {gql, useQuery} from '@apollo/client';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SFMNotice from '../../components/SFMNotice';

const UNKNOWN_VENDOR_QUERY = gql`
    query purchasesConnection{
        purchasesConnection(vendorID:null){
            total
        }
    }
`;

/**
 * @return {null}
 */
export default function UnknownVendorNotice() {
  const {loading, error, data} = useQuery(UNKNOWN_VENDOR_QUERY, {fetchPolicy: 'cache-and-network'});
  if (loading) {
    return null;
  }

  if (error && (!data || !data.length)) {
    throw error;
  }

  const {purchasesConnection} = data;
  return (
    <Row className="flex-shrink-0">
      <Col sm={12}>
        {
          /**
           * YELLOWTAG:TJH - the path /app/purchases/vendor really should be refactored to
           * /app/purchases?vendorIsNull
           */
        }
        <SFMNotice to="/app/purchases/vendor/" show={purchasesConnection.total}
                   msg={`${purchasesConnection.total} Purchases with unknown vendors to resolve`}/>
      </Col>
    </Row>
  );
}
