import React, {useState} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Navigate} from 'react-router-dom';

import useResetPassword from '../../hooks/useResetPassword';

import MessageModal from '../../components/MessageModal';
import UnauthenticatedPage from '../../components/UnauthenticatedPage';
import ResetPasswordForm from './ResetPasswordForm';

export default function ResetPassword() {
  const [resetPassword, {resetPasswordSuccess, resetPasswordLoading}] = useResetPassword();
  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    return (<Navigate to="/app/login" replace/>);
  }

  return (
    <UnauthenticatedPage>
      <UnauthenticatedPage.Card>
        <Row className="p-4">
          <Col>
            <h4 className="mt-3 fw-bold">Reset Password</h4>
            <p>Enter your new password</p>
            <ResetPasswordForm disabled={resetPasswordLoading} onSubmit={resetPassword}/>
          </Col>
        </Row>
      </UnauthenticatedPage.Card>
      {resetPasswordSuccess && (
        <MessageModal heading="Info" size="md" onDismissed={() => setRedirect(true)}>
          You've successfully reset your password
        </MessageModal>
      )}
    </UnauthenticatedPage>
  );
}
