import moment from 'moment';
import {useCallback, useEffect, useState} from 'react';

import useWorkOrderStatuses, {CLOSED_ID, DISCARDED_ID} from './useWorkOrderStatuses';
import useWorkOrderTypes from './useWorkOrderTypes';
import useWorkOrderDivisions from './useDivisions';

export default function useWorkOrderState(workOrder, fetchedTasks) {
  const [school, setSchool] = useState(workOrder?.school);
  const [requestDate, setRequestDate] = useState(
    workOrder?.requestDate
    ? moment(workOrder.requestDate)
    : moment()
      .year(1970)
      .startOf('year')
  );
  const [request, setRequest] = useState(workOrder?.request);
  const [name, setName] = useState(workOrder?.name);
  const [workOrderType, setWorkOrderType] = useState(workOrder?.workOrderType);
  const [division, setDivision] = useState(workOrder?.division);
  const [status, internalSetStatus] = useState(workOrder?.status);
  const [priority, setPriority] = useState(workOrder?.priority);
  const [proposedWOIs, setProposedWOIs] = useState([]);
  const [deletedWOIs, setDeletedWOIs] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [editErrorMsg, setEditErrorMsg] = useState(null);
  const [comments, setComments] = useState([]);
  const [tasks, setTasks] = useState([]);

  const {defaultWorkOrderStatus, workOrderStatusesLoading} = useWorkOrderStatuses();
  const {workOrderTypes} = useWorkOrderTypes();
  const {divisions} = useWorkOrderDivisions();

  const isValid = requestDate && request && school;
  const existing = !!workOrder?.id;

  useEffect(() => {
    if (!workOrder?.id) {
      return;
    }
    setSchool(workOrder.school);
    setRequest(workOrder.request);
    setRequestDate(workOrder.requestDate && moment(workOrder.requestDate));
    setName(workOrder.name);
    setWorkOrderType(workOrder.workOrderType);
    setDivision(workOrder.division);
    internalSetStatus(workOrder.status);
    setPriority(workOrder.priority);

  }, [workOrder, setSchool, setRequestDate, setRequest, setName, setWorkOrderType, setDivision,
    internalSetStatus, setPriority]);

  useEffect(() => {
    if (workOrderStatusesLoading || existing || status || !defaultWorkOrderStatus) {
      return;
    }

    internalSetStatus(defaultWorkOrderStatus);
  }, [workOrderStatusesLoading, existing, status, defaultWorkOrderStatus, internalSetStatus]);

  useEffect(() => {
    if (!workOrder) {
      return;
    }
    if (!existing) {
      setIsDirty(!!(school || request || tasks.length || comments.length));
    } else {
      const mRequestDate = workOrder.requestDate && moment(workOrder.requestDate);
      setIsDirty(!(
        workOrder.school.id === school?.id
        && workOrder.priority === priority
        && workOrder.division?.id === division?.id
        && workOrder.status.id === status?.id
        && workOrder.request === request
        && workOrder.name === name
        && workOrder.workOrderType?.id === workOrderType?.id
        && (mRequestDate === requestDate || mRequestDate?.isSame(requestDate))
      ));
    }
  }, [
    workOrder, existing, setIsDirty, school, requestDate, request, name, workOrderType, division, status,
    priority, proposedWOIs, deletedWOIs, tasks, comments
  ]);

  useEffect(() => {
    if (workOrder && !existing) {
      setRequestDate(moment());
    }
  }, [workOrder, existing, setRequestDate]);

  useEffect(() => {
    if (existing || !divisions?.length || !workOrderTypes?.length) {
      return;
    }
    setDivision(division => division ? division : divisions[0]);
    setWorkOrderType(type => type ? type : workOrderTypes[0]);
  }, [existing, divisions, workOrderTypes, setDivision, setWorkOrderType]);

  const setStatus = useCallback(function _setStatus(pStatus) {
    const {laborCosts, materialCosts, customerMaterialCosts} = workOrder;
    let unassigned;
    if (pStatus.id === DISCARDED_ID
      && (laborCosts || materialCosts || customerMaterialCosts)) {
      setEditErrorMsg('A work order with time and/or materials charged to it cannot be discarded.');
    } else if (
      pStatus.id === CLOSED_ID
      && ( //YELLOWTAG:TJH - leaky abstraction
        (unassigned = (!tasks.length && !fetchedTasks.length))
        || fetchedTasks?.find(({isComplete}) => !isComplete)
      )) {
      const msg = unassigned
                  ? 'A work order without tasks cannot be closed.'
                  : 'A work order with incomplete tasks cannot be closed';
      setEditErrorMsg(msg);
    } else {
      internalSetStatus(pStatus);
    }
  }, [fetchedTasks, workOrder, setEditErrorMsg, internalSetStatus, tasks]);

  return {
    ...(workOrder || {}),
    school, setSchool,
    requestDate, setRequestDate,
    request, setRequest,
    name, setName,
    workOrderType, setWorkOrderType,
    division, setDivision,
    status, setStatus,
    priority, setPriority,
    proposedWOIs, setProposedWOIs,
    deletedWOIs, setDeletedWOIs,
    comments, setComments,
    tasks, setTasks,
    isValid, existing, isDirty,
    editErrorMsg, setEditErrorMsg
  };
}
