import {ApolloLink} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {createUploadLink} from 'apollo-upload-client';

const LINK_OPTIONS = {
  uri: `/graphql`,
  credentials: 'include',
  fetchOptions: {mode: 'cors'},
  headers: {
    'Apollo-Require-Preflight': 'true'
  }
};

const apolloLink = ApolloLink.from([
  onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({message, locations, path}) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }),
  createUploadLink(LINK_OPTIONS)
]);

export {apolloLink};
