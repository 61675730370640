import {useCallback} from 'react';

export default function useLocalStorage(key) {
  const getter = useCallback(() => {
    return JSON.parse(localStorage.getItem(key));
  }, [key]);

  const setter = useCallback((value) => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key]);

  return [getter, setter];
}