import React from 'react';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import CenteredLabel from '../../components/CenteredLabel';

export const ASSIGNED_TO_ME = 'assignedToMe';
export const ASSIGNED_TO_ANYONE = 'assignedToAnyone';
export const NOT_ASSIGNED = 'notAssigned';
export const ANY = 'any';

export default function AssignedToMeOrAnyoneButtonGroup({value, onChange, ...props}) {
  return (
    <ToggleButtonGroup size="sm" name="assignmentType" type="radio" value={value} onChange={onChange} {...props}>
      <ToggleButton id={ASSIGNED_TO_ANYONE} variant="outline-primary" value={ASSIGNED_TO_ANYONE}>
        <CenteredLabel label="Assigned"/>
      </ToggleButton>
      <ToggleButton id={ASSIGNED_TO_ME} variant="outline-primary" value={ASSIGNED_TO_ME}>
        <CenteredLabel label="Assigned to Me"/>
      </ToggleButton>
      <ToggleButton id={NOT_ASSIGNED} variant="outline-primary" value={NOT_ASSIGNED}>
        <CenteredLabel label="Not Assigned"/>
      </ToggleButton>
      <ToggleButton id={`assigned-any`} variant="outline-primary" value={ANY}>
        <CenteredLabel label="Any"/>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
