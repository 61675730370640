import React, {useEffect} from 'react';

import ACMSelect from './ACMSelect';

const VALUES = ['NONE', 'MINE', 'ALL'];
export default function TaskClosedPicker({selected, onSelect, ...props}) {

  function capitalize(value) {
    const firstLetter = value
      .charAt(0)
      .toUpperCase();
    const remaining = value
      .slice(1)
      .toLowerCase();
    return `${firstLetter}${remaining}`;
  }

  useEffect(() => {
    const vSelected = VALUES.find(v => v === selected?.toUpperCase()) ?? VALUES[0];
    if(vSelected !== selected){
      onSelect(vSelected)
    }
  }, [selected, onSelect]);

  return (
    <ACMSelect placeholder="Closed" selected={selected} values={VALUES}
               renderLabel={(name) => capitalize(name)} onSelect={onSelect} {...props}/>
  );
}