import React from 'react';

/**
 * @return {null}
 */
export default function SchoolCode({code}) {
  return code
    ? (
      <span className="fw-bold text-primary">{code}</span>
    )
    : null;
}
