import {useCallback, useMemo} from 'react';
import {gql, useMutation} from '@apollo/client';

const DELETE_TIMESHEET_MUTATION = gql`
    mutation deleteTimeSheet($id:Int!){
        deleteTimeSheets(ids:[$id]){
            message
        }
    }
`;

export default function useDeleteTimesheet(options) {
  const [gqlDeleteTimeSheet, mutationResponse] = useMutation(DELETE_TIMESHEET_MUTATION, options);

  const deleteTimeSheet = useCallback(async function _deleteTimeSheet(timeSheet) {
    const {id} = timeSheet;
    return gqlDeleteTimeSheet({
      variables: {id},
      update(cache) {
        const cacheID = cache.identify({id, __typename: 'TimeSheet'});
        cache.evict({id: cacheID});
        cache.gc();
      }
    });
  }, [gqlDeleteTimeSheet]);

  return useMemo(() => {
    const {data, loading, error} = mutationResponse;
    const response = data?.deleteTimeSheets;
    return [deleteTimeSheet, {
      deleteTimeSheetData: response,
      deleteTimeSheetLoading: loading,
      deleteTimeSheetError: error
    }];
  }, [deleteTimeSheet, mutationResponse]);
}