import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import VendorLookup from '../VendorLookup';

export default function VendorFormGroup(
  {
    vendor, vendorNotListed, disabled, onVendorChange, onVendorNotListedChange, ...props
  }) {

  return (
    <Form.Group controlId="vendor" as={Col} {...props}>
      <Form.Label>Vendor</Form.Label>
      <VendorLookup vendor={vendor} disabled={disabled || vendorNotListed} required={!vendorNotListed}
                    onVendorSelect={onVendorChange}/>
      <Form.Check type="checkbox" id="vendor-not-listed" label="Vendor not listed"
                  checked={vendorNotListed} required={!vendor}
                  onChange={({target}) => onVendorNotListedChange(target.checked)}/>
      <Form.Control.Feedback type="invalid">Vendor is required or affirmed not listed</Form.Control.Feedback>
    </Form.Group>
  );
}
