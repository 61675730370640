import {useContext, useMemo} from 'react';
import moment from 'moment';
import {Views} from 'react-big-calendar';

import TimeSheetsContext from '../context/TimeSheetsContext';
import {OVERTIME_HOURLY_PAY_TYPE_ALT_ID, UNPAID_PAY_TYPE_ALT_ID} from './usePayTypes';

function sumTotalTimeReducer(reduction, timeSheet) {
  const {totalTime} = timeSheet;
  const total = reduction;
  total.add(moment.duration(totalTime));
  return total;
}

export default function useTimeSheetTotals(timeSheets) {
  const {calendarDate, calendarView} = useContext(TimeSheetsContext);

  const payableTime = useMemo(() => {
    if (!timeSheets) {
      return null;
    }
    let result = timeSheets
      .filter(({payType}) => payType.altID !== UNPAID_PAY_TYPE_ALT_ID);
    if (calendarView === Views.DAY) {
      const targetDate = moment(calendarDate).startOf('day');
      result = result.filter(({clockIn}) =>
        moment(clockIn)
          .startOf('day')
          .isSame(targetDate));
    }
    return result;
  }, [timeSheets, calendarView, calendarDate]);

  const dailyTotals = useMemo(() => {
      if (!payableTime) {
        return null;
      }
      let result = null;
      if (calendarView === Views.WEEK) {
        result = payableTime
          .reduce((reduction, {clockIn, totalTime}) => {
            const dayOfWeek = moment(clockIn).weekday();
            const dailyTotal = reduction.get(dayOfWeek) ?? moment.duration(0);
            dailyTotal.add(moment.duration(totalTime));
            reduction.set(dayOfWeek, dailyTotal);
            return reduction;
          }, new Map());
      } else if (calendarView === Views.DAY) {
        result = payableTime
          .reduce(sumTotalTimeReducer, moment.duration(0));
      }

      return result;
    }, [payableTime, calendarView]
  );

  const regularPayTotal = useMemo(() => {
    if (!payableTime) {
      return null;
    }
    return payableTime
      .filter(({payType}) => payType.altID !== OVERTIME_HOURLY_PAY_TYPE_ALT_ID)
      .reduce(sumTotalTimeReducer, moment.duration(0));
  }, [payableTime]);

  const overtimeTotal = useMemo(() => {
    if (!payableTime) {
      return null;
    }
    return payableTime
      .filter(({payType}) => payType.altID === OVERTIME_HOURLY_PAY_TYPE_ALT_ID)
      .reduce(sumTotalTimeReducer, moment.duration(0));
  }, [payableTime]);

  return useMemo(
    () => ({dailyTotals, regularPayTotal, overtimeTotal}),
    [dailyTotals, regularPayTotal, overtimeTotal]
  );
}
