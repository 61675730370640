import {useCallback, useContext, useEffect, useState} from 'react';

import SessionContext from '../context/session';

import useLocalStorage from './useLocalStorage';
import useSchools from './useSchools';

export default function useJobTaskSchoolCoordinator(job, onSelectJob, onSelectTask) {
  const {user} = useContext(SessionContext);
  const {schools} = useSchools();
  const [school, internalSetSchool] = useState(job?.school || user.school);
  const [getDefaultJob] = useLocalStorage('defaultJob');

  useEffect(() => {
    if(job || !school){
      return;
    }
    const defaultJob = getDefaultJob();
    if(defaultJob?.schoolID === school.id){
      onSelectJob(defaultJob)
    }
  }, [job, school, getDefaultJob, onSelectJob]);

  useEffect(() => {
    if (job && !school && schools) {
      internalSetSchool(schools.find(({id}) => id === job.schoolID));
    }
  }, [job, schools, school, internalSetSchool]);

  const setSchool = useCallback((school) => {
    internalSetSchool(school);
    onSelectJob(null);
    onSelectTask(null);
  }, [internalSetSchool, onSelectJob, onSelectTask]);

  return [school, setSchool];
}