import {useState} from 'react';
import {gql, useQuery} from '@apollo/client';

import purchaseStatsFragment from '../graphql/purchase-stats-fragment';
import vendorFragment from '../graphql/vendor-fragment';
import usePagination from './usePagination';

const PAGE_SIZE = 10;

const VENDOR_PURCHASE_STATS = gql`
    query vendorPurchaseStats($pageSize: Int, $offset: Int) {
        vendorsConnection(pageSize:$pageSize, offset:$offset)
        @connection(key:"vendors")
        {
            next
            hasMore
            total
            vendors{
                ...VendorFields
                purchaseStats{
                    ...PurchaseStatsFields
                }
            }
        }
    }
    ${purchaseStatsFragment}
    ${vendorFragment}
`;

export default function useVendorPurchaseStats() {
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const {offset} = usePagination(pageSize);
  const {data, loading, error, refetch} = useQuery(VENDOR_PURCHASE_STATS, {
    variables: {pageSize, offset},
    fetchPolicy: 'cache-and-network'
  });

  const {vendorsConnection} = data || {};

  return {
    vendorsConnection, vendorsLoading: loading, vendorsError: error, refetchVendors: refetch, pageSize, setPageSize
  };
}
