import React from 'react';
import {faMoneyBillAlt, faUserCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function CustPurchaseIcon({size, ...props}) {
  return (
    <span className="fa-layers fa-fw" {...props}>
      <FontAwesomeIcon transform="up-2 left-1" icon={faMoneyBillAlt} size={size}/>
      <FontAwesomeIcon transform="shrink-5 right-12 down-6" icon={faUserCircle} size={size}/>
    </span>
  );
}
