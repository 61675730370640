import React, {useContext} from 'react';
import Card from 'react-bootstrap/Card';
import {faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import WorkOrderContext from '../../context/WorkOrderContext';

export default function Requester({header = false, cardClassName}) {
  const {requester} = useContext(WorkOrderContext);
  const {firstName, lastName, title, department, email, phone} = requester || {};
  const subtitle = [title, department]
    .filter(i => !!i)
    .join(', ');
  return (
    <Card className={cardClassName}>
      {
        header && (<Card.Header>Requester</Card.Header>)
      }
      <Card.Body>
        <Card.Title className="fw-bold">{firstName} {lastName}</Card.Title>
        <Card.Subtitle className="text-muted">{subtitle}</Card.Subtitle>
        <address className="py-2">
          <div>
            {
              phone && (
                <>
                  <FontAwesomeIcon className="mx-2" icon={faPhone}/>
                  <a href={`tel:${phone}`}>{phone}</a>
                </>
              )
            }
          </div>
          <div>
            {
              email && (
                <>
                  <FontAwesomeIcon className="mx-2" icon={faEnvelope}/>
                  <a href={`mailto:${email}`}>{email}</a>
                </>
              )
            }
          </div>
        </address>
      </Card.Body>
    </Card>
  );
}