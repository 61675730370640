import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

export default function FilterPills({clearDisabled, onClearQuery, children}) {

  return (
    <Container>
      <Row>
        {children}
        <Col className="mt-auto" sm>
          <Row className="justify-content-end">
            <div className="d-grid mt-auto">
              <Button className="ms-auto" variant="outline-primary" disabled={clearDisabled}
                      onClick={onClearQuery}>
                Reset Filters
              </Button>
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}