import {useState} from 'react';
import {gql, useQuery} from '@apollo/client';

import PurchaseList from '../graphql/purchase-list';
import usePagination from './usePagination';

const FILTERED_PURCHASES = gql`
    query purchasesConnection(
        $dateRange: QueryDateRange,
        $clientIDs:[ Int!],
        $purchaseTypeIDs: [Int!],
        $isSplit:Boolean,
        $bankIDs:[Int!],
        $accessID: Int,
        $technicianIDs:[Int!],
        $isReturn:Boolean,
        $jobIDs:[Int!],
        $workOrderIDs:[Int!],
        $pageSize:Int
        $offset:Int
        $orderBy:[OrderBy!]
    ) @connection(
        key:"purchases",
        filter:[
            "dateRange", "clientIDs", "purchaseTypeIDs", "isSplit", "bankIDs", "accessID", "technicianIDs", "isReturn",
            "jobIDs", "workOrderIDs"
        ]
    ) {
        purchasesConnection(
            dateRange:$dateRange,
            schoolIDs:$clientIDs
            purchaseTypeIDs:$purchaseTypeIDs
            isSplit:$isSplit
            bankIDs:$bankIDs
            accessID:$accessID
            technicianIDs:$technicianIDs
            isReturn:$isReturn,
            jobIDs:$jobIDs,
            workOrderIDs: $workOrderIDs,
            pageSize:$pageSize
            offset:$offset
            orderBy:$orderBy){
            next
            hasMore
            total
            amountSum
            billingTotalSum
            purchases{
                ...PurchaseListPurchase
            }
        }
    }
    ${PurchaseList.fragments.purchase}
`;

const PAGE_SIZE = 10;
const DATE_FORMAT = 'YYYY-MM-DD';

export default function useFilteredPurchases(query, orderBy, pPageSize = PAGE_SIZE) {
  const [pageSize, setPageSize] = useState(pPageSize);
  const {offset} = usePagination(pageSize);

  const {startDate, endDate, ...remainingQuery} = query;

  let dateRange;
  if (startDate || endDate) {
    dateRange = {
      startDate: startDate && startDate.format(DATE_FORMAT),
      endDate: endDate && endDate.format(DATE_FORMAT)
    };
  }

  const skip = !Object.values(query)
    .filter(v => !!v)
    .length;
  const {data, loading, error, refetch} = useQuery(FILTERED_PURCHASES, {
    variables: {...remainingQuery, dateRange, pageSize, orderBy, offset},
    fetchPolicy: 'cache-and-network',
    skip: skip
  });

  if (error && !(data && data.length)) {
    throw error;
  }

  const {purchasesConnection} = data || {};

  return {
    purchasesConnection, purchasesLoading: loading, refetchPurchases: refetch, pageSize, setPageSize
  };
}
