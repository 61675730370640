import {useCallback, useMemo} from 'react';
import {gql, useApolloClient} from '@apollo/client';

import useSchools from './useSchools';

const NUMBER_SEARCH_ORDER = [{attribute: 'vNumber', direction: 'ASC'}];

const WORK_ORDERS_BY_NUMBER_QUERY = gql`
    query workOrderNumberSearch(
        $number:String,
        $pageSize: Int,
        $offset: Int,
        $orderBy: [OrderBy!]
    ) @connection( key: "workOrders", filter:[ "number" ] ){
        workOrdersConnection(
            number: $number,
            pageSize: $pageSize,
            offset:$offset,
            orderBy:$orderBy
        ) {
            next
            hasMore
            total
            workOrders {
                id,
                number,
                abbreviation,
                vNumber
            }
        }
    }
`;
const PAGE_SIZE = 30;

export default function useWorkOrderNumberSearch() {
  const client = useApolloClient();
  const {schools} = useSchools();

  const woNumberRegex = useMemo(() => {
    let result = /(?!)/; //matches nothing
    if (schools?.length) {
      const codes = schools.map(({code}) => code);
      result = new RegExp(`(${codes?.join('|')})([0-9]+)`, 'i');
    }
    return result;
  }, [schools]);

  const matchesSchoolAndNumber = useCallback(number => {
    // eslint-disable-next-line
    const [_, second] = number.match(woNumberRegex);

    return !!second?.length;
  }, [woNumberRegex]);

  return useCallback(async number => {

    if (matchesSchoolAndNumber(number)) {
      const {data, error} = await client.query({
        query: WORK_ORDERS_BY_NUMBER_QUERY,
        fetchPolicy: 'network-only',
        variables: {number, orderBy: NUMBER_SEARCH_ORDER, pageSize: PAGE_SIZE}
      });

      if (error && !data?.length) {
        throw error;
      }

      return data.workOrdersConnection.workOrders;
    }
  }, [matchesSchoolAndNumber, client]);
}