import React from 'react';
import Container from 'react-bootstrap/Container';

import QueryContext from '../../context/QueryContext';

import useLocationsQueryParams, {toUrl, BASE_QUERY} from '../../hooks/useLocationQueryParams';
import useQueryContext from '../../hooks/useQueryContext';
import useFilteredLocations from '../../hooks/useFilteredLocations';
import useColumnSort from '../../hooks/useColumnSort';

import LocationsTable, {locationsOrderBy} from '../../components/LocationsTable';
import ConnectionPagination from '../../components/ConnectionPagination';

import Header from './Header';

const ROUTE_CONTEXT_KEY = 'page.Locations';

export default function Locations() {
  const {columnSortDirection, onSort} = useColumnSort(new Map([['date', 'DESC']]));

  const [locationsQuery, setLocationsQuery] = useLocationsQueryParams();
  const {query, handleUpdateQuery, handleClearQuery}
    = useQueryContext(ROUTE_CONTEXT_KEY, locationsQuery, setLocationsQuery, toUrl, BASE_QUERY);
  const {locationsConnection, locationsLoading, pageSize, setPageSize}
    = useFilteredLocations(query, locationsOrderBy(columnSortDirection));

  const {locations} = locationsConnection || {};
  return (
    <QueryContext.Provider value={{query, handleUpdateQuery, handleClearQuery}}>
      <Container fluid>
        <Header/>
        <LocationsTable locations={locations} loading={locationsLoading} sortDirection={columnSortDirection}
                        onSort={onSort}/>
        {
          locationsConnection && (
            <ConnectionPagination pageSize={pageSize} connection={locationsConnection} onChangePageSize={setPageSize}/>
          )
        }
      </Container>
    </QueryContext.Provider>
  );
}