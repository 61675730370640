import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';
import {Navigate, Link} from 'react-router-dom';
import {faExchangeAlt, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import useTimer from '../../hooks/useTimer';

import QuerySpinner from '../../components/QuerySpinner';
import USCurrency from '../../components/USCurrency';
import VendorConversionDialog from '../../components/VendorConversionDialog';

function VendorRow({vendor, onDelete, onConvert}) {
  const [vendorID, setVendorID] = useState(null);

  if (vendorID) {
    return (<Navigate to={`/app/purchases/vendor/${vendorID}`}/>);
  }
  const {purchaseStats, id, name, isAlias, resolvesTo, aliases} = vendor;
  const {lastYearsPurchaseTotal, ytdPurchaseTotal, purchaseCount} = purchaseStats;

  let convertTitle = 'Convert to Alias';
  if (isAlias) {
    convertTitle = 'Convert to Vendor';
  }
  return (
    <tr onClick={() => setVendorID(id)}>
      <td>{name}</td>
      <td>{resolvesTo && (<Link to={`/app/purchases/vendor/${resolvesTo.id}`}>{resolvesTo.name}</Link>)}</td>
      <td><USCurrency amount={lastYearsPurchaseTotal}/></td>
      <td><USCurrency amount={ytdPurchaseTotal}/></td>
      <td>{purchaseCount}</td>
      <td>{aliases.length}</td>
      <td>
        <OverlayTrigger placement="auto" flip
                        overlay={<Tooltip id={`delete-${id}`}>Delete Vendor</Tooltip>}>
          <Button variant="outline-light" type="button" onClick={onDelete}>
            <FontAwesomeIcon className="text-primary" size="lg" icon={faTrashAlt}/>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger placement="auto" flip
                        overlay={<Tooltip id={`convert-${id}`}>{convertTitle}</Tooltip>}>
          <Button variant="outline-light" type="button" onClick={onConvert}>
            <FontAwesomeIcon className="text-primary" size="lg" icon={faExchangeAlt}/>
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
}

export default function VendorList({vendors, loading, onDelete}) {
  const [alarm] = useTimer(loading);
  const [vendorToConvert, setVendorToConvert] = useState(null);

  if (alarm) {
    return (
      <Col sm={12}>
        <QuerySpinner/>
      </Col>
    );
  }

  function handleDelete(vendor) {
    return event => {
      event.stopPropagation();
      onDelete(vendor);
    };
  }

  function handleConvert(vendor) {
    return event => {
      event.stopPropagation();
      setVendorToConvert(vendor);
    };
  }

  const lastYear = new Date().getFullYear() - 1;
  return (
    <>
      <Table responsive hover>
        <thead>
        <tr className="bg-secondary">
          <th>Vendor</th>
          <th>Alias for</th>
          <th>Material Costs {lastYear}</th>
          <th>Material Costs YTD</th>
          <th># of Purchases</th>
          <th># of Aliases</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {
          !!vendors &&
          vendors.map(vendor =>
            (<VendorRow key={vendor.id} vendor={vendor} onDelete={handleDelete(vendor)}
                        onConvert={handleConvert(vendor)}/>))
        }
        </tbody>
      </Table>
      {vendorToConvert && (<VendorConversionDialog vendor={vendorToConvert} onClose={() => setVendorToConvert(null)}/>)}
    </>
  );
}
