import {useCallback, useContext} from 'react';
import {gql, useApolloClient} from '@apollo/client';

import SessionContext from '../context/session';

const PAGE_SIZE = 60;
const JOBS_QUERY = gql`
    query searchJobsForName($name: String, $includeGlobal:Boolean, $pageSize:Int){
        jobsConnection(name:$name, includeGlobal: $includeGlobal, pageSize:$pageSize)
        @connection(key:"jobs", filter:["name", "includeGlobal"]){
            jobs {
                id
                name
                isSFMPurchaseAssignable
                school{
                    id
                    code
                    name
                }
            }
        }
    }
`;

export default function useJobSearch() {
  const client = useApolloClient();
  const {user} = useContext(SessionContext);

  return useCallback(async name => {
    if (name) {
      const {data, error} = await client.query({
        query: JOBS_QUERY,
        fetchPolicy: 'network-only',
        variables: {name, includeGlobal: user.canViewAllJobs(), pageSize: PAGE_SIZE}
      });

      if (error && !(data && data.length)) {
        throw error;
      }

      return data.jobsConnection.jobs;
    }
  }, [client, user]);
}
