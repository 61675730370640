import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import useDeleteVendor from '../hooks/useDeleteVendor';

import FormGroup from './FormGroup';
import VendorLookup from './VendorLookup';

export default function DeleteVendorDialog({vendor, onClose}) {
  const [validated, setValidated] = useState(false);
  const [newVendor, setNewVendor] = useState(vendor.resolvesTo);
  const {name, purchaseStats, aliases, isAlias} = vendor;
  const {purchaseCount} = purchaseStats;
  const [deleteVendor, {deleteVendorLoading}] = useDeleteVendor();

  async function handleSubmit(e) {
    e.preventDefault();
    setValidated(true);
    if ((!newVendor && purchaseCount) || (vendor === newVendor)) {
      return;
    }

    const {error} = await deleteVendor(vendor, newVendor);
    if (!error) {
      onClose();
    }
  }

  return (
    <Modal onHide={onClose} show animation>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title><h4 className="fw-bold">Delete Vendor</h4></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row as="dl">
            <Col as="dt" sm={4}>Vendor Name</Col>
            <Col as="dd" sm={8}>{name}</Col>
            <Col as="dt" sm={4}>Purchase Count</Col>
            <Col as="dd" sm={8}>{purchaseCount}</Col>
            {!isAlias &&
              <>
              <Col as="dt" sm={4}>Alias Count</Col>
              <Col as="dd" sm={8}>{aliases.count}</Col>
              </>
            }
          </Row>
          <Row>
            <FormGroup controlId="vendor" label="Re-assign all purchases and aliases to:">
              <VendorLookup vendor={newVendor} onVendorSelect={setNewVendor} required={purchaseCount > 0}
                            filter={aVendor => aVendor.id !== vendor.id}/>
              <Form.Control.Feedback type="invalid">Vendor is required</Form.Control.Feedback>
            </FormGroup>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" disabled={deleteVendorLoading}>Delete</Button>
          <Button variant="outline-primary" onClick={onClose} disabled={deleteVendorLoading}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
