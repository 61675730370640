import React from 'react';

import SchoolCode from '../SchoolCode';

export default function Title({existing, schoolCode, number, name, forPrint = false, readOnly = false}) {

  let title = 'Work Order';
  if (!forPrint && !readOnly) {
    title = existing ? `Edit ${title}` : `Add ${title}`;
  }

  return (
    <h4 className="fw-bold text-center mt-2">
      {title}
      {
        existing && (
          <>
            {' '}
            <SchoolCode code={schoolCode}/>{number}
          </>
        )
      }
      {
        name && (
          <> - {name}</>
        )
      }
    </h4>
  );
}