import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';

import useTechnicianSearch from '../hooks/useTechnicianSearch';

import FormGroup from './FormGroup';
import LookupByName from './LookupByName';

export default function TechnicianFormGroup(
  {technician, label = 'Technician', disabled, required, onSelectTechnician, ...props}
) {
  const [showInactive, setShowInactive] = useState(false);
  const searchTechnicians = useTechnicianSearch(showInactive);

  return (
    <FormGroup controlId="technician" label={label} {...props}>
      <LookupByName selected={technician} disabled={disabled} fetch={searchTechnicians} required={required}
                    onSelect={onSelectTechnician} showInactive={showInactive}/>
      <Form.Control.Feedback type="invalid">Technician is required</Form.Control.Feedback>
      <Form.Check type="switch" id="technician-inactive" label="Show Inactive" checked={showInactive}
                  onChange={e => setShowInactive(e.target.checked)}/>
    </FormGroup>
  );
}
