import React, {useCallback, useContext} from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import SessionContext from '../context/session';
import TimeSheetsContext from '../context/TimeSheetsContext';

import useTimer from '../hooks/useTimer';

import QuerySpinner from './QuerySpinner';
import TimeSheetCalendar from './TimeSheetCalendar';

export default function TimeSheetView({timeSheets, loading}) {
  const {user} = useContext(SessionContext);
  const {setTimeSheetUnderEdit} = useContext(TimeSheetsContext);
  const [alarm] = useTimer(loading);

  const handleEmptyTimeRangeSelected = useCallback(function _handleEmptyTimeRangeSelected(timeSheet) {
    setTimeSheetUnderEdit({
      ...timeSheet,
      technician: {
        id: user.userID
      }
    });
  }, [setTimeSheetUnderEdit, user]);

  return (
    <Container fluid className="h-100">
      <Row className="d-inline-block w-100 h-100">
        <TimeSheetCalendar className="position-relative" timeSheets={timeSheets}
                           onEmptyTimeRangeSelected={handleEmptyTimeRangeSelected}>
          {
            alarm &&
            (
              <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-50" style={{zIndex: 1010}}>
                <QuerySpinner/>
              </div>
            )
          }
        </TimeSheetCalendar>
      </Row>
    </Container>
  );
}