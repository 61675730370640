import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

if (window.location !== window.parent.window.location) {
  window.parent.window.postMessage(false, '*');
} else {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(<App/>);
  reportWebVitals();
}
