import {useCallback, useContext} from 'react';

import {gql, useMutation} from '@apollo/client';
import SessionContext from '../context/session';
import PurchaseList from '../graphql/purchase-list';

const ADD_PURCHASE_MUTATION = gql`
    mutation addPurchase(
        $isSplit: Boolean!
        $date: String!
        $amount: Float!
        $notes: String!
        $techID: Int!
        $jobID: Int
        $taskID: Int
        $vendorID: Int
        $purchaseTypeID: Int!
        $purchaseImages: [Upload!]! ) {

        addPurchase(
            isSplit: $isSplit
            date: $date
            amount: $amount
            notes: $notes
            techID: $techID
            jobID: $jobID
            taskID: $taskID
            vendorID: $vendorID
            purchaseTypeID: $purchaseTypeID
            purchaseImages: $purchaseImages ) {
            ...PurchaseListPurchase
        }
    }
    ${PurchaseList.fragments.purchase}
`;

const NULL_ID = {id: null};
export default function useAddPurchase(options) {
  const {user} = useContext(SessionContext);
  const [gqlAddPurchase, {loading, error}] = useMutation(ADD_PURCHASE_MUTATION, options);

  const addPurchase = useCallback(purchase => {
    const {date, isSplit, purchaseType, task, job, vendor, technician, ...theRest} = purchase;
    const {id: purchaseTypeID} = purchaseType;
    const {id: jobID} = (!isSplit && job) || NULL_ID;
    const {id: taskID} = (!isSplit && task) || NULL_ID;
    const {id: vendorID} = vendor || NULL_ID;
    const {id: techID} = technician || {id: user.userID};

    const variables = {
      ...theRest,
      isSplit,
      date: date.format('YYYY-MM-DD'),
      jobID, purchaseTypeID, vendorID, taskID, techID
    };

    return gqlAddPurchase({variables});
  }, [user, gqlAddPurchase]);

  return [addPurchase, {addPurchaseLoading: loading, addPurchaseError: error}];
}
