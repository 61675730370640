import {gql, useMutation} from '@apollo/client';
import moment from 'moment';

import PurchaseList from '../graphql/purchase-list';

const UPDATE_PURCHASE = gql`
    mutation updatePurchase(
        $id:Int!
        $isSplit:Boolean!
        $date:String!
        $amount:Float!
        $notes:String!
        $techID:Int!
        $jobID:Int
        $taskID:Int
        $vendorID:Int
        $purchaseTypeID: Int!
        $purchaseImages:[Upload!]
        $deletedPurchaseImageIds:[Int!] ){
        updatePurchase(
            id:$id
            isSplit:$isSplit
            date:$date
            amount:$amount
            notes:$notes
            techID:$techID
            jobID:$jobID
            taskID:$taskID
            vendorID:$vendorID
            purchaseTypeID:$purchaseTypeID
            purchaseImages:$purchaseImages
            deletedPurchaseImageIds:$deletedPurchaseImageIds
        ){
            ...PurchaseListPurchase
        }
    }
    ${PurchaseList.fragments.purchase}
`;

const NULL_ID = {id: null};
export default function useUpdatePurchase(options) {
  const [gqlUpdatePurchase, {loading, data, error}] = useMutation(UPDATE_PURCHASE, options);

  if (error && !(data && data.length)) {
    throw error;
  }

  function updatePurchase(purchase) {
    const {isSplit, date, technician, purchaseType, task, job, vendor, ...theRest} = purchase;
    const {id: techID} = technician || {};
    const {id: jobID} = (!isSplit && job) || NULL_ID;
    const {id: taskID} = (!isSplit && task) || NULL_ID;
    const {id: purchaseTypeID} = purchaseType;
    const {id: vendorID} = vendor || NULL_ID;

    const variables = {
      ...theRest,
      isSplit,
      date: moment(date).format('YYYY-MM-DD'),
      techID, purchaseTypeID, taskID, jobID, vendorID
    };

    return gqlUpdatePurchase({variables});
  }

  const {updatePurchase: updatedPurchase} = data || {};
  return [updatePurchase, {updatedPurchase, updatePurchaseLoading: loading}];
}
