import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import useAddVendor from '../../hooks/useAddVendor';
import useVendorSearch from '../../hooks/useVendorSearch';

export default function EditVendorDialog({onClose, vendor}) {
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState((vendor || {}).name || '');
  const options = {onCompleted: () => onClose()};
  const [addVendor, {addVendorLoading, addVendorError}] = useAddVendor(options);
  const searchVendors = useVendorSearch();
  const [similarVendors, setSimilarVendors] = useState([]);

  if (addVendorError) {
    throw addVendorError;
  }
  const vendorExists = !!similarVendors.find(sv => sv.trim().toLowerCase() === name.trim().toLowerCase());

  function handleSubmit(e) {
    e.preventDefault();
    setValidated(true);

    if (vendorExists || !name) {
      return;
    }
    // noinspection JSIgnoredPromiseFromCall
    addVendor(name);
  }

  async function handleNameChange({target}) {
    const name = target.value;
    setName(name);

    let similarVendors = [];
    if (name) {
      const vendors = await searchVendors(name);
      similarVendors = vendors.map(({name}) => name);
    }
    setSimilarVendors(similarVendors);
  }

  let feedback = 'Vendor name is required';
  let validity = 'valid';
  if (vendorExists) {
    feedback = 'Vendor name exists';
    validity = 'invalid';
  }
  return (
    <Modal show animation>
      <Form validated={validated} onSubmit={handleSubmit} noValidate>
        <Modal.Header>
          <h4 className="fw-bold">New Vendor</h4>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          <Row className="flex-shrink-0">
            <Form.Group controlId="vendor-name" as={Col} sm={12}>
              <Form.Label>Name</Form.Label>
              <Form.Control placeholder="Vendor Name" value={name} className={validated && `is-${validity}`}
                            onChange={handleNameChange} required/>
              <Form.Control.Feedback type="invalid" disabled={addVendorLoading}>{feedback}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="flex-shrink-1 flex-grow-0">
            <Form.Group className="d-flex flex-column" controlId="similar-vendors" style={{height: '275px'}} as={Col}
                        sm={12}>
              <Form.Label>Similar Vendors</Form.Label>
              <ListGroup className="overflow-auto flex-shrink-1">
                {similarVendors.map(sv => (<ListGroup.Item key={sv}>{sv}</ListGroup.Item>))}
              </ListGroup>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            addVendorLoading && (
              <span className="me-3">
                Creating Vendor
                <Spinner className="ms-3" variant="primary" animation="border" role="status"/>
              </span>
            )}
          <Button variant="primary" type="submit" disabled={addVendorLoading}>Create Vendor</Button>
          <Button variant="outline-primary" type="button" disabled={addVendorLoading} onClick={onClose}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
