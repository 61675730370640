import {useState} from 'react';
import {gql, useQuery} from '@apollo/client';

import usePagination from './usePagination';

const overlappingTimesQuery = gql`
    query overlappingTimes($pageSize: Int, $offset: Int, $orderBy: [OrderBy!])
    @connection(key: "overlappingTimes", filter: []) {
        overlappingTimesConnection(
            pageSize: $pageSize
            offset: $offset
            orderBy: $orderBy
        ) {
            next
            hasMore
            total
            overlappingTimes {
                techType
                periodFrom
                periodTo
                reportFrom
                reportTo
                overlaps
                uri
            }
        }
    }
`;

const PAGE_SIZE = 10;

export default function useOverlappingTimes(orderBy = []) {
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const {offset} = usePagination(pageSize);

  const {data, loading, error, refetch} = useQuery(overlappingTimesQuery, {
    variables: {pageSize, offset, orderBy},
    fetchPolicy: 'cache-and-network'
  });

  if (error && !(data && data.length)) {
    throw error;
  }

  const {overlappingTimesConnection} = data || {};

  return {
    overlappingTimesConnection: overlappingTimesConnection,
    overlappingTimesLoading: loading,
    refetchOverlappingTimes: refetch,
    pageSize,
    setPageSize
  };
}
