import {useMemo, useRef, useState} from 'react';
import moment from 'moment';
import {Views} from 'react-big-calendar';

import useEarliestPayrollDate from './useEarliestPayrollDate';
import useLocalStorage from './useLocalStorage';

export default function useTimeSheetsContext() {
  const [startDateTime, setStartDateTime] = useState(moment().startOf('week'));
  const [endDateTime, setEndDateTime] = useState(moment().endOf('week'));
  const [calendarView, setCalendarView] = useState(Views.WEEK);
  const [calendarDate, setCalendarDate] = useState(moment());
  const {earliestPayrollDate} = useEarliestPayrollDate();
  const [getDefaultSchool, setDefaultSchool] = useLocalStorage('defaultSchool');
  const [getDefaultJob, setDefaultJob] = useLocalStorage('defaultJob');
  const [selectedTimeSheet, setSelectedTimeSheet] = useState(null);
  const [timeSheetUnderEdit, setTimeSheetUnderEdit] = useState(null);
  const [timeSheetToDelete, setTimeSheetToDelete] = useState(null);
  const popupPortalRef = useRef(null);

  return useMemo(() => ({
    startDateTime, setStartDateTime,
    endDateTime, setEndDateTime,
    calendarView, setCalendarView,
    calendarDate, setCalendarDate,
    earliestPayrollDate,
    getDefaultSchool, setDefaultSchool,
    getDefaultJob, setDefaultJob,
    selectedTimeSheet, setSelectedTimeSheet,
    timeSheetUnderEdit, setTimeSheetUnderEdit,
    timeSheetToDelete, setTimeSheetToDelete,
    popupPortalRef
  }), [
    startDateTime, setStartDateTime,
    endDateTime, setEndDateTime,
    calendarView, setCalendarView,
    calendarDate, setCalendarDate,
    earliestPayrollDate,
    getDefaultSchool, setDefaultSchool,
    getDefaultJob, setDefaultJob,
    selectedTimeSheet, setSelectedTimeSheet,
    timeSheetUnderEdit, setTimeSheetUnderEdit,
    timeSheetToDelete, setTimeSheetToDelete,
    popupPortalRef
  ]);
}