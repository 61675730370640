import React from 'react';
import Form from 'react-bootstrap/Form';

import FormGroup from '../FormGroup';
import ImageSelect from '../ImageSelect';

export default function ImagesFormGroup(
  {images, disabled, vendorNotListed, vendorLegible, onImagesChange, onImageDeleted, onVendorLegibleChange, ...props}
) {

  let feedback = 'At least one receipt image is required';
  if (images && images.length) {
    feedback = 'You must affirm that the vendor is legible in the receipt';
  }

  const validity = images && images.length && (!vendorNotListed || vendorLegible)
    ? 'sfm-is-valid'
    : 'sfm-is-invalid';
  return (
    <FormGroup controlId="images" label="Images" {...props}>
      <ImageSelect className={validity} images={images} disabled={disabled} onSelect={onImagesChange}
                   onDelete={onImageDeleted} required/>
      <Form.Check id="vendor-legible-affirmation" label="The vendor is legible in the receipt"
                  className={validity} checked={vendorLegible} disabled={!vendorNotListed} required={vendorNotListed}
                  onChange={({target}) => onVendorLegibleChange(target.checked)}/>
      <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
    </FormGroup>
  );
}
