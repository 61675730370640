import {gql, useQuery} from '@apollo/client';

import userFragment from '../graphql/user-fragment';

const CURRENT_USER_QUERY = gql`
    query currentUser{
        currentUser{
            ...UserFields
        }
    }
    ${userFragment}
`;

export default function useCurrentUser(skip) {
  const {data, loading, error, refetch} = useQuery(CURRENT_USER_QUERY,
    {fetchPolicy: 'network-only', skip});

  const {currentUser} = data || {};
  return {currentUser, userLoading: loading, currentUserError: error, refetchCurrentUser: refetch};
}
