import {useMemo} from 'react';

export default function useTaskLabels(tasks) {
  return useMemo(() => {
    let taskLabels = new Map();
    tasks?.reduce((reduction, task) => {
      if (!task) {
        return reduction;
      }
      const {description, assignment, technician} = task || {};
      const vNumber = assignment?.workOrder?.vNumber;
      let name = '';
      if (technician) {
        const {firstName, lastName} = technician;
        name = `(${lastName}, ${firstName})`;
      }

      reduction.set(task.id, `${vNumber} - ${description} ${name}`);
      return reduction;
    }, taskLabels);

    return taskLabels;
  }, [tasks]);
}