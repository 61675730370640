import React, {useContext} from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import {useNavigate, useLocation} from 'react-router-dom';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import useAuthorizedLocations from '../hooks/useAuthorizedLocations';
import SessionContext from '../context/session';

import Logo from './Logo';
import Version from './Version';

const {SFM_APP_ENV} = window;

const NAV_STYLE = 'Production' === SFM_APP_ENV
  ? {height: '100vh'}
  : {height: '94vh'};
/**
 * @return {null}
 */
export default function MainNavDrawer() {
  const authorizedLocations = useAuthorizedLocations();
  const {onEndSession} = useContext(SessionContext);
  const navigate = useNavigate();

  let navItems = [];
  if (authorizedLocations.length > 1) {
    navItems = authorizedLocations.map(({location, displayName}) => (
      <MainNavItem key={location} eventKey={location}>{displayName}</MainNavItem>
    ));
  }

  return (
    <Col style={{maxWidth: '25%'}}>
      <Navbar style={{...NAV_STYLE, padding: 0}}>
        <Nav variant="pills" className="align-items-end h-100 flex-column border bg-light"
          onSelect={eventKey => navigate(eventKey)}>
          {navItems}
          <Navbar.Brand className="mt-auto">
            <Logo/>
          </Navbar.Brand>
          <Nav.Item className="border fw-bold w-100">
            <Nav.Link className="text-dark" onClick={() => onEndSession()}>
              <Row>
                <Col><FontAwesomeIcon icon={faSignOutAlt}/></Col>
                <Col>Log out</Col>
              </Row>
            </Nav.Link>
          </Nav.Item>
          <Navbar.Text className="w-100 text-center text-muted">
            <Version/>
          </Navbar.Text>
        </Nav>
      </Navbar>
    </Col>
  );
}

function MainNavItem({eventKey, children}) {
  const {pathname} = useLocation();
  return (
    <Nav.Item className="border fw-bold w-100">
      <Nav.Link className="text-center text-dark rounded-0" eventKey={eventKey} children={children}
                active={pathname === eventKey}/>
    </Nav.Item>
  );
}
