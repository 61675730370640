import React, {useCallback} from 'react';
import AsyncCreatable from 'react-select/async-creatable';

import useAddVendor from '../../hooks/useAddVendor';
import useVendorSearch from '../../hooks/useVendorSearch';
import theme from '../../lib/selectTheme';

export default function VendorSelectCreate({vendor, onVendorSelect}) {
  const searchVendors = useVendorSearch();
  const [addVendor, {addVendorLoading, addVendorError}] = useAddVendor();

  if (addVendorError) {
    throw addVendorError;
  }

  const toOption = useCallback(value => ({label: value.name, value}), []);

  const handleLoadOptions = useCallback(async input => {
    const result = await searchVendors(input);
    return result.map(toOption);
  }, [searchVendors, toOption]);

  const handleCreate = useCallback(async input => {
    const {data} = await addVendor(input.trim());
    const {addVendor: newVendor} = data;
    onVendorSelect(newVendor);
  }, [addVendor, onVendorSelect]);

  return (
    <AsyncCreatable classNamePrefix="sfm-select" className="sfm-select" theme={theme} placeholder="Select Vendor"
                    loadOptions={handleLoadOptions} value={vendor} cacheOptions
                    onChange={({value}) => onVendorSelect(value)} noOptionsMessage={() => 'Type to Search ...'}
                    onCreateOption={handleCreate} isDisabled={addVendorLoading}/>
  );
}
