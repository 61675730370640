import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function EmailInputGroup({controlId, email, disabled, onEmailChange}) {
  return (
    <Form.Group controlId={controlId}>
      <Form.Label>Email Address</Form.Label>
      <InputGroup>
        <InputGroup.Text id="envelope" as="span"
                         className="flex-column justify-content-center px-2 bg-secondary border rounded-left">
          <FontAwesomeIcon icon={faEnvelope}/>
        </InputGroup.Text>
        <Form.Control placeholder="email address" as="input" type="email" aria-describedby="envelope" value={email}
                      disabled={disabled} onChange={({target}) => onEmailChange(target.value)}/>
      </InputGroup>
    </Form.Group>
  );
}
