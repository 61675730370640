import React from 'react';
import Form from 'react-bootstrap/Form';

import FormGroup from '../FormGroup';
import ClientLookup from '../ClientLookup';

export default function ClientFormGroup({sm, school, onSchoolChange, disabled}){
  return (
    <FormGroup controlId="client" label="Client" sm={sm}>
      <ClientLookup client={school} onClientSelect={onSchoolChange} disabled={disabled} required/>
      <Form.Control.Feedback type="invalid">Client is required</Form.Control.Feedback>
    </FormGroup>
  );
}
