import {gql} from '@apollo/client';

export default gql`
    fragment UserFields on User{
        id
        userType
        userID
        username
        status
        lastName
        firstName
        email
        entryLevel

        role{
            name
            rights{
                name
                type
                field
                context
                constraint
            }
        }
        
        school {
            id
            code
            name
        }
    }
`;
