import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

export default function Header({onNewVendor}) {
  return (
    <Row className="p-4 bg-light flex-shrink-0">
      <Col sm={2}>
        <h4 className="fw-bold">Vendors</h4>
      </Col>
      <Col sm={{offset: 8}}>
        <Button onClick={onNewVendor}>New Vendor</Button>
      </Col>
    </Row>
  );
}
