import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Row from 'react-bootstrap/Row';
import {Link} from 'react-router-dom';

export default function Header({vendor, onShowAddAliasDialog, onConvert}) {
  const {id, name, isAlias} = vendor || {};
  const vendorName = name || '<unknown>';
  return (
    <Row className="bg-light flex-shrink-0 mb-3">
      <Col sm={12}>
        <Breadcrumb>
          <Link to="/app/vendors" className="breadcrumb-item">Vendors</Link>
          <Breadcrumb.Item active>{vendorName}</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Col className="ps-4" sm={10}>
        <h4 className="fw-bold">{`Purchases from ${vendorName}`}</h4>
      </Col>
      <Col sm={2}>
        <DropdownButton id="vendor-actions" title="Actions" drop="start">
          {
            !isAlias &&
            (
              <>
                <Dropdown.Item as={Link} to={`/app/vendors/${id}/aliases`}>View Aliases</Dropdown.Item>
                <Dropdown.Item as="button" onClick={onShowAddAliasDialog}>Add Alias</Dropdown.Item>
              </>
            )
          }
          <Dropdown.Item as="button" onClick={onConvert}>
            {`Convert to ${isAlias ? 'Vendor' : 'Alias'}`}
          </Dropdown.Item>
        </DropdownButton>
      </Col>
    </Row>
  );
}
