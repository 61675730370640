import React, {useContext, useState} from 'react';
import Col from 'react-bootstrap/Col';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';

import RouteContext from '../context/RouteContext';
import SessionContext from '../context/session';
import ManageSplitPurchases from '../page/ManageSplitPurchase';

import Locations from '../page/Locations';
import OverlappingTimes from '../page/OverlappingTimes';
import Purchases from '../page/Purchases';
import PurchasesByTech from '../page/PurchasesByTech';
import PurchasesWithVendor from '../page/PurchasesWithVendor';
import Reports from '../page/Reports';
import TimeSheets from '../page/TimeSheets';
import VendorAliases from '../page/VendorAliases';
import VendorPage from '../page/VendorPage';
import WorkOrder from '../page/WorkOrder';
import WorkOrders from '../page/WorkOrders';

import DB from './DB';

const {SFM_APP_ENV} = window;

const COL_STYLE = 'Production' === SFM_APP_ENV
                  ? {height: '100vh'}
                  : {height: '94vh'};

/**
 * @return {null}
 */
export default function AuthorizedRoutes() {
  const [routeState, setRouteState] = useState({});
  const {initialLocation, user} = useContext(SessionContext);
  const {pathname} = useLocation();

  if (!user) {
    return null;
  }

  if (initialLocation && initialLocation.pathname !== pathname) {
    return (<Navigate to={initialLocation} replace/>);
  }

  function setRouteContext(key, context) {
    setRouteState({...routeState, [key]: context});
  }

  function getRouteContext(key) {
    return routeState[key];
  }

  const unauthorizedRedirect = <Navigate to="/app/db" replace/>;
  return (
    <RouteContext.Provider value={{setRouteContext, getRouteContext}}>
      <Col style={COL_STYLE} md={10} sm={8} xs={8}>
        <Routes>
          <Route path="/app">
            <Route index element={<Navigate replace to="db"/>}/>
            <Route path="db/*" element={<DB/>}/>
            <Route path="work-orders">
              <Route index element={<WorkOrders/>}/>
              <Route path=":workOrderID" element={<WorkOrder/>}/>
            </Route>
            <Route path="vendors">
              <Route index element={
                user.canViewPurchaseStats()
                ? <VendorPage/>
                : unauthorizedRedirect
              }/>
              <Route path=":vendorID/aliases" element={
                user.canViewPurchaseStats()
                ? <VendorAliases/>
                : unauthorizedRedirect
              }/>
            </Route>
            <Route path="purchases">
              <Route index element={
                user.canQueryOtherThanOwnPurchases()
                ? <Purchases/>
                : unauthorizedRedirect
              }/>
              <Route path="tech/:id" element={
                user.canOnlyQueryOwnPurchases()
                ? <PurchasesByTech/>
                : unauthorizedRedirect
              }/>
              {/*See YELLOWTAG in UnknownVendorNotice*/}
              <Route path="vendor/:vendorID?" element={
                user.canGetVendor()
                ? <PurchasesWithVendor/>
                : unauthorizedRedirect
              }/>
              <Route path="manageSplit/:id" element={
                user.canUpdateAllPurchases()
                ? <ManageSplitPurchases/>
                : unauthorizedRedirect
              }/>
            </Route>
            <Route path="time-sheets">
              <Route index element={
                user.canQueryTimeSheets()
                ? <TimeSheets/>
                : unauthorizedRedirect
              }/>
            </Route>
            <Route path="reports">
              <Route index element={
                user.canQueryMultipleReports()
                ? <Reports/>
                : unauthorizedRedirect
              }/>
              <Route path="locations" element={
                user.canQueryLocations()
                ? <Locations/>
                : unauthorizedRedirect
              }/>
              <Route path="overlapping-time" element={
                user.canQueryOverlappingTimes()
                ? <OverlappingTimes/>
                : unauthorizedRedirect
              }/>
            </Route>
          </Route>
        </Routes>
      </Col>
    </RouteContext.Provider>
  );
}
