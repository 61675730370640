import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import useTimer from '../hooks/useTimer';

import QuerySpinner from './QuerySpinner';
import SortableTable from './SortableTable';
import LocationsTableRow from './LocationsTableRow';

const BASE_HEADERS = [
  {key: 'name', children: 'Name', sortable: true},
  {key: 'date', children: 'Date', sortable: true},
  {key: 'userAgent', children: 'User Agent', sortable: true},
  {key: 'deviceID', children: 'Device ID', sortable: true},
  {key: 'address', children: 'Address', sortable: true},
  {key: 'entry', children: 'Time Entered', sortable: true},
  {key: 'job', children: 'Job #', sortable: true},
  {key: 'notes', children: 'Notes', sortable: true}
];

export default function LocationsTable({locations, loading, ...props}) {
  const [alarm] = useTimer(loading);

  if (alarm) {
    return (
      <Row className="h-100">
        <Col sm={12}>
          <QuerySpinner/>
        </Col>
      </Row>
    );

  }

  const body = locations
    ?.map(location => (<LocationsTableRow key={location.id} location={location}/>));
  return (
    <Row className="overflow-auto flex-shrink-1 flex-grow-1" style={{minHeight: '33vh'}}>
      <SortableTable className="table-hover" headers={BASE_HEADERS} body={body} {...props}/>
    </Row>
  );
}

const ATTRIBUTE_SUBSTITUTIONS = new Map([
  ['name', ['technician.lastName', 'technician.firstName']],
  ['date', ['timeSheet.autoDate']],
  ['entry', ['timeSheet.clockIn']],
  ['job', ['job.name']],
  ['notes', ['timeSheet.notes']]
]);

export function locationsOrderBy(columnDirectionMap) {
  return Array.from(columnDirectionMap.entries())
    .reduce((reduction, [attribute, direction]) => {
      const subs = ATTRIBUTE_SUBSTITUTIONS.get(attribute);
      if (subs) {
        subs.forEach(sub => reduction.push({attribute: sub, direction}));
      } else {
        reduction.push({attribute, direction});
      }
      return reduction;
    }, []);
}