import React, {useContext} from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import SessionContext from '../../context/session';
import LoginException from '../../controller/LoginException';

import RouterButton from '../../components/RouterButton';
import UnauthenticatedPage from '../../components/UnauthenticatedPage';
import Version from '../../components/Version';
import LoginForm from './LoginForm';

export default function LoginComponent() {
  const {onAuthenticateUser, authenticationError} = useContext(SessionContext);

  let errorMsg = null;
  if(authenticationError){
    errorMsg = 'Login Request Failed';
    if(authenticationError instanceof LoginException){
      errorMsg = authenticationError.message;
    }
  }

  return (
    <UnauthenticatedPage>
      <UnauthenticatedPage.Card>
        <LoginForm onSubmit={onAuthenticateUser}/>
      </UnauthenticatedPage.Card>
      <UnauthenticatedPage.Row>
        <Col sm={2}>
          <RouterButton variant="outline-primary" to="/app/forgot-password">
            Forgot Password?
          </RouterButton>
        </Col>
        <Col sm={2}>
          <RouterButton variant="outline-primary" to="/app/forgot-username">
            Forgot Username?
          </RouterButton>
        </Col>
      </UnauthenticatedPage.Row>
      <UnauthenticatedPage.Row>
        {errorMsg && <Alert variant="primary">{errorMsg}</Alert>}
      </UnauthenticatedPage.Row>
      <Row className="justify-content-center text-muted">
        <Col sm={4} className="text-center"><Version/></Col>
      </Row>
    </UnauthenticatedPage>
  );
}
