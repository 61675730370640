import {useState, useContext} from 'react';
import {gql, useQuery} from '@apollo/client';

import Session from '../context/session';

import workOrderFragment from '../graphql/work-order-fragment';
import teacherWorkOrderFragment from '../graphql/teacher-work-order-fragment';

import usePagination from './usePagination';

const FILTERED_WORK_ORDERS_QUERY = gql`
    query filteredWorkOrders(
        $dateRange: QueryDateRange,
        $clientIDs:[Int!],
        $statusIDs:[Int!],
        $typeIDs:[Int],
        $divisionIDs:[Int],
        $technicianIDs:[Int!],
        $requesterIDs:[Int!],
        $workOrderIDs:[Int!],
        $isAssigned:Boolean,
        $number:String,
        $keywords:String,
        $isAllTasksComplete:Boolean,
        $isTimeCharged:Boolean,
        $pageSize: Int,
        $offset: Int,
        $orderBy: [OrderBy!]
    ) @connection(
        key: "workOrders",
        filter:[
            "dateRange", "clientIDs", "statusIDs", "typeIDs", "divisionIDs", "technicianIDs", "requesterIDs",
            "workOrderIDs", "isAssigned", "number", "keywords", "isAllTasksComplete", "isTimeCharged"
        ]
    ){
        workOrdersConnection(
            dateRange:$dateRange,
            clientIDs:$clientIDs,
            statusIDs:$statusIDs,
            typeIDs:$typeIDs,
            divisionIDs:$divisionIDs,
            technicianIDs:$technicianIDs,
            requesterIDs:$requesterIDs,
            workOrderIDs: $workOrderIDs,
            isAssigned: $isAssigned,
            number: $number,
            keywords:$keywords,
            isAllTasksComplete: $isAllTasksComplete,
            isTimeCharged: $isTimeCharged,
            pageSize: $pageSize,
            offset:$offset,
            orderBy:$orderBy
        ) {
            next
            hasMore
            total
            workOrders {
                ...WorkOrderFields
            }
        }
    }
    ${workOrderFragment}
`;

const TEACHERS_WORK_ORDER_QUERY = gql`
    query filteredTeacherWorkOrders(
        $dateRange: QueryDateRange,
        $clientIDs:[Int!],
        $statusIDs:[Int!],
        $requesterIDs:[Int!],
        $workOrderIDs:[Int!],
        $number:String,
        $keywords:String,
        $pageSize: Int,
        $offset: Int,
        $orderBy: [OrderBy!]
    ) @connection(
        key: "workOrders",
        filter:[
            "dateRange", "clientIDs", "statusIDs", "typeIDs", "divisionIDs", "technicianIDs", "requesterIDs",
            "workOrderIDs", "isAssigned", "number", "keywords"
        ]
    ){
        workOrdersConnection(
            dateRange:$dateRange,
            clientIDs:$clientIDs,
            statusIDs:$statusIDs,
            requesterIDs:$requesterIDs,
            workOrderIDs: $workOrderIDs,
            number: $number,
            keywords:$keywords,
            pageSize: $pageSize,
            offset:$offset,
            orderBy:$orderBy
        ) {
            next
            hasMore
            total
            workOrders {
                ...TeacherWorkOrderFields
            }
        }
    }
    ${teacherWorkOrderFragment}
`;
const PAGE_SIZE = 10;
const DATE_FORMAT = 'YYYY-MM-DD';

export default function useFilteredWorkOrders(query, orderBy, skip) {
  const {isTeacher} = useContext(Session);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const {offset} = usePagination(pageSize);

  const {startDate, endDate, ...remainingQuery} = query;

  let dateRange;
  if (startDate || endDate) {
    dateRange = {
      startDate: startDate?.format(DATE_FORMAT),
      endDate: endDate?.format(DATE_FORMAT)
    };
  }
  let graphqlQuery = FILTERED_WORK_ORDERS_QUERY;
  if (isTeacher) {
    graphqlQuery = TEACHERS_WORK_ORDER_QUERY;
  }
  const {data, loading, error, refetch} = useQuery(graphqlQuery, {
    variables: {...remainingQuery, dateRange, pageSize, offset, orderBy},
    fetchPolicy: 'cache-and-network',
    skip
  });

  if (error && !(data && data.length)) {
    throw error;
  }

  const {workOrdersConnection} = data || {};

  return {workOrdersConnection, workOrdersLoading: loading, refetchWorkOrders: refetch, pageSize, setPageSize};
}