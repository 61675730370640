import React, {useContext, useState} from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import moment from 'moment';

import WorkOrderContext from '../../context/WorkOrderContext';
import SessionContext from '../../context/session';
import CommentsContext from '../../context/CommentsContext';

import useAddWorkOrderComment from '../../hooks/useAddWorkOrderComment';

import CommentList from './CommentList';

export default function CommentsCard() {
  const {id: workOrderID, comments, setComments} = useContext(WorkOrderContext);
  const {user} = useContext(SessionContext);
  const [newComment, setNewComment] = useState('');
  const {workOrderComments, refetchWorkOrderComments} = useContext(CommentsContext);
  const [addWorkOrderComment] = useAddWorkOrderComment(workOrderID, {onCompleted: refetchWorkOrderComments});

  function handleAddComment() {
    if (workOrderID) {
      addWorkOrderComment(newComment);
    } else {
      setComments([...comments, newComment]);
    }
    setNewComment('');
  }

  const vComments = workOrderID
    ? workOrderComments
    : comments.map((c, i) => ({id: i, comment: c, user, lastUpdated: moment()}));
  return (
    <Card body>
      <CommentList comments={vComments}/>
      <Form.Group as={Col} controlId="comment">
        <Form.Label>Comments</Form.Label>
        <Form.Control as="textarea" value={newComment}
                      onChange={({target}) => setNewComment(target.value)}/>
        <Row className="p-3 align-items-center justify-content-end">
          <Col sm={2}>
            <Button variant="outline-primary" disabled={!newComment} onClick={handleAddComment}>
              Add Comment
            </Button>
          </Col>
        </Row>
      </Form.Group>
    </Card>
  );
}