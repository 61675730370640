import React, {useCallback, useEffect, useState} from 'react';
import {gql, useMutation} from '@apollo/client';
import Button from 'react-bootstrap/Button';

import AreYouSure from '../components/AreYouSure';

const DELETE_PURCHASE = gql`
    mutation deletePurchase($id:Int!){
        deletePurchase(id:$id){
            message
        }
    }
`;

export default function useDeletePurchase(refetchPurchases) {
  const [purchase, setPurchase] = useState(null);
  const [showAreYouSure, setShowAreYouSure] = useState(false);
  const [gqlDeletePurchase, {loading, data, error}] = useMutation(DELETE_PURCHASE);

  useEffect(() => {
    if(!data || loading){
      return;
    }

    const {deletePurchase} = data;
    const {message} = deletePurchase || {};
    if(message){
      refetchPurchases();
    }
  }, [data, loading, refetchPurchases]);

  const handleDeletePurchase = useCallback(purchase => {
    setPurchase(purchase);
    setShowAreYouSure(true);
  }, [setPurchase, setShowAreYouSure]);

  const confirmDelete = useCallback(() => {
    setShowAreYouSure(false);
    gqlDeletePurchase({variables: {id: purchase.id}});
  }, [setShowAreYouSure, gqlDeletePurchase, purchase]);

  const cancelDelete = useCallback(() => {
    setShowAreYouSure(false);
  }, [setShowAreYouSure]);

  if(error){
    throw error;
  }

  let message = 'Deleting a purchase cannot be undone';
  if(purchase?.isLocked){
    message = 'This purchase has already been locked';
  }
  const areYouSure = (
    <AreYouSure title="Are you sure?" message={message} show={showAreYouSure}
                buttons={[
                  <Button key="delete" variant="primary" onClick={confirmDelete}>Delete</Button>,
                  <Button key="cancel" variant="outline-primary" onClick={cancelDelete}>Cancel</Button>
                ]}/>
  );
  return [handleDeletePurchase, areYouSure];
}
