import {useState} from 'react';

export default function useColumnSort(initialSort) {
  const [columnSort, setColumnSort] = useState(initialSort);

  function onSort(sortKey, event) {
    event.preventDefault();

    const {shiftKey} = event;
    const order = columnSort.get(sortKey);
    if (order) {
      if (shiftKey) {
        columnSort.delete(sortKey);
      } else if ('ASC' === order) {
        columnSort.set(sortKey, 'DESC');
      } else if ('DESC' === order) {
        columnSort.delete(sortKey);
      }
    } else {
      if (!shiftKey) {
        columnSort.clear();
      }
      columnSort.set(sortKey, 'ASC');
    }

    setColumnSort(new Map(columnSort));
  }

  return {columnSortDirection: columnSort, onSort};
}
