import React, {useContext} from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import {Link} from 'react-router-dom';

import WorkOrderContext from '../../context/WorkOrderContext';
import Costs from './Costs';

export default function CostsFormGroup() {
  const {laborCosts, materialCosts, customerMaterialCosts, purchaseCount, id} = useContext(WorkOrderContext);
  return (
    <Form.Group as={Col} controlId="costs-group">
      <Row className="py-2">
        <Costs laborCosts={laborCosts} materialCosts={materialCosts}
               customerMaterialCosts={customerMaterialCosts}/>
      </Row>
      <Row className="justify-content-end">
        <Col sm={2}>
          <Button className="ms-auto" variant="outline-primary" as={Link} to={`/app/purchases?workOrderIDs=${id}`}>
            Go to Purchases <Badge className="mx-1" bg="secondary" pill>{purchaseCount}</Badge>
          </Button>
        </Col>
      </Row>
    </Form.Group>
  );

}