import React from 'react';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

export default function QuerySpinner() {
  return (
    <Row className="justify-content-center align-items-center h-100">
      <Spinner size="lg" animation="border" variant="primary"/>
    </Row>
  );
}
