import {useContext} from 'react';
import {gql, useQuery} from '@apollo/client';

import SessionContext from '../context/session';

const SCHOOLS_QUERY = gql`
    query schools{
        schools{
            id
            code
            name
        }
    }
`;

export default function useSchools() {
  const {user} = useContext(SessionContext);
  const {data, loading, error} = useQuery(SCHOOLS_QUERY, {skip: !user.canQuerySchools()});

  if (error && !(data && data.length)) {
    console.error(error);
    throw error;
  }

  let schools = null;
  if (data) {
    ({schools} = data);
  }
  return {schools, schoolsLoading: loading};
}
