import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {faQuestionCircle} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import CustCreditCardIcon from './CustCreditCardIcon';
import CustInvoiceIcon from './CustInvoiceIcon';
import CustPurchaseIcon from './CustPurchaseIcon';
import CustRentalIcon from './CustRentalIcon';
import SFMConsumableIcon from './SFMConsumableIcon';
import SFMCreditCardIcon from './SFMCreditCardIcon';
import SFMInvoiceIcon from './SFMInvoiceIcon';
import SFMRentalIcon from './SFMRentalIcon';

function DefaultIcon(props) {
  return <FontAwesomeIcon icon={faQuestionCircle} {...props}/>;
}

const CUST_CC_PURCHASE_TYPE_ID = 9;
const CUST_INVOICE_PURCHASE_TYPE_ID = 10;
const CUST_PURCHASE_PURCHASE_TYPE_ID = 11;
const CUST_RENTAL_PURCHASE_TYPE_ID = 12;
const SFM_CONSUMABLE_PURCHASE_TYPE_ID = 13;
const SFM_CC_PURCHASE_TYPE_ID = 14;
const SFM_INVOICE_PURCHASE_TYPE_ID = 15;
const SFM_RENTAL_PURCHASE_TYPE_ID = 17;

const PURCHASE_TYPE_ICON = new Map([
  [CUST_CC_PURCHASE_TYPE_ID, CustCreditCardIcon],
  [CUST_INVOICE_PURCHASE_TYPE_ID, CustInvoiceIcon],
  [CUST_PURCHASE_PURCHASE_TYPE_ID, CustPurchaseIcon],
  [CUST_RENTAL_PURCHASE_TYPE_ID, CustRentalIcon],
  [SFM_CONSUMABLE_PURCHASE_TYPE_ID, SFMConsumableIcon],
  [SFM_CC_PURCHASE_TYPE_ID, SFMCreditCardIcon],
  [SFM_INVOICE_PURCHASE_TYPE_ID, SFMInvoiceIcon],
  [SFM_RENTAL_PURCHASE_TYPE_ID, SFMRentalIcon]
]);

export {
  PURCHASE_TYPE_ICON, DefaultIcon, CUST_CC_PURCHASE_TYPE_ID, CUST_INVOICE_PURCHASE_TYPE_ID,
  CUST_PURCHASE_PURCHASE_TYPE_ID, CUST_RENTAL_PURCHASE_TYPE_ID, SFM_CONSUMABLE_PURCHASE_TYPE_ID,
  SFM_CC_PURCHASE_TYPE_ID, SFM_INVOICE_PURCHASE_TYPE_ID, SFM_RENTAL_PURCHASE_TYPE_ID
};

export default function PurchaseTypeIcon({id, purchaseType, className, ...props}) {
  const Icon = PURCHASE_TYPE_ICON.get(purchaseType.id) || DefaultIcon;
  return (
    <OverlayTrigger placement="auto" flip
                    overlay={<Tooltip id={id}>{purchaseType.category}</Tooltip>}>
      <div className={className}>
        <Icon {...props}/>
      </div>
    </OverlayTrigger>
  );
}
