import React from 'react';
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import OverlappingTimes from '../../page/OverlappingTimes';
import Locations from '../../page/Locations';

export default function Reports() {
  //YELLOWTAG:TJH - still needs work:
  //  - reloading the URL when locations report filters are active will load the overlapping time sheet report
  return (
    <Container fluid>
      <Tabs id="report-tabs" className="mb-3 mt-3" defaultActiveKey="overlapping-time">
        <Tab eventKey="overlapping-time" title="Overlapping Time">
          <OverlappingTimes/>
        </Tab>
        <Tab eventKey="locations" title="Locations">
          <Locations/>
        </Tab>
      </Tabs>
    </Container>
  );
}