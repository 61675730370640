import queryString from 'query-string';
import {useLocation} from 'react-router-dom';

export default function usePagination(pageSize) {
  const {search} = useLocation();
  const {page = 1} = queryString.parse(search);
  const pageNum = parseInt(page);
  const offset = (pageNum - 1) * pageSize;
  return {pageNum, offset};
}
