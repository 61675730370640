import {gql, useQuery} from '@apollo/client';

const PURCHASE_IMAGES_QUERY = gql`
    query purchaseImages($ids:[Int!]!){
        purchaseImages(ids:$ids){
            id
            uri
        }
    }
`;

//YELLOWTAG:TJH - we should start sharing source with the mobile app.
//  See mobile/src/hooks/usePurchaseImages.js
export default function usePurchaseImages(images) {
  images = images || [];
  const ids = images.map(({id}) => id);
  const {data, loading, error} = useQuery(PURCHASE_IMAGES_QUERY, {variables: {ids}, fetchPolicy: 'network-only'});
  const {purchaseImages} = data || {};

  return {purchaseImages, purchaseImagesLoading: loading, purchaseImagesError: error};
}

