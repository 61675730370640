import React, {useContext} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import LightboxContext from '../context/LightboxContext';

import useTimer from '../hooks/useTimer';

import PurchaseTableRow from './PurchaseTableRow';
import QuerySpinner from './QuerySpinner';
import SortableTable from './SortableTable';

const BASE_HEADERS = [
  {key: 'date', children: 'Date', sortable: true},
  {key: 'job', children: 'Job', sortable: true},
  {key: 'workOrder', children: 'WO', sortable: true},
  {key: 'amount', children: 'Amount', sortable: true},
  {key: 'purchaseType', children: 'Type', sortable: true},
  {key: 'note', children: 'Note'},
  {key: 'photo', children: 'Photo'}
];
const TECH_HEADER = {key: 'technician', children: 'Tech', sortable: true};
const VENDOR_HEADER = {key: 'vendor', style: {minWidth: '14em'}, children: 'Vendor'};

export default function PurchaseTable(
  {
    purchases, selectedPurchases, loading, showVendor, showTech, resolveAliases, onEdit, onDelete, onSelect, children,
    ...props
  }
) {
  const {setLightboxImages} = useContext(LightboxContext);
  const [alarm] = useTimer(loading);

  if (alarm) {
    return (
      <Row className="h-100">
        <Col sm={12}>
          <QuerySpinner/>
        </Col>
      </Row>
    );
  }

  function createTableBody() {
    return purchases.map(purchase => {
      const {id, vendor, indirectVendor} = purchase;
      let targetVendor = vendor;
      if (resolveAliases && vendor && vendor.isAlias) {
        targetVendor = indirectVendor;
      }
      let {name: vendorName} = targetVendor || {};
      const selected = selectedPurchases && selectedPurchases.includes(purchase);
      const vendorCell = showVendor && (<td>{vendorName}</td>);
      return (
        <PurchaseTableRow key={id} purchase={purchase} selected={selected} showTech={showTech} vendorCell={vendorCell}
                          onImageClick={setLightboxImages} onEdit={onEdit} onDelete={onDelete} onSelect={onSelect}/>
      );
    });
  }

  const body = purchases
    ? createTableBody()
    : children;

  const headers = [...BASE_HEADERS];
  if (onSelect) {
    headers.unshift({key: 'select', children: 'Select'});
  }
  if (showTech) {
    let start = 1;
    if (onSelect) {
      start = 2;
    }
    headers.splice(start, 0, TECH_HEADER);
  }
  if (showVendor) {
    headers.push({...VENDOR_HEADER, sortable: !!purchases});
  }
  headers.push({key: 'edit'});
  return (
    <Row className="overflow-auto flex-shrink-1 flex-grow-1" style={{minHeight: '33vh'}}>
      <SortableTable headers={headers} body={body} {...props}/>
    </Row>
  );
}

const ATTRIBUTE_SUBSTITUTIONS = new Map([
  ['technician', ['technician.lastName', 'technician.firstName']],
  ['job', ['school.code', 'job.name']],
  ['workOrder', ['workOrder.abbreviation', 'workOrder.number']],
  ['vendor', ['vendor.name']],
  ['purchaseType', ['purchaseType.sortOrder', 'bank.name']]
]);

export function purchaseOrderBy(columnDirectionMap) {
  return Array.from(columnDirectionMap.entries())
    .reduce((reduction, [attribute, direction]) => {
      const subs = ATTRIBUTE_SUBSTITUTIONS.get(attribute);
      if (subs) {
        subs.forEach(sub => reduction.push({attribute: sub, direction}));
      } else {
        reduction.push({attribute, direction});
      }
      return reduction;
    }, []);
}
