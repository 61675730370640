import {gql} from '@apollo/client';

export default gql`
  fragment TeacherWorkOrderFields on WorkOrder{
      id
      number
      abbreviation
      vNumber
      date
      request
      requestDate
      requester {
          id
          lastName
          firstName
      }
      status {
          id
          name
      }
  }

`;