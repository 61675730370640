import React from 'react';
import Card from 'react-bootstrap/Card';
import ImagesFormGroup from './ImagesFormGroup';

export default function ImagesCard({images, onImagesChanged, onImageDeleted}) {
  return (
    <Card body>
      <ImagesFormGroup images={images} onSelect={onImagesChanged} onDelete={onImageDeleted}/>
    </Card>
  );
}