import React from 'react';

import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

import EditButton from './EditButton';
import TrashButton from './TrashButton';

export default function SFMTimesheetCard({timeSheet, disabled, onEdit, onDelete}) {
  const {job, task, payType, notes, locked} = timeSheet;
  const {school} = job;
  const {assignment} = task || {};
  const {workOrder} = assignment || {};

  const editable = !(disabled || locked)
  return (
      <Card>
        <Card.Body>
          <Card.Title className="text-center">{school.code} {job.name}</Card.Title>
          {
            workOrder &&
            (<Card.Subtitle className="text-center">{workOrder.vNumber}</Card.Subtitle>)
          }
          {
            !editable &&
            (<div className="text-center fst-italic">Not editable</div>)
          }
          <Table className="mt-2" size="sm" striped borderless hover>
            <tbody>
            <tr>
              <td>Task</td>
              <td className={task ? undefined : "fst-italic"}>{task?.description || "No task selected"}</td>
            </tr>
            <tr>
              <td>Closed</td>
              <td>{timeSheet.closed}</td>
            </tr>
            <tr>
              <td>Pay type</td>
              <td>{payType.description}</td>
            </tr>
            <tr>
              <td>Notes</td>
              <td>{notes}</td>
            </tr>
            </tbody>
          </Table>
        </Card.Body>
        <div className="d-flex justify-content-end m-3">
          <EditButton size="sm" onClick={onEdit} disabled={!editable}/>
          <TrashButton size="sm" onClick={onDelete} disabled={!editable}/>
        </div>
      </Card>
  );
}