import React from 'react';
import moment from 'moment';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

import useEarliestPurchaseDate from '../../hooks/useEarliestPurchaseDate';

import JobAndTaskPicker from '../JobAndTaskPicker';
import TechnicianFormGroup from '../TechnicianFormGroup';
import EditModalFooter from '../EditModalFooter';

import DateFormGroup from './DateFormGroup';
import VendorFormGroup from './VendorFormGroup';
import AmountFormGroup from './AmountFormGroup';
import PurchaseTypeFormGroup from './PurchaseTypeFormGroup';
import NotesFormGroup from './NotesFormGroup';
import ImagesFormGroup from './ImagesFormGroup';

export default function EditPurchaseModal(
  {
    purchase, purchaseImages, vendorMeta, validated, loading, show, showTechs, disableTechs, onPurchaseImagesChanged,
    onPurchaseImageDeleted, onVendorMetaChange, onClose, onSubmit
  }
) {
  const {
    isSplit, setIsSplit,
    job, setJob,
    task, setTask,
    date, setDate,
    purchaseType, setPurchaseType,
    vendor, setVendor,
    amount, setAmount,
    notes, setNotes,
    technician, setTechnician,
    isReturn, setIsReturn,
    existing
  } = purchase;

  const {vendorNotListed, vendorInNotes, vendorLegible} = vendorMeta;
  const {earliestPurchaseDate} = useEarliestPurchaseDate();

  if(loading){
    return null;
  }
  let title = 'Add Purchase';
  let actionButtonLabel = 'Create';
  let progressMsg = 'Creating Purchase';
  if (existing) {
    title = 'Edit Purchase';
    actionButtonLabel = 'Update';
    progressMsg = 'Updating Purchase';
  }

  let showDateAlert = date && date.isBefore(earliestPurchaseDate) && !date.isSame(purchase.date);
  const today = moment()
    .startOf('day');
  let maxClosedAge = moment.duration(today.diff(earliestPurchaseDate))
                           .as('days');
  maxClosedAge = Math.ceil(maxClosedAge);
  return (
    <Modal size="lg" show={show} onHide={onClose}>
      <Form validated={validated} onSubmit={onSubmit} noValidate>
        <Modal.Header>
          <Modal.Title><h4 className="fw-bold">{title}</h4></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Check type="switch" id="split" label="Split" checked={isSplit}
                          onChange={({target}) => setIsSplit(target.checked)} disabled={loading}/>
            </Col>
            <Col>
              <Form.Check type="switch" id="return" label="This is a return" checked={isReturn}
                          onChange={({target}) => setIsReturn(target.checked)} disabled={loading}/>
            </Col>
          </Row>
          <Row>
            <JobAndTaskPicker job={job} task={task} maxClosedAge={maxClosedAge} onSelectJob={setJob} onSelectTask={setTask}
                              disabled={loading} collapse={isSplit}/>
          </Row>
          {
            showTechs &&
            <Row>
              <TechnicianFormGroup technician={technician} disabled={loading || disableTechs}
                                   onSelectTechnician={setTechnician} required={!disableTechs}/>
            </Row>
          }
          {
            showDateAlert && (
              <Row>
                <Col sm={12}>
                  <Alert className="text-center" variant="warning">
                    Date is before earliest purchase date: {earliestPurchaseDate.format('MM/DD/YYYY')}
                  </Alert>
                </Col>
              </Row>
            )
          }
          <Row>
            <DateFormGroup sm={3} date={date} onDateChange={setDate} disabled={loading}/>
            <VendorFormGroup sm={4} vendor={vendor} vendorNotListed={vendorNotListed} disabled={loading}
                             onVendorChange={setVendor}
                             onVendorNotListedChange={vendorNotListed => onVendorMetaChange({vendorNotListed})}/>
            <AmountFormGroup sm={3} amount={amount} onAmountChange={setAmount} disabled={loading}/>
            <PurchaseTypeFormGroup sm={2} purchaseType={purchaseType} onPurchaseTypeChange={setPurchaseType}
                                   disabled={loading}/>
          </Row>
          <Row>
            <NotesFormGroup notes={notes} vendorNotListed={vendorNotListed} disabled={loading}
                            vendorInNotes={vendorInNotes} onNotesChange={setNotes}
                            onVendorInNotesChange={vendorInNotes => onVendorMetaChange({vendorInNotes})}/>
          </Row>
          <Row>
            <ImagesFormGroup images={purchaseImages} disabled={loading} vendorNotListed={vendorNotListed}
                             vendorLegible={vendorLegible} onImagesChange={onPurchaseImagesChanged}
                             onImageDeleted={onPurchaseImageDeleted}
                             onVendorLegibleChange={vendorLegible => onVendorMetaChange({vendorLegible})}/>
          </Row>
        </Modal.Body>
        <EditModalFooter loading={loading} progressMsg={progressMsg} actionLabel={actionButtonLabel}
                         onClose={onClose}/>
      </Form>
    </Modal>
  );
}
