import {useCallback, useContext} from 'react';
import {useMutation, gql} from '@apollo/client';

import WorkOrderContext from '../context/WorkOrderContext';

const UPDATE_TASK_MUTATION = gql`
    mutation updateWorkOrderTask($taskID:Int!, $techID:Int!, $description:String!, $isComplete:Boolean!){
        updateWorkOrderTask(taskID:$taskID, techID:$techID, description:$description, isComplete: $isComplete){
            id
            techID
            description
            isComplete
        }
    }
`;

export default function useUpdateTask() {
  const {id:workOrderID, setTasks:setNewWOTasks} = useContext(WorkOrderContext);
  const [gqlUpdateTask, {data, loading, error}] = useMutation(UPDATE_TASK_MUTATION);

  if (error && !data?.length) {
    throw error;
  }

  const updateTask = useCallback(task => {
    let result;
    if(workOrderID){
      const {id: taskID, technician, ...theRest} = task;
      const {id: techID} = technician;
      result = gqlUpdateTask({variables: {taskID, techID, ...theRest}});
    } else {
      setNewWOTasks(newWOTasks => newWOTasks.map((t, i) => {
        let result = t;
        if (i === task.id) {
          const {technician, description, isComplete} = task;
          result = {technician, description, isComplete};
        }
        return result;
      }));
    }

    return result;
  }, [workOrderID, gqlUpdateTask, setNewWOTasks]);

  return [updateTask, {updateTaskLoading: loading}];
}