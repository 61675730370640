import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {useParams} from 'react-router-dom';

import useGetVendor from '../../hooks/useGetVendor';
import useGetVendorAliases from '../../hooks/useGetVendorAliases';

import AddAliasDialog from '../../components/AddAliasDialog';
import AliasList from './AliasList';
import Header from './Header';

export default function VendorAliases() {
  let {vendorID} = useParams();
  const [getVendor, {vendor}] = useGetVendor();
  const [getVendorAliases, {aliases, refetchAliases}] = useGetVendorAliases()
  const [showAddAlias, setShowAddAlias] = useState(false);

  if (vendorID) {
    vendorID = parseInt(vendorID);
  }

  useEffect(() => {
    getVendor(vendorID);
    getVendorAliases(vendorID);
  }, [vendorID, getVendor, getVendorAliases]);

  function handleCloseAliasDialog(){
    setShowAddAlias(false);
    refetchAliases();
  }

  return (
    <Container className="d-flex flex-column h-100" fluid>
      <Header vendor={vendor} onShowAddAlias={() => setShowAddAlias(true)}/>
      <AliasList aliases={aliases} onConvert={refetchAliases} onDelete={refetchAliases}/>
      {showAddAlias && (<AddAliasDialog vendor={vendor} onClose={handleCloseAliasDialog}/>)}
    </Container>
  );
}
