import React, {useContext, useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import WorkOrderContext from '../../context/WorkOrderContext';
import useTechnicianSearch from '../../hooks/useTechnicianSearch';

import LookupByName from '../LookupByName';

export default function TaskRow({task, onAddTask, onUpdateTask, onDeleteTask, locked}) {
  const [technician, setTechnician] = useState(task?.technician);
  const [description, setDescription] = useState(task?.description || '');
  const [isComplete, setIsComplete] = useState(task?.isComplete || false);
  const searchTechnicians = useTechnicianSearch(false);
  const [isDirty, setIsDirty] = useState(false);
  const {status} = useContext(WorkOrderContext);

  const {id: taskID} = task || {};
  const exists = typeof taskID !== 'undefined';

  useEffect(() => {
    if (task) {
      setTechnician(task.technician);
      setDescription(task.description || '');
      setIsComplete(!!task.isComplete);
    } else {
      setTechnician(null);
      setDescription('');
      setIsComplete(false);
    }
  }, [task, setTechnician, setDescription, setIsComplete]);

  useEffect(() => {
    setIsDirty(technician !== task?.technician
      || description !== (task?.description || '')
      || isComplete !== (task?.isComplete));
  }, [task, technician, description, isComplete, setIsDirty]);

  function handleChangeDescription({target}) {
    setDescription(target.value);
  }

  function handleAddTask() {
    onAddTask(technician, description, isComplete);
  }

  async function handleUpdateTask() {
    onUpdateTask({...task, technician, description, isComplete});
    setIsDirty(false);
  }

  function handleDeleteTask() {
    onDeleteTask(task);
  }

  return (
    <Row>
      <Col sm={4}>
        <LookupByName selected={technician} onSelect={setTechnician} fetch={searchTechnicians}/>
      </Col>
      <Col sm={4}>
        <Form.Control type="text" placeholder="task" value={description} onChange={handleChangeDescription}/>
      </Col>
      <Col className="p-2" sm={2}>
        <Form.Switch id={`task-${taskID}-complete`} label="Complete" checked={isComplete}
                     onChange={({target}) => setIsComplete(target.checked)}
                     disabled={'terminal' === status?.meta}/>
      </Col>
      <Col sm={2}>
        {
          exists
            ? (
              <>
                <Button variant="outline-primary" className="m-1" onClick={handleUpdateTask}
                        disabled={!isDirty}>
                  Update
                </Button>
                {
                  !locked && (
                    <Button variant="outline-danger" className="m-1" onClick={handleDeleteTask}>Delete</Button>
                  )
                }
              </>
            )
            : (
              <Button variant="outline-primary" className="m-1" onClick={handleAddTask}
                      disabled={!(technician && description)}>
                Add
              </Button>
            )
        }
      </Col>
    </Row>
  );
}