import React, {useContext, useEffect} from 'react';
import {Navigate} from 'react-router-dom';

import Alert from 'react-bootstrap/Alert';

import SessionContext from '../../context/session';
import QueryContext from '../../context/QueryContext';

import useTimeSheetQueryParams, {toUrl, BASE_QUERY} from '../../hooks/useTimeSheetQueryParams';
import useQueryContext from '../../hooks/useQueryContext';


import TimeSheetsContainer from './TimeSheetsContainer';

const ROUTE_CONTEXT_KEY = 'page.TimeSheets';
export default function TimeSheets() {
  const {user} = useContext(SessionContext);
  const [tsQuery, setTSQuery] = useTimeSheetQueryParams();
  const {redirect, query, handleUpdateQuery}
    = useQueryContext(ROUTE_CONTEXT_KEY, tsQuery, setTSQuery, toUrl, BASE_QUERY);

  useEffect(() => {
    const {technicianIDs} = query;
    if (user && !technicianIDs?.includes(user.userID)) {
      handleUpdateQuery({...query, technicianIDs: [...(technicianIDs || []), user.userID]});
    }
  }, [user, query, handleUpdateQuery]);

  if (redirect) {
    return (<Navigate to={redirect}/>);
  }

  function handleClearQuery() {
    handleUpdateQuery({});
  }

  return (
    <QueryContext.Provider value={{query, handleUpdateQuery, handleClearQuery}}>
      <Alert className="m-3" variant="info">
        This is a working Beta version. If you have questions or concerns call Pat Millius:
        &nbsp;<a href="tel:615-715-6884">615-715-6884</a>
      </Alert>
      <TimeSheetsContainer/>
    </QueryContext.Provider>
  );
}