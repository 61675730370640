import React, {useContext} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import SessionContext from '../context/session';

import useTimer from '../hooks/useTimer';

import WorkOrderTableRow from './WorkOrderTableRow';
import QuerySpinner from './QuerySpinner';
import SortableTable from './SortableTable';

const BASE_HEADERS = [
  {key: 'date', children: 'Submitted Date', sortable: true},
  {key: 'number', children: 'Number', sortable: true},
  {key: 'requester', children: 'Requester', sortable: true},
  {key: 'name', children: 'Work Order Name', sortable: true},
  {key: 'status', children: 'Status', sortable: true},
  {key: 'assigned', children: 'Assigned'},
  {key: 'division', children: 'Division', sortable: true}
];

const TEACHER_KEYS = ['date', 'number', 'requester', 'name', 'status'];
export default function WorkOrderTable({workOrders, loading, children, ...props}) {
  const {isTeacher} = useContext(SessionContext);
  const [alarm] = useTimer(loading);

  if (alarm) {
    return (
      <Row className="h-100">
        <Col sm={12}>
          <QuerySpinner/>
        </Col>
      </Row>
    );
  }

  function createTableBody() {
    return workOrders.map(workOrder => {
      const {id} = workOrder;
      return <WorkOrderTableRow key={id} workOrder={workOrder}/>;
    });
  }

  const body = workOrders ? createTableBody() : children;

  let headers = [...BASE_HEADERS];
  if (isTeacher) {
    headers = headers.filter(({key}) => TEACHER_KEYS.includes(key));
  }
  return (
    <Row className="overflow-auto flex-shrink-1 flex-grow-1" style={{minHeight: '33vh'}}>
      <SortableTable className="table-hover" headers={headers} body={body} {...props} />
    </Row>
  );
}

const ATTRIBUTE_SUBSTITUTIONS = new Map([
  ['number', ['abbreviation', 'number']],
  ['requester', ['requester.lastName', 'requester.firstName']],
  ['status', ['status.name']],
  ['division', ['division.name']]
]);

export function workOrderOrderBy(columnDirectionMap) {
  return Array.from(columnDirectionMap.entries())
    .reduce((reduction, [attribute, direction]) => {
        const subs = ATTRIBUTE_SUBSTITUTIONS.get(attribute);
        if (subs) {
          subs.forEach(sub => reduction.push({attribute: sub, direction}));
        } else {
          reduction.push({attribute, direction});
        }
        return reduction;
      },
      []
    );
}

