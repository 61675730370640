import React, {useCallback, useState} from 'react';
import EditPurchaseDialog from '../components/EditPurchaseDialog';

export default function useEditPurchaseDialog(refetchPurchases) {
  const [showEditPurchaseDialog, setShowEditPurchaseDialog] = useState(false);
  const [purchase, setPurchase] = useState(null);

  const handleEditPurchaseDialogClose = useCallback(shouldRefetch => {
    setShowEditPurchaseDialog(false);
    setPurchase(null);
    if (shouldRefetch) {
      refetchPurchases();
    }
  }, [setShowEditPurchaseDialog, setPurchase, refetchPurchases]);

  const handleEditPurchase = useCallback(purchase => {
    setPurchase(purchase);
    setShowEditPurchaseDialog(true);
  }, [setPurchase, setShowEditPurchaseDialog]);

  return [
    handleEditPurchase,
    (showEditPurchaseDialog && <EditPurchaseDialog onClose={handleEditPurchaseDialogClose} purchase={purchase} show/>)];
}
