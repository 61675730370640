import React from 'react';
import moment from 'moment';

import FormattedDate from './FormattedDate';

export default function OverlappingTimeTableRow({overlappingTime}) {
  const {techType, periodFrom, periodTo, overlaps, uri} = overlappingTime;
  const inclusivePeriodTo = moment(periodTo).subtract(1, 'day');
  return (
    <tr>
      <td>{techType}</td>
      <td>
        <FormattedDate date={periodFrom}/>
      </td>
      <td>
        <FormattedDate date={inclusivePeriodTo}/>
      </td>
      <td>{overlaps}</td>
      <td>
        {uri && (
          <a href={uri} target="_blank" rel="noreferrer">
            View Report
          </a>
        )}
      </td>
    </tr>
  );
}
