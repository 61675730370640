import {gql, useQuery} from '@apollo/client';

const BANKS_QUERY = gql`
  query banks{
      banks{
          id
          name
      }
  }
`;

export default function useBanks(){
  const {data, loading, error} = useQuery(BANKS_QUERY);

  if(error && !(data && data.length)){
    console.error(error);
    throw error;
  }

  const {banks} = data || {};

  return {banks, banksLoading:loading};
}
