import {gql, useQuery} from '@apollo/client';

const GET_WORK_ORDER_STATUS_HISTORY = gql`
    query getHistory($workOrderID:Int!){
        statusHistory(workOrderID: $workOrderID){
            id
            timeStamp
            automated
            status{
                id
                name
            }
            user {
                id
                userType
                lastName
                firstName
            }
        }
    }
`;

export default function useStatusHistory(workOrderID) {
  const {data, loading, error} = useQuery(GET_WORK_ORDER_STATUS_HISTORY,
    {variables: {workOrderID}, skip: !workOrderID, fetchPolicy: 'cache-and-network'});

  if (error && !data?.length) {
    throw error;
  }

  const {statusHistory} = data || {};
  return {statusHistory, statusHistoryLoading: loading};
}