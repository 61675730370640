import React, {useCallback, useContext, useMemo, useRef} from 'react';
import moment from 'moment';
import {Calendar, momentLocalizer, Views} from 'react-big-calendar';

import TimeSheetsContext from '../context/TimeSheetsContext';
import SFMTimesheetEventWrapper from './SFMTimesheetEventWrapper';
import CalendarFooter from './CalendarFooter';

const localizer = momentLocalizer(moment);

function toLocalDate(dateString) {
  return moment(dateString)
    .toDate();
}

export default function TimeSheetCalendar({timeSheets, onEmptyTimeRangeSelected, ...props}) {
  const calendarContainerRef = useRef(null);
  const {
    setStartDateTime,
    setEndDateTime,
    calendarDate, setCalendarDate,
    calendarView, setCalendarView,
    setSelectedTimeSheet,
    earliestPayrollDate
  } = useContext(TimeSheetsContext);

  const events = useMemo(
    () => timeSheets?.map((timeSheet) => {
        const {clockIn, clockOut, job} = timeSheet;
        return {
          ...timeSheet,
          start: toLocalDate(clockIn),
          end: toLocalDate(clockOut),
          title: `${job.school.code} ${job.name}`
        };
      }
    ),
    [timeSheets]
  );

  const date = useMemo(
    () => calendarDate.toDate(),
    [calendarDate]
  );

  const scrollToTime = useMemo(
    () => {
      const minHours = events?.length
                       ? events.reduce((reduction, {start}) => Math.min(reduction, start.getHours()), 23)
                       : Math.max(moment().hour() - 1, 0);

      let result = new Date(date);
      result.setHours(minHours - 1);
      result.setMinutes(0);
      result.setSeconds(0);
      result.setMilliseconds(0);
      return result;
    },
    [events, date]
  );

  const components = useMemo(() => ({
    eventWrapper: SFMTimesheetEventWrapper
  }), []);

  const formats = useMemo(() => ({
    dayRangeHeaderFormat: (range, culture, localizer) => localizer.format(range.start, 'MMMM YYYY', culture)
  }), []);

  const handleRangeChange = useCallback((newRange) => {
    let result = newRange;
    if (Array.isArray(newRange)) {
      result = {
        start: newRange[0],
        end: moment(newRange[newRange.length - 1])
          .endOf('day')
          .toDate()
      };
    }

    result = {...result};
    setStartDateTime(moment(result.start));
    setEndDateTime(moment(result.end));
    return newRange;
  }, [setStartDateTime, setEndDateTime]);

  const handleOnNavigate = useCallback((newDate) => {
    setCalendarDate(moment(newDate));
    setSelectedTimeSheet(null);
  }, [setCalendarDate, setSelectedTimeSheet]);

  const handleOnSelecting = useCallback(({start, end}) => {
    const mStart = moment(start);
    const mEnd = moment(end);

    setSelectedTimeSheet(null);
    return !mStart.isBefore(earliestPayrollDate)
      && !events.find(e => {
        let eStart = moment(e.start);
        let eEnd = moment(e.end);

        return mStart.isBetween(eStart, eEnd, 'minute')
          || mEnd.isBetween(eStart, eEnd, 'minute')
          || (mStart.isSameOrBefore(eStart)
            && mEnd.isSameOrAfter(eEnd));
      });
  }, [events, setSelectedTimeSheet, earliestPayrollDate]);

  const handleSelectEvent = useCallback((event) => {
    setSelectedTimeSheet(event);
  }, [setSelectedTimeSheet]);

  const handleOnSelectSlot = useCallback((slotInfo) => {
    const {start, end, action} = slotInfo;
    setSelectedTimeSheet(null);
    if ('select' === action
      && !moment(start).isBefore(earliestPayrollDate)
      && start.getDay() === end.getDay()) {
      onEmptyTimeRangeSelected({clockIn: moment(start), clockOut: moment(end)});
    }
  }, [onEmptyTimeRangeSelected, setSelectedTimeSheet, earliestPayrollDate]);

  const handleOnView = useCallback(newView => {
    setSelectedTimeSheet(null);
    setCalendarView(newView);
  }, [setSelectedTimeSheet, setCalendarView]);

  const dayPropGetter = useCallback(date => {
    if (moment(date).isBefore(earliestPayrollDate)) {
      return {className: 'bg-dark-subtle'};
    }
  }, [earliestPayrollDate]);

  const eventPropGetter = useCallback((event, start, end, isSelected) => {
    if(moment(start).isBefore(earliestPayrollDate)) {
      return {className: 'bg-secondary text-dark'};
    }
  }, [earliestPayrollDate])

  const {children, ...theRest} = props;
  return (
    <div ref={calendarContainerRef} style={{height: "80%"}} {...theRest}>
      {children}
      <Calendar components={components} localizer={localizer} formats={formats} defaultView={Views.WEEK}
                dayLayoutAlgorithm="no-overlap" showMultiDayTimes={true} view={calendarView} step={15} timeslots={2}
                date={date} scrollToTime={scrollToTime} events={events} dayPropGetter={dayPropGetter}
                eventPropGetter={eventPropGetter} onNavigate={handleOnNavigate} onRangeChange={handleRangeChange}
                onSelecting={handleOnSelecting} onSelectEvent={handleSelectEvent} onSelectSlot={handleOnSelectSlot}
                onView={handleOnView} selectable/>
      <CalendarFooter calendarContainerRef={calendarContainerRef} timeSheets={timeSheets}/>
    </div>
  );
}