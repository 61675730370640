import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import useEarliestPurchaseDate from '../../hooks/useEarliestPurchaseDate';
import ACMSingleDatePicker from '../../components/ACMSingleDatePicker';

export default function MinPurchaseDateDialog({onClose}) {
  const [lMinPurchaseDate, setLMinPurchaseDate] = useState();
  const {earliestPurchaseDate, setEarliestPurchaseDate} = useEarliestPurchaseDate();

  useEffect(() => {
    setLMinPurchaseDate(earliestPurchaseDate);
  }, [earliestPurchaseDate]);

  async function handleSubmit(e) {
    e.preventDefault();

    const {errors} = await setEarliestPurchaseDate(lMinPurchaseDate);
    if (errors && errors.length) {
      throw errors[0];
    }
    onClose();
  }

  return (
    <Modal onHide={onClose} show>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            <h4 className="fw-bold">Disallow Purchases Before Date</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex align-items-center">
            <Form.Group className="mx-auto" controlId="disallowDate" as={Col} sm={3}>
              <Form.Label>Earliest Date</Form.Label>
              <ACMSingleDatePicker date={lMinPurchaseDate} onDateChange={setLMinPurchaseDate}/>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">Update</Button>
          <Button variant="outline-primary" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
