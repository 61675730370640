import React from 'react';
import useWorkOrderStatuses from '../hooks/useWorkOrderStatuses';
import SFMItemSelect from './SFMItemSelect';

export default function WorkOrderStatusPicker({status, disabled, onStatusSelect, ...props}) {
  const {workOrderStatuses, workOrderStatusesLoading} = useWorkOrderStatuses();
  return (
    <SFMItemSelect value={status} options={workOrderStatuses} placeholder="Status" isSearchable={false}
                   isLoading={workOrderStatusesLoading} isDisabled={disabled} onChange={onStatusSelect} required
                   {...props}/>
  );
}
