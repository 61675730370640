import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import {useLocation, useNavigate} from 'react-router-dom';
import queryString from 'query-string';

import useConnection from '../../hooks/useConnection';

const CURRENT_PAGE_MARGIN = 2;
const CURRENT_PAGE_WINDOW = (2 * CURRENT_PAGE_MARGIN);
const COLLAPSE_PAGE_BOUNDARY = 12;

function getCurrentPageMargin(currentPage, pageCount) {
  if (pageCount < COLLAPSE_PAGE_BOUNDARY) {
    return {min: 1, max: pageCount};
  }

  let min = currentPage - CURRENT_PAGE_MARGIN;
  if (min === CURRENT_PAGE_MARGIN || min === CURRENT_PAGE_MARGIN + 1) {
    min = CURRENT_PAGE_MARGIN + 2;
  }
  const topLimitForMin = pageCount - CURRENT_PAGE_WINDOW;
  if (min === topLimitForMin - 1
    || min === topLimitForMin - 2) {
    min = topLimitForMin - 3;
  }

  min = Math.min(Math.max(1, min), pageCount - CURRENT_PAGE_WINDOW);
  let max = min + CURRENT_PAGE_WINDOW;
  max = Math.min(Math.max(1, max), pageCount);

  return {min, max};
}

function* itemLabels(currentPage, pageCount) {
  let currentLabel = 1;
  yield currentLabel++;
  //beginning
  const {min, max} = getCurrentPageMargin(currentPage, pageCount);
  while (currentLabel > min && currentLabel <= max) {
    yield currentLabel++;
  }
  if (currentLabel >= pageCount) {
    return;
  }
  //ellipses
  yield null;

  //optional middle
  if (currentLabel < min && max < pageCount) {
    currentLabel = min;
    while (currentLabel <= max) {
      yield currentLabel++;
    }
    //ellipses
    currentLabel = pageCount;
    yield null;
  } else {
    currentLabel = currentLabel < min
      ? min
      : pageCount;
  }

  while (currentLabel <= pageCount) {
    yield currentLabel++;
  }
}

export default function Items({connection, pageSize}) {
  const location = useLocation();
  const navigate = useNavigate();
  const {currentPage, pageCount} = useConnection(connection, pageSize);
  const items = [];
  let ellipsesCount = 0;
  const {pathname, search} = location;
  const {page, ...queryParams} = queryString.parse(search);

  for (let value of itemLabels(currentPage, pageCount)) {
    if (null === value) {
      items.push(<Pagination.Ellipsis key={`ellipses_${ellipsesCount++}`}/>);
    } else {
      const searchString = queryString.stringify({...queryParams, page: value});
      items.push(
        <Pagination.Item key={value} active={currentPage === value}
                         onClick={() => navigate(`${pathname}?${searchString}`)}>
          {value}
        </Pagination.Item>
      );
    }
  }

  const firstPageQs = queryString.stringify({...queryParams, page: 1});
  const prevPageQs = queryString.stringify({...queryParams, page: Math.max(1, currentPage - 1)});
  const nextPageQs = queryString.stringify({...queryParams, page: Math.min(currentPage + 1, pageCount)});
  const lastPageQs = queryString.stringify({...queryParams, page: pageCount});
  return (
    <>
      <Pagination.First className="me-3" key="first" onClick={() => navigate(`${pathname}?${firstPageQs}`)}/>
      <Pagination.Prev key="prev" onClick={() => navigate(`${pathname}?${prevPageQs}`)}/>
      {items}
      <Pagination.Next key="next" onClick={() => navigate(`${pathname}?${nextPageQs}`)}/>
      <Pagination.Last className="ms-3" key="last" onClick={() => navigate(`${pathname}?${lastPageQs}`)}/>
    </>
  );
}
