import React from 'react';
import Card from 'react-bootstrap/Card';

import TechTaskFormGroup from './TechTaskFormGroup';

export default function TasksAndTimeCard() {
  return (
    <Card body>
      <TechTaskFormGroup sm={12}/>
    </Card>
  );
}