import React, {useContext, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Navigate} from 'react-router-dom';

import SessionContext from '../../context/session';
import QueryContext from '../../context/QueryContext';

import useColumnSort from '../../hooks/useColumnSort';
import useEditPurchaseDialog from '../../hooks/useEditPurchaseDialog';
import useFilteredPurchases from '../../hooks/useFilteredPurchases';
import useDeletePurchase from '../../hooks/useDeletePurchase';
import usePurchaseListQueryParams, {toUrl, BASE_QUERY} from '../../hooks/usePurchaseListQueryParams';
import useBulkDeletePurchases from '../../hooks/useBulkDeletePurchases';
import useQueryContext from '../../hooks/useQueryContext';

import ConnectionPagination from '../../components/ConnectionPagination';
import PurchaseTable, {purchaseOrderBy} from '../../components/PurchaseTable';

import Header from './Header';
import SplitPurchasesNotice from './SplitPurchasesNotice';
import BulkDeleteDialog from './BulkDeleteDialog';

const ROUTE_CONTEXT_KEY = 'page.Purchases';

/**
 * @return {null}
 */
export default function Purchases() {
  const [query, setQuery] = usePurchaseListQueryParams();
  const {redirect, ...purchaseQueryContext} = useQueryContext(ROUTE_CONTEXT_KEY, query, setQuery, toUrl, BASE_QUERY);
  const {columnSortDirection, onSort} = useColumnSort(new Map([['date', 'DESC']]));

  const {resolveAliases = false} = query;

  const {purchasesLoading, purchasesConnection, refetchPurchases, pageSize, setPageSize} =
    useFilteredPurchases(purchaseQueryContext.query, purchaseOrderBy(columnSortDirection));
  // noinspection JSUnusedLocalSymbols
  const [handleEditPurchase, editPurchaseDialog] = useEditPurchaseDialog(refetchPurchases);
  const [handleDeletePurchase, deletePurchaseDialog] = useDeletePurchase(refetchPurchases);
  const [bulkDeletePurchases, selectedPurchases, setBulkDeletePurchases] = useBulkDeletePurchases(refetchPurchases);
  const {user} = useContext(SessionContext);
  const [showBulkDelete, setShowBulkDelete] = useState(false);

  if (redirect) {
    return (<Navigate to={redirect} replace/>);
  }

  function handleBulkDelete() {
    setShowBulkDelete(true);
  }

  function handleConfirmBulkDelete() {
    bulkDeletePurchases();
    setShowBulkDelete(false);
  }

  const {purchases, amountSum, billingTotalSum} = purchasesConnection || {};
  return (
    <QueryContext.Provider value={purchaseQueryContext}>
      <Container className="d-flex flex-column h-100" fluid>
        <Header amountSum={amountSum} billingTotalSum={billingTotalSum} orderBy={columnSortDirection}
                bulkDeleteEnabled={selectedPurchases.length > 0} onNewPurchase={() => handleEditPurchase({})}
                onBulkDelete={handleBulkDelete}/>
        {
          user.canMutateAllPurchases() &&
          <SplitPurchasesNotice/>
        }
        <PurchaseTable sortDirection={columnSortDirection} onSort={onSort} loading={purchasesLoading}
                       purchases={purchases} selectedPurchases={selectedPurchases} resolveAliases={resolveAliases}
                       onEdit={handleEditPurchase} onDelete={handleDeletePurchase}
                       onSelect={user.canDeleteAnyPurchase() && setBulkDeletePurchases} showTech showVendor/>
        {purchasesConnection && <ConnectionPagination connection={purchasesConnection} pageSize={pageSize}
                                                      onChangePageSize={setPageSize}/>}
        {editPurchaseDialog}
        {deletePurchaseDialog}
        <BulkDeleteDialog show={showBulkDelete} selectedPurchases={selectedPurchases}
                          onConfirm={handleConfirmBulkDelete}
                          onCancel={() => setShowBulkDelete(false)}/>
      </Container>
    </QueryContext.Provider>
  );
}
