import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';

export const MomentParam = Object.freeze({
  encode(value) {
    return value && value.format(DATE_FORMAT);
  },

  decode(value) {
    return value && moment(value, DATE_FORMAT);
  }
});
