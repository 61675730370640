import {useCallback} from 'react';

import {gql, useMutation} from '@apollo/client';

const ADD_WORK_ORDER_MUTATION = gql`
    mutation addWorkOrder(
        $schoolID: Int!
        $requestDate: String!
        $request: String!
        $name: String
        $workOrderTypeID: Int
        $divisionID: Int
        $statusID: Int
        $priority : Int
        $tasks:[TaskTechAndDescription!]
        $comments:[String!]
        $images: [Upload!]!
    ) {
        addWorkOrder(
            schoolID: $schoolID
            requestDate: $requestDate
            request: $request
            name: $name
            workOrderTypeID: $workOrderTypeID
            divisionID: $divisionID
            statusID: $statusID
            priority: $priority
            tasks: $tasks
            comments: $comments
            images: $images
        ) {
            id
        }
    }
`;

const NULL_ID = {id: null};
export default function useAddWorkOrder(options) {
  const [gqlAddWorkOrder, {loading, error}] = useMutation(ADD_WORK_ORDER_MUTATION, options);

  const addWorkOrder = useCallback(workOrder => {
    const {school, requestDate: mRequestDate, request, name, workOrderType, division, status, ...theRest} = workOrder;
    const {id: schoolID} = school;
    const {id: workOrderTypeID} = workOrderType || NULL_ID;
    const {id: divisionID} = division || {};
    const {id: statusID} = status || {};
    const requestDate = mRequestDate.format('YYYY-MM-DD');
    const variables = {
      ...theRest, schoolID, request, name, workOrderTypeID, divisionID, statusID, requestDate
    };

    return gqlAddWorkOrder({variables});
  }, [gqlAddWorkOrder]);

  return [addWorkOrder, {addWorkOrderLoading: loading, addWorkOrderError: error}];
}
