import {
  NumericArrayParam,
  useQueryParams,
  encodeQueryParams
} from 'use-query-params';
import queryString from 'query-string';

const PARAM_CONFIG_MAP = {
  technicianIDs: NumericArrayParam
};

export default function useTimeSheetQueryParams(){
  return useQueryParams(PARAM_CONFIG_MAP);
}

export function toUrl(query, {pathname}){
  const qs = queryString.stringify(encodeQueryParams(PARAM_CONFIG_MAP, query));
  return `${pathname}?${qs}`;
}

export const BASE_QUERY = Object.freeze({
  technicianIDs: undefined
})