import {useCallback, useContext} from 'react';
import {useMutation, gql} from '@apollo/client';

import WorkOrderContext from '../context/WorkOrderContext';
import TasksContext from '../context/TasksContext';

const ADD_TASK_MUTATION = gql`
    mutation addWorkOrderTask($workOrderID:Int!, $techID:Int!, $description:String!, $isComplete:Boolean!){
        addWorkOrderTask(
            workOrderID: $workOrderID, 
            techID: $techID, 
            description: $description, 
            isComplete: $isComplete){
            id
        }
    }
`;

export default function useAddTask() {
  const {id: workOrderID, setTasks: setNewWOTasks} = useContext(WorkOrderContext);
  const {refetchTasks} = useContext(TasksContext);

  const [gqlAddWOTask, {data, error, loading}] = useMutation(ADD_TASK_MUTATION, {onCompleted: refetchTasks});

  if (error && !data?.length) {
    throw error;
  }

  const addWorkOrderTask = useCallback(async function _addTask(technician, description, isComplete) {
    if (workOrderID) {
      const {id: techID} = technician;
      await gqlAddWOTask({variables: {workOrderID, techID, description, isComplete}});
    } else {
      setNewWOTasks(newWOTasks => [...newWOTasks, {technician, description, isComplete}]);
    }
  }, [workOrderID, gqlAddWOTask, setNewWOTasks]);

  return [addWorkOrderTask, {addTaskLoading: loading, addTaskError: error}];
}