import {useContext, useEffect, useState} from 'react';
import {gql, useLazyQuery} from '@apollo/client';

import SessionContext from '../context/session';

const JOBS_QUERY = gql`
    query fetchJobsForSchool($schoolID: Int!, $includeGlobal:Boolean){
        jobsConnection(schoolID:$schoolID, includeGlobal: $includeGlobal, pageSize:200)
        @connection(key:"jobs", filter:["schoolID", "includeGlobal"]){
            jobs {
                id
                name
                schoolID
                isSFMPurchaseAssignable
            }
        }
    }
`;

export default function useSchoolJobs() {
  const [fetchJobs, {data, loading, error}] = useLazyQuery(JOBS_QUERY);
  const [jobClient, setJobClient] = useState(null);
  const {user} = useContext(SessionContext);

  useEffect(() => {
    if (jobClient) {
      fetchJobs({variables: {schoolID: jobClient.id, includeGlobal: user.canViewAllJobs()}});
    }
  }, [jobClient, fetchJobs, user]);

  if (error) {
    console.error(error);
    throw error;
  }

  let jobs = null;
  if (data) {
    ({jobs} = data.jobsConnection);
  }

  return {setJobClient, jobs, jobLoading: loading};
}
