import React from 'react';
import Button from 'react-bootstrap/Button';

import FilteredListHeader from '../../components/FilteredListHeader';
import WorkOrderFilterPills from '../../components/WorkOrderFilterPills';
import WorkOrderFilterForm from './WorkOrderFilterForm';

export default function Header({workOrderCount, onNewWorkOrder}) {
  return (
    <FilteredListHeader title="Work Orders"
                        FilterPills={<WorkOrderFilterPills/>}
                        FilterForm={<WorkOrderFilterForm workOrderCount={workOrderCount}/>}
                        Actions={<Button onClick={onNewWorkOrder}>New Work Order</Button>}/>
  );
}
