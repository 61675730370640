import React, {useContext} from 'react';

import QueryContext from '../context/QueryContext';

import {collectionMemberMapper} from '../lib/filterUtils';

import useSchools from '../hooks/useSchools';
import usePurchaseTypes from '../hooks/usePurchaseTypes';
import useBanks from '../hooks/useBanks';
import useTechnicians from '../hooks/useTechnicians';
import useJobs from '../hooks/useJobs';
import useWorkOrders from '../hooks/useWorkOrders';

import {SFM_CC_PURCHASE_TYPE_ID} from './PurchaseTypeIcons';
import SFMFilterPill from './SFMFilterPill';
import FilterPills from './FilterPills';
import DateRangePill from './DateRangePill';
import ClientPills from './ClientPills';
import TechnicianPills from './TechnicianPills';

export default function PurchaseFilterPills() {
  const {query, handleUpdateQuery, handleClearQuery} = useContext(QueryContext);
  const {
    startDate, endDate, isSplit, resolveAliases, isReturn, clientIDs = [], purchaseTypeIDs = [], bankIDs = [],
    technicianIDs = [], jobIDs = [], workOrderIDs = []
  } = query;

  const {schools} = useSchools();
  const {purchaseTypes: allPurchaseTypes} = usePurchaseTypes();
  const {banks: allBanks} = useBanks();
  const {technicians = []} = useTechnicians(technicianIDs);
  const {jobs = []} = useJobs(jobIDs);
  const {workOrders = []} = useWorkOrders(workOrderIDs);

  function handleRemoveClient(client) {
    handleUpdateQuery({...query, clientIDs: clientIDs.filter(id => id !== client.id), page: 1});
  }

  function handleRemovePurchaseType(purchaseType) {
    const newQuery = {...query, purchaseTypeIDs: purchaseTypeIDs.filter(id => id !== purchaseType.id)};
    if (SFM_CC_PURCHASE_TYPE_ID === purchaseType.id) {
      newQuery.bankIDs = undefined;
    }
    handleUpdateQuery({...newQuery, page: 1});
  }

  function handleClearDateFilter() {
    handleUpdateQuery({...query, startDate: null, endDate: null, page: 1});
  }

  function handleClearSplitFilter() {
    handleUpdateQuery({...query, isSplit: null, page: 1});
  }

  function handleClearReturnFilter() {
    handleUpdateQuery({...query, isReturn: null, page: 1});
  }

  function handleClearResolveAliasesFilter() {
    handleUpdateQuery({...query, resolveAliases: null, page: 1});
  }

  function handleRemoveBankFilter(bank) {
    handleUpdateQuery({...query, bankIDs: bankIDs.filter(id => id !== bank.id), page: 1});
  }

  function handleRemoveTech(technician) {
    handleUpdateQuery({...query, technicianIDs: technicianIDs.filter(id => id !== technician.id), page: 1});
  }

  function handleRemoveJob(job) {
    return () => handleUpdateQuery({...query, jobIDs: jobIDs.filter(id => id !== job.id), page:1});
  }

  function handleRemoveWorkOrder(workOrder) {
    return () => handleUpdateQuery({...query, workOrderIDs: workOrderIDs.filter(id => id !== workOrder.id), page:1});
  }

  let splitFilter = null;
  if ('boolean' === typeof isSplit) {
    splitFilter = 'Not split';
    if (isSplit) {
      splitFilter = 'Split';
    }
  }

  let returnFilter = null;
  if ('boolean' === typeof isReturn) {
    returnFilter = 'Purchase';
    if (isReturn) {
      returnFilter = 'Return';
    }
  }

  let resolveAliasesFilter = null;
  if (resolveAliases) {
    resolveAliasesFilter = 'Resolve Aliases';
  }

  const clearDisabled = !(
    startDate
    || endDate
    || splitFilter
    || resolveAliases
    || clientIDs.length
    || purchaseTypeIDs.length
    || bankIDs.length
    || technicianIDs.length
    || workOrderIDs.length
  );

  const clients = clientIDs.map(collectionMemberMapper(schools))
                           .filter(c => !!c);
  const purchaseTypes = purchaseTypeIDs.map(collectionMemberMapper(allPurchaseTypes))
                                       .filter(c => !!c);
  const banks = bankIDs.map(collectionMemberMapper(allBanks))
                       .filter(c => !!c);
  return (
    <FilterPills clearDisabled={clearDisabled} onClearQuery={handleClearQuery}>
      <DateRangePill startDate={startDate} endDate={endDate} onClearDateFilter={handleClearDateFilter}/>
      {
        splitFilter && (
          <SFMFilterPill key="split-filter" onRemove={handleClearSplitFilter}>
            {splitFilter}
          </SFMFilterPill>
        )
      }
      {
        resolveAliasesFilter && (
          <SFMFilterPill key="resolve-aliases" onRemove={handleClearResolveAliasesFilter}>
            {resolveAliasesFilter}
          </SFMFilterPill>
        )
      }
      <ClientPills clients={clients} onRemoveClient={handleRemoveClient}/>
      {
        jobs.map(job =>
          <SFMFilterPill key={`job-${job.id}`} onRemove={handleRemoveJob(job)}>
            {`${job.school.code}-${job.name}`}
          </SFMFilterPill>
        )
      }
      {
        workOrders.map(workOrder => (
          <SFMFilterPill key={`work-order-${workOrder.id}`} onRemove={handleRemoveWorkOrder(workOrder)}>
            {workOrder.vNumber}
          </SFMFilterPill>
        ))
      }
      {
        purchaseTypes.map(purchaseType =>
          <SFMFilterPill key={`purchase-type-${purchaseType.id}`}
                         onRemove={() => handleRemovePurchaseType(purchaseType)}>
            {`${purchaseType.category}`}
          </SFMFilterPill>)
      }
      {
        banks.map(bank =>
          <SFMFilterPill key={`bank-${bank.id}`} onRemove={() => handleRemoveBankFilter(bank)}>
            {bank.name}
          </SFMFilterPill>
        )
      }
      <TechnicianPills technicians={technicians} onRemoveTechnician={handleRemoveTech}/>
      {
        returnFilter &&
        <SFMFilterPill key="return" onRemove={handleClearReturnFilter}>
          {returnFilter}
        </SFMFilterPill>
      }
    </FilterPills>
  );
}
