import React, {useContext} from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import SessionContext from '../../context/session';
import WorkOrderContext from '../../context/WorkOrderContext';

import ClientFormGroup from './ClientFormGroup';
import CompletionDateFormGroup from './CompletionDateFormGroup';
import NameFormGroup from './NameFormGroup';
import RequestFormGroup from './RequestFormGroup';
import WorkOrderStatusFormGroup from './WorkOrderStatusFormGroup';

export default function CoreFields({readOnly}) {
  const {isTeacher} = useContext(SessionContext);
  const {existing, name, school, requestDate, request, status, setSchool, setRequestDate, setName, setRequest}
    = useContext(WorkOrderContext);

  return (
    <Form.Group as={Col} controlId="core-fields">
      <Row>
        <ClientFormGroup sm={9} school={school} onSchoolChange={setSchool} disabled={existing}/>
        <CompletionDateFormGroup sm={3} requestDate={requestDate} onRequestDateChange={setRequestDate}
                                 readOnly={readOnly}/>
      </Row>
      {
        isTeacher && existing &&(
          <Row>
            <WorkOrderStatusFormGroup lg={3} status={status} disabled/>
          </Row>
        )
      }
      {
        !isTeacher && (
          <Row>
            <NameFormGroup sm={12} name={name} onNameChange={setName}/>
          </Row>
        )
      }
      <Row>
        <RequestFormGroup request={request} onRequestChange={setRequest} readOnly={readOnly}/>
      </Row>
    </Form.Group>
  );
}