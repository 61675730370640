import React from 'react';

import useJobList from '../hooks/useJobList';
import ACMSelect from './ACMSelect';

export default function JobList({onSelectJob}) {
  const {jobs, jobsLoading} = useJobList();

  return (
    <ACMSelect selected={null} values={jobs} placeholder="Select Job" isLoading={jobsLoading} onSelect={onSelectJob}
               renderLabel={({name, school}) => `${school.code} - ${name}`} isClearable/>
  );
}
