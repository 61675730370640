import React from 'react';
import Button from 'react-bootstrap/Button';
import {faSort, faSortDown, faSortUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const SORT_DIRECTION_ICON = new Map([
  ['unsorted', faSort],
  ['ASC', faSortUp],
  ['DESC', faSortDown]
]);

export default function SortableTableHeader({children, sortOrder, sortDirection, onSort, style, ...props}) {
  const sortIcon = SORT_DIRECTION_ICON.get(sortDirection) || faSort;
  const shouldSort = sortDirection || sortOrder || onSort;
  return (
    //REDTAG:TJH - style is temporary until bootstrap resolves bg-<mode> issues (v6?)
    <th style={style} {...props}>
      {children}
      {sortOrder && (
        <sup style={{fontWeight: 'normal'}}>
          {sortOrder}
        </sup>
      )}
      {shouldSort && (
        <Button className="bg-secondary border-secondary px-1" variant="light" size="sm" onClick={onSort}>
          <FontAwesomeIcon icon={sortIcon}/>
        </Button>
      )}
    </th>
  );
}
