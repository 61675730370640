import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import PurchaseTypePicker from '../PurchaseTypePicker';

export default function PurchaseTypeFormGroup({purchaseType, disabled, onPurchaseTypeChange, ...props}) {
  return (
    <Form.Group controlId="purchaseType" as={Col} {...props}>
      <Form.Label>Type</Form.Label>
      <PurchaseTypePicker constrainToCustomer type={purchaseType} disabled={disabled} onTypeSelect={onPurchaseTypeChange}/>
      <Form.Control.Feedback type="invalid">Purchase Type is required</Form.Control.Feedback>
    </Form.Group>
  );
}
