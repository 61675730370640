import React from 'react';
import moment from 'moment';
import Form from 'react-bootstrap/Form';

const DATE_FORMAT = 'YYYY-MM-DD';
export default function ACMSingleDatePicker(
  {date, required = false, onDateChange, minDate, readOnly = false, formControlFeedback, disabled, ...props}
) {

  function handleOnChange({target}) {
    const {value} = target;
    const newDate = moment(value, DATE_FORMAT);
    if (newDate.isValid()) {
      onDateChange(newDate);
    }
  }

  const isInvalid = required && !date
                    ? 'is-invalid'
                    : 'is-valid';
  const displayedDate = date?.format(DATE_FORMAT);
  return (
    <div className={`sfm-date ${isInvalid}`}>
      <Form.Control className="sfm-input" type="date" value={displayedDate} min={minDate?.format(DATE_FORMAT)}
                    onChange={handleOnChange} disabled={readOnly} readOnly={readOnly} {...props}/>
      {formControlFeedback}
    </div>
  );
}
