import {gql, useMutation} from '@apollo/client';
import queryString from 'query-string';
import {useLocation} from 'react-router-dom';

const RESET_PASSWORD = gql`
    mutation resetPassword($token:String!, $code:String!, $password:String!){
        resetPassword(token:$token, code:$code, password:$password){
            message
        }
    }
`;

export default function useResetPassword() {
  const location = useLocation();
  const {token, code} = queryString.parse(location.search);
  const [gqlResetPassword, {data, loading, error}] = useMutation(RESET_PASSWORD);

  if (error) {
    throw error;
  }

  function resetPassword(password) {
    return gqlResetPassword({variables: {token, code, password}});
  }

  const resetPasswordSuccess = !!data;
  return [resetPassword, {resetPasswordSuccess, resetPasswordLoading: loading}];
}
