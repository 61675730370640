import React, {useEffect} from 'react';

import useTaskLabels from '../hooks/useTaskLabels';
import useSchoolTasks from '../hooks/useSchoolTasks';
import useFilteredTasks from '../hooks/useFilteredTasks';

import ACMSelect from './ACMSelect';

export default function TaskLookup({client, task, disabled, onTaskSelect, taskSetType, maxClosedAge, ...props}) {
  const {setTaskClient, tasks, tasksLoading} = useSchoolTasks(maxClosedAge);
  const taskLabels = useTaskLabels(tasks);
  const filteredTasks = useFilteredTasks(tasks, taskSetType);

  function renderTaskLabel({id}) {
    return taskLabels.get(id)
  }

  useEffect(() => {
    setTaskClient(client);
  }, [client, setTaskClient]);

  useEffect(() => {
    if (tasksLoading || !filteredTasks || !task) {
      return;
    }
    if (filteredTasks.map(({id}) => id)
                     .includes(task.id)) {
      onTaskSelect(task);
    } else {
      onTaskSelect(null);
    }
  }, [task, tasksLoading, filteredTasks, onTaskSelect]);

  if(tasksLoading){
    return null;
  }
  return (
    <ACMSelect selected={task} values={filteredTasks} placeholder="Select Task" isLoading={tasksLoading}
               disabled={disabled} renderLabel={renderTaskLabel} onSelect={onTaskSelect} {...props}/>
  );
}
