import React, {useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';

import useTimer from '../hooks/useTimer';
import useWorkOrders from '../hooks/useWorkOrders';

import EditWorkOrder from '../components/EditWorkOrder';
import QuerySpinner from '../components/QuerySpinner';

export default function WorkOrder() {
  const {workOrderID} = useParams();
  const navigate = useNavigate();
  const {workOrders, workOrderLoading} = useWorkOrders([Number.parseInt(workOrderID)]);
  const [alarm, clearAlarmTimeout] = useTimer(workOrderLoading);

  useEffect(() => clearAlarmTimeout, [clearAlarmTimeout]);
  if (alarm) {
    return <QuerySpinner/>;
  }

  function handleFinished() {
    navigate('/app/work-orders');
  }

  const [workOrder] = workOrders || [];
  return (
    <EditWorkOrder workOrder={workOrder} onFinished={handleFinished}/>
  );
}