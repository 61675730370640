import React from 'react';

import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function EditModalFooter({loading, progressMsg, actionLabel, onClose}) {
  return (
    <Modal.Footer>
      {loading && (
        <Container className="d-flex justify-content-center">
          {progressMsg}
          <Spinner className="ms-3" variant="primary" animation="border" role="status"/>
        </Container>
      )}
      <Button variant="primary" type="submit" disabled={loading}>{actionLabel}</Button>
      <Button variant="outline-primary" disabled={loading} onClick={onClose}>Cancel</Button>
    </Modal.Footer>
  );
}
