import {useCallback} from 'react';
import {gql, useMutation} from '@apollo/client';

import purchaseStatsFragment from '../graphql/purchase-stats-fragment';
import vendorFragment from '../graphql/vendor-fragment';

const CONVERT_VENDOR_TO_ALIAS_MUTATION = gql`
    mutation convertVendorToAlias($id:Int!, $assignToID:Int!){
        convertVendorToAlias(id:$id, assignToID:$assignToID){
            ...VendorFields
            purchaseStats{
                ...PurchaseStatsFields
            }
        }
    }
    ${purchaseStatsFragment}
    ${vendorFragment}
`;

const CONVERT_ALIAS_TO_VENDOR_MUTATION = gql`
    mutation convertAliasToVendor($id:Int!){
        convertAliasToVendor(id:$id){
            ...VendorFields
            purchaseStats{
                ...PurchaseStatsFields
            }
        }
    }

    ${purchaseStatsFragment}
    ${vendorFragment}
`;

export default function useConvertVendor() {
  const [gqlConvertVendorToAlias, {data: aliasData, error: aliasError, loading: aliasLoading}]
    = useMutation(CONVERT_VENDOR_TO_ALIAS_MUTATION);
  const [gqlConvertAliasToVendor, {data: vendorData, error: vendorError, loading: vendorLoading}]
    = useMutation(CONVERT_ALIAS_TO_VENDOR_MUTATION);

  const convertVendorToAlias = useCallback((vendor, assignToVendor) => {
    const variables = {id: vendor.id, assignToID: assignToVendor.id};
    return gqlConvertVendorToAlias({variables});
  }, [gqlConvertVendorToAlias]);

  const convertAliasToVendor = useCallback(vendor => {
    const variables = {id: vendor.id};
    return gqlConvertAliasToVendor({variables});
  }, [gqlConvertAliasToVendor]);

  if (aliasError || vendorError) {
    throw aliasError || vendorError;
  }

  const {convertVendorToAlias: vendorToAliasResults = []} = aliasData || {};
  const {convertAliasToVendor: aliasToVendorResults = []} = vendorData || {};

  return {
    convertVendorToAlias,
    convertAliasToVendor,
    conversionLoading: aliasLoading || vendorLoading,
    conversionData: [...vendorToAliasResults, ...aliasToVendorResults]
  };
}
