import React, {useState} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

import {formatDateTime} from '../../lib/timeUtils';

export default function TaskTimeSheets({id, timeSheets}) {
  return (
    <Row className="m-3">
      <NoTimeSheetsMsg timeSheets={timeSheets}/>
      <TimeSheets id={id} timeSheets={timeSheets}/>
    </Row>
  );
}

function NoTimeSheetsMsg({timeSheets}) {
  if (timeSheets?.length) {
    return null;
  }
  return (<>No Time Sheets</>);
}

function TimeSheets({id, timeSheets}) {
  const [showTimeSheets, setShowTimeSheets] = useState(false);

  if (!timeSheets?.length) {
    return null;
  }

  return (
    <>
      <Col sm={12}>
        <Form.Check className="my-2" id={id} type="switch" label="Show Time Sheets"
                    checked={showTimeSheets} onChange={() => setShowTimeSheets(!showTimeSheets)}/>
      </Col>
      <Col sm={12}>
        <Collapse in={showTimeSheets}>
          <div>
            <Table size="sm" bordered responsive>
              <thead className="bg-secondary">
              <tr>
                <th>Tech</th>
                <th>In</th>
                <th>Out</th>
                <th>Total</th>
                <th>Job</th>
                <th>Notes</th>
              </tr>
              </thead>
              <tbody>
              {
                timeSheets?.map(({id, technician, clockIn, clockOut, totalTime, job, notes}) => {
                  const {firstName, lastName} = technician;
                  return (
                    <tr key={`timesheet-${id}`}>
                      <td>{firstName} {lastName}</td>
                      <td>{formatDateTime(clockIn)}</td>
                      <td>{formatDateTime(clockOut)}</td>
                      <td>{totalTime.substring(0, totalTime.lastIndexOf(':'))}</td>
                      <td>{job?.name}</td>
                      <td>{notes}</td>
                    </tr>
                  );
                })
              }
              </tbody>
            </Table>
          </div>
        </Collapse>
      </Col>
    </>
  );
}