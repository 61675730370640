import {useContext} from 'react';
import {gql, useQuery} from '@apollo/client';

import SessionContext from '../context/session';

const GET_WORK_ORDER_COMMENTS = gql`
  query workOrderComments($workOrderID:Int!){
      workOrderComments(workOrderID: $workOrderID){
          id
          comment
          lastUpdated
          user{
              id
              lastName
              firstName
          }
      }
  }
  `;

export default function useWorkOrderComments(workOrderID){
  const {user} = useContext(SessionContext);
  const skip = !workOrderID || !user?.canQueryComments();
  const {data, loading, error, refetch} = useQuery(GET_WORK_ORDER_COMMENTS,
    {variables:{workOrderID}, fetchPolicy:'cache-and-network', skip});

  if(error && !data?.length){
    throw error;
  }

  const {workOrderComments} = data || {};

  return {workOrderComments, workOrderCommentsLoading:loading, refetchWorkOrderComments:refetch};
}