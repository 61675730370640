import React from 'react';
import Form from 'react-bootstrap/Form';

import FormGroup from '../FormGroup';

export default function NameFormGroup({sm, name, onNameChange}) {
  return (
    <FormGroup controlId="name" label="Work Order Name" sm={sm}>
      <Form.Control type="text" maxLength={45} value={name || ''}
                    onChange={({target}) => onNameChange(target.value)}/>
    </FormGroup>
  );
}
