import React, {useCallback, useContext, useEffect, useState} from 'react';

import SessionContext from '../../context/session';

import useAddPurchase from '../../hooks/useAddPurchase';
import usePurchaseImages from '../../hooks/usePurchaseImages';
import usePurchaseState from '../../hooks/usePurchaseState';
import useUpdatePurchase from '../../hooks/useUpdatePurchase';

import EditPurchaseModal from './EditPurchaseModal';

export default function EditPurchaseDialog({show, purchase, onClose}) {
  const purchaseState = usePurchaseState(purchase || {});
  const {vendor, setVendor, isValid, existing, job} = purchaseState;
  const {purchaseImages, purchaseImagesError, purchaseImagesLoading} =
    usePurchaseImages(purchase && purchase.purchaseImages);
  const [validated, setValidated] = useState(false);
  const [proposedPIs, setProposedPIs] = useState([]);
  const [deletedPIs, setDeletedPIs] = useState([]);
  const [vendorNotListed, setVendorNotListed] = useState(existing ? !vendor : false);
  const [vendorInNotes, setVendorInNotes] = useState(vendorNotListed);
  const [vendorLegible, setVendorLegible] = useState(vendorNotListed);
  const {user} = useContext(SessionContext);

  // noinspection JSUnusedGlobalSymbols
  const options = {onCompleted: () => onClose(true)};
  const [addPurchase, {addPurchaseLoading, addPurchaseError}] = useAddPurchase(options);
  const [updatePurchase, {updatePurchaseLoading, updatePurchaseError}] = useUpdatePurchase(options);

  useEffect(() => {
    if (!purchaseImages) {
      return;
    }
    setProposedPIs(pIs => [...purchaseImages, ...pIs]);
  }, [purchaseImages]);

  useEffect(() => {
    if (vendorNotListed) {
      setVendor(null);
    } else {
      setVendorInNotes(false);
      setVendorLegible(false);
    }
  }, [vendorNotListed, setVendor, setVendorLegible, setVendorInNotes]);

  const error = addPurchaseError || updatePurchaseError || purchaseImagesError;

  if (error) {
    console.error(error);
    throw error;
  }

  const handleOnImageChange = useCallback(newImages => {
    setProposedPIs(pis => [...pis, ...newImages]);
  }, [setProposedPIs]);

  const handleDeleteImage = useCallback(image => {
    setProposedPIs(pis => pis.filter(pi => pi !== image));
    if (image.id) {
      setDeletedPIs(i => [...i, image]);
    }
  }, [setProposedPIs, setDeletedPIs]);


  function handleVendorMetaChange({vendorNotListed, vendorInNotes, vendorLegible}) {
    if ('boolean' === typeof vendorNotListed) {
      setVendorNotListed(vendorNotListed);
    }
    if ('boolean' === typeof vendorInNotes) {
      setVendorInNotes(vendorInNotes);
    }
    if ('boolean' === typeof vendorLegible) {
      setVendorLegible(vendorLegible);
    }
  }

  const isFormValid = isValid
    && proposedPIs.length
    && (vendor || (vendorNotListed && vendorInNotes && vendorLegible));

  async function handleSubmit(e) {
    e.preventDefault();
    setValidated(true);
    if (!isFormValid) {
      return;
    }

    const {id} = purchase || {};
    const {isSplit, date, amount, notes, task, job, purchaseType, technician, isReturn} = purchaseState;
    const reportedAmount = isReturn ? -amount : amount;
    const newPurchase = {
      id, isSplit, date, amount: reportedAmount, notes, task, job, vendor, purchaseType, technician,
      purchaseImages: proposedPIs.filter(p => !p.id)
    };
    if (id) {
      // noinspection ES6MissingAwait
      updatePurchase({
        ...newPurchase,
        deletedPurchaseImageIds: deletedPIs.map(dP => dP.id)
      });
    } else {
      // noinspection ES6MissingAwait
      addPurchase(newPurchase);
    }
  }

  const handleOnClose = useCallback(() => {
    setValidated(false);
    onClose();
  }, [setValidated, onClose])

  const vendorMeta = {vendorNotListed, vendorInNotes, vendorLegible};
  const loading = purchaseImagesLoading || addPurchaseLoading || updatePurchaseLoading;
  return (
    <EditPurchaseModal purchase={purchaseState} purchaseImages={proposedPIs} vendorMeta={vendorMeta}
                       validated={validated} loading={loading} show={show}
                       showTechs={user.canUpdatePurchaseTech()}
                       disableTechs={!user.canAssignTechToPurchaseForJob(job)}
                       onPurchaseImagesChanged={handleOnImageChange} onPurchaseImageDeleted={handleDeleteImage}
                       onVendorMetaChange={handleVendorMetaChange} onClose={handleOnClose} onSubmit={handleSubmit}/>
  );
}
