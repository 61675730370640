import React, {useContext} from 'react';

import QueryContext from '../../context/QueryContext';

import useTechnicians from '../../hooks/useTechnicians';

import FilterPills from '../../components/FilterPills';
import TechnicianPills from '../../components/TechnicianPills';
import DateRangePill from '../../components/DateRangePill';

export default function LocationsFilterPills() {
  const {query, handleUpdateQuery, handleClearQuery} = useContext(QueryContext);

  const {technicianIDs = [], startDate, endDate} = query;

  const {technicians} = useTechnicians(technicianIDs);

  function handleRemoveTechnician(technician) {
    handleUpdateQuery({...query, technicianIDs: technicianIDs.filter(id => id !== technician.id), page:1});
  }

  function handleClearDateFilter() {
    handleUpdateQuery({...query, startDate: null, endDate: null, page:1});
  }

  return (
    <FilterPills clearDisabled={false} onClearQuery={handleClearQuery}>
      <TechnicianPills technicians={technicians} onRemoveTechnician={handleRemoveTechnician}/>
      <DateRangePill startDate={startDate} endDate={endDate} onClearDateFilter={handleClearDateFilter}/>
    </FilterPills>
  );
}