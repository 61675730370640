import {gql, useLazyQuery} from '@apollo/client';

const LOOKUP_USERNAME_QUERY = gql`
    query lookupUsername($email:String!){
        lookupUsername(email:$email)
    }
`;

export default function useForgotUsername() {
  const [gqlLookupUsername, {data, loading, error}] = useLazyQuery(LOOKUP_USERNAME_QUERY,
    {fetchOptions: 'network-only'});

  if (error) {
    throw error;
  }

  function lookupUsername(email) {
    gqlLookupUsername({variables: {email}});
  }

  const forgotUsernameSuccess = data && data.lookupUsername;
  return [lookupUsername, {forgotUsernameSuccess, forgotUsernameLoading: loading}];
}
