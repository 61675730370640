import {useCallback} from 'react';
import {gql, useApolloClient} from '@apollo/client';

const PAGE_SIZE = 30;
const TECHNICIAN_SEARCH_QUERY = gql`
    query techniciansConnection($name:String, $pageSize:Int, $showInactive:Boolean){
        techniciansConnection(name:$name, pageSize:$pageSize, showInactive:$showInactive) 
        @connection(key:"technicians", filter:["name", "showInactive"]){
            next
            hasMore
            total
            technicians{
                id
                lastName
                firstName
                status
            }
        }
    }
`;

export default function useTechnicianSearch(showInactive = false) {
  const client = useApolloClient();

  return useCallback(async name => {
    if (name) {
      const {data, error} = await client.query({
        query: TECHNICIAN_SEARCH_QUERY,
        fetchPolicy: 'network-only',
        variables: {name, pageSize: PAGE_SIZE, showInactive}
      });

      if (error && !(data && data.length)) {
        throw error;
      }

      return data.techniciansConnection.technicians;
    }
  }, [client, showInactive]);
}
