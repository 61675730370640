import React, {useEffect, useState} from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from 'moment';

function mapToDateArray(startDate, endDate) {
  return [startDate, endDate]
    .map(d => d?.toDate());
}

function dateToMoment(d) {
  return d && moment(d);
}

export default function ACMDateRangePicker({startDate, endDate, onDatesChange, ...props}) {
  const [dates, setDates] = useState(mapToDateArray(startDate, endDate));

  function handleChange(newDates) {
    newDates ||= [];
    setDates(newDates);
    const [startDate, endDate] = newDates.map(d => dateToMoment(d))
    if ((startDate && endDate)
      || (!startDate && !endDate)) {
      onDatesChange({startDate, endDate});
    }
  }

  useEffect(() => {
    setDates(mapToDateArray(startDate, endDate));
  }, [startDate, endDate]);

  return (
    <DateRangePicker className="sfm-input" calendarType="gregory" value={dates} format="MM/dd/y" rangeDivider="&rarr;"
                     onChange={handleChange} {...props}/>
  );
}
