import React from 'react';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function EditFooter({loading, progressMsg, actionLabel, onCancel}) {
  return (
    <Row className="align-items-center justify-content-end p-3">
      {loading && (
        <Container className="d-flex justify-content-center">
          {progressMsg}
          <Spinner className="ms-3" variant="primary" animation="border" role="status"/>
        </Container>
      )}


      <Col className="ms-auto" sm={2}>
        <Button className="m-1" variant="primary" type="submit" disabled={loading}>{actionLabel}</Button>
        <Button className="m-1" variant="outline-primary" disabled={loading} onClick={onCancel}>Cancel</Button>
      </Col>
    </Row>
  );
}
