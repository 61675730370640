import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';

/**
 * @return {null}
 */
export default function ErrorModal({message}) {
  if (!message) {
    return null;
  }

  const displayed = message.split('\n')
    .map((line, index) => line && (<p key={index}>{line}</p>));
  return (
    <Modal size="lg" centered scrollable show>
      <Modal.Header><h3>Error</h3></Modal.Header>
      <Modal.Body>
        <Alert variant="danger">{displayed}</Alert>
      </Modal.Body>
    </Modal>
  );
}
