import React, {useState} from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import useUsersSearch from '../../hooks/useUserSearch';
import LookupByName from '../../components/LookupByName';

export default function UserFormGroup({user, label = 'User', disabled, required, onSelectUser, ...props}) {
  const [showInactive, setShowInactive] = useState(false);
  const searchUsers = useUsersSearch(showInactive);

  return (
    <Form.Group controlId="user" as={Col} {...props}>
      <Form.Label>{label}</Form.Label>

      <LookupByName placeholder="Select User" selected={user} disabled={disabled} fetch={searchUsers} required={required} onSelect={onSelectUser}
                    showInactive={showInactive}/>
      <Form.Check type="switch" id="user-inactive" label="Show Inactive" checked={showInactive}
                  onChange={e => setShowInactive(e.target.checked)}/>
    </Form.Group>
  );
}