import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import {formatDateTime} from '../../lib/timeUtils';

//YELLOWTAG:TJH - naming is hard
export default function CommentList({comments}) {
  if(!comments?.length){
    return 'No Comments Entered';
  }
  return comments.map(({id, comment, user, lastUpdated}) => (
    <Card key={`comment-${id}`} className="m-3 page-break">
      <Card.Body>
        <pre className="border-0">{comment}</pre>
      </Card.Body>
      <Card.Footer className="text-muted">
        <Row>
          <Col sm={12} className="text-right">
            <address rel="author">{user.firstName} {user.lastName}</address>
            <time dateTime={lastUpdated}>{formatDateTime(lastUpdated)}</time>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  ));

}