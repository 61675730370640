import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import EmailInputGroup from './EmailInputGroup';

export default function EmailAddressForm({primaryActionTitle, disabled, onSubmit}) {
  const [email, setEmail] = useState('');

  function handleSubmit(event) {
    event.preventDefault();
    onSubmit(email);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <EmailInputGroup controlId="emailInput" email={email} disabled={disabled} onEmailChange={setEmail}/>
      <div className="mt-3 d-grid">
        <Button type="submit" disabled={disabled}>{primaryActionTitle}</Button>
      </div>
    </Form>
  );
}
