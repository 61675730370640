import {gql, useMutation} from '@apollo/client';

import PurchaseList from '../graphql/purchase-list';

const SPLIT_PURCHASE_MUTATION = gql`
    mutation splitPurchase(
        $purchaseID:Int!
        $splitDetails:[SplitDetails!]! ){

        splitPurchase(purchaseID:$purchaseID splitDetails:$splitDetails){
            ...PurchaseListPurchase
        }
    }
    ${PurchaseList.fragments.purchase}
`;

export default function useSplitPurchase() {

  const [gqlSplitPurchase, {data, loading, error}] = useMutation(SPLIT_PURCHASE_MUTATION);

  if (error && !(data && data.length)) {
    throw error;
  }

  function doSplitPurchase(purchase, inSplitPurchases) {
    const splitDetails = inSplitPurchases.map(({amount, job, task}) =>
      ({amount, jobID: job.id, taskID: task && task.id}));
    return gqlSplitPurchase({variables: {purchaseID: purchase.id, splitDetails}});
  }

  const {splitPurchase} = data || {};
  return [doSplitPurchase, {purchases: splitPurchase, purchasesLoading: loading}];
}
