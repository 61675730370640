import React from 'react';
import Form from 'react-bootstrap/Form';

import ACMSingleDatePicker from '../ACMSingleDatePicker';
import FormGroup from '../FormGroup';

export default function CompletionDateFormGroup({sm, requestDate, readOnly = false, onRequestDateChange}) {
  return (
    <FormGroup controlId="date" label="Desired Completion Date" sm={sm}>
      <ACMSingleDatePicker date={requestDate} onDateChange={onRequestDateChange} readOnly={readOnly} required/>
      <Form.Control.Feedback type="invalid">Date is required</Form.Control.Feedback>
    </FormGroup>
  );
}
