import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import {
  faChevronLeft,
  faChevronRight,
  faRedo,
  faSearchMinus,
  faSearchPlus,
  faUndo
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function HeaderButton({icon, disabled, onClick}) {
  return (
    <Button variant="outline-dark" {...{disabled, onClick}}>
      <FontAwesomeIcon className="text-light" size="lg" icon={icon}/>
    </Button>
  );
}

export default function Header(
  {
    pageCount,
    onPreviousPage,
    onNextPage,
    prevDisabled,
    nextDisabled,
    children,
    onZoomIn,
    onZoomOut,
    onRotateLeft,
    onRotateRight,
    zoomOutDisabled
  }
) {
  return (
    <Modal.Header className="bg-dark text-light" closeButton>
      <Row className="justify-content-end align-items-center w-100">
        {pageCount && (
          <Col sm={4}>
            <HeaderButton icon={faChevronLeft} disabled={prevDisabled} onClick={onPreviousPage}/>
            {children}
            <HeaderButton icon={faChevronRight} disabled={nextDisabled} onClick={onNextPage}/>
          </Col>
        )}
        <Col sm={4}>
          <HeaderButton icon={faSearchPlus} onClick={onZoomIn}/>
          <HeaderButton icon={faSearchMinus} onClick={onZoomOut} disabled={zoomOutDisabled}/>
          <HeaderButton icon={faUndo} onClick={onRotateLeft}/>
          <HeaderButton icon={faRedo} onClick={onRotateRight}/>
        </Col>
      </Row>
    </Modal.Header>
  );
}
