import React from 'react';

import WorkOrderStatusPicker from '../WorkOrderStatusPicker';
import FormGroup from '../FormGroup';

export default function WorkOrderStatusFormGroup({status, disabled, onStatusChange, ...props}) {
  return (
    <FormGroup controlId="workOrderStatus" label="Status" {...props}>
      <WorkOrderStatusPicker status={status} disabled={disabled} onStatusSelect={onStatusChange}/>
    </FormGroup>
  );
}