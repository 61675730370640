import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export default function CenteredLabel({label}) {
  return (
    <Row className="h-100">
      <Col className="d-flex justify-content-center align-items-center">{label}</Col>
    </Row>
  );
}