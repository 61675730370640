import {useCallback} from 'react';
import {gql, useMutation} from '@apollo/client';

const ADD_WORK_ORDER_COMMENT = gql`
    mutation addWorkOrderComment($workOrderID:Int!, $comment:String!){
        addWorkOrderComment(workOrderID: $workOrderID, comment: $comment){
            id
        }
    }
`;

export default function useAddWorkOrderComment(workOrderID, options) {
  const [gqlAddWorkOrderComment, {loading, error}] = useMutation(ADD_WORK_ORDER_COMMENT, options);

  const addWorkOrderComment = useCallback(comment => {
    return gqlAddWorkOrderComment({variables: {workOrderID, comment}});
  }, [workOrderID, gqlAddWorkOrderComment]);

  return [addWorkOrderComment, {addWorkOrderCommentLoading: loading, addWorkOrderCommentError: error}];
}