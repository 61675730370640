import React, {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Iframe from 'react-iframe';

import DBNavigationContext from '../context/DBNavigationContext';
import SessionContext from '../context/session';

export default function DB() {
  const {onEndSession} = useContext(SessionContext);
  const {onNavigation} = useContext(DBNavigationContext);
  const {pathname, search} = useLocation();

  const basePath = pathname.replace(/^\/app\/db\/?(.*)$/, '$1') || 'index.php';
  const [initialUrl] = useState(`${window.SFM_DB_HOST}/${basePath}${search}`);

  useEffect(() => {
    function receiveMessage(messageEvent) {
      const {data} = messageEvent;
      if ('string' === typeof data) {
        onNavigation(messageEvent);
      } else if (data === false) {
        onEndSession();
      }
    }

    window.addEventListener('message', receiveMessage);

    return () => window.removeEventListener('message', receiveMessage);
  }, [onEndSession, onNavigation]);

  return (
    <div className="overflow-hidden h-100">
      <Iframe id="db-iframe" url={initialUrl} height="100%" width="100%"/>
    </div>
  );
}
