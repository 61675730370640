import React, {useContext, useMemo} from 'react';
import {Views} from 'react-big-calendar';

import TimeSheetsContext from '../context/TimeSheetsContext';

import useTimeSheetTotals from '../hooks/useTimeSheetTotals';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useCalendarViewSizeObserver from '../hooks/useCalendarViewSizeObserver';

function TotalsColumn({className, style, children}) {
  return (
    <Col className={`border p-1 ${className}`} style={style}>
      {children}
    </Col>
  );
}

function GutterColumn({gutterWidth, children}) {
  return (
    <Col className="border p-1 text-end fw-bold"
         style={{width: gutterWidth, maxWidth: gutterWidth, minWidth: gutterWidth}}>
      {children}
    </Col>
  );
}

export default function CalendarFooter({timeSheets, calendarContainerRef}) {
  const {calendarView} = useContext(TimeSheetsContext);
  const {dailyTotals, regularPayTotal, overtimeTotal} = useTimeSheetTotals(timeSheets);
  const {gutterWidth, eventContainerWidth} = useCalendarViewSizeObserver(calendarContainerRef);

  const dailyTotalsColumns = useMemo(() => {
    let result = null;
    if (!dailyTotals) {
      return result;
    }
    if (calendarView === Views.WEEK) {
      let style = {width: eventContainerWidth, maxWidth: eventContainerWidth, minWidth: eventContainerWidth};
      result = [...Array(7).keys()]
        .map(key => {
          const dailyTotal = dailyTotals.get(key);
          return (
            <TotalsColumn key={`dailyTotal-${key}`} className="pe-2 text-end" style={style}>
              {dailyTotal?.as('hours') ?? 0}
            </TotalsColumn>
          );
        });
    } else if (calendarView === Views.DAY) {
      result = (
        <TotalsColumn className="ps-2">{dailyTotals.as('hours')}</TotalsColumn>
      );
    }

    return result;
  }, [dailyTotals, calendarView, eventContainerWidth]);

  if (Views.AGENDA === calendarView) {
    return null;
  }
  let rows = [];
  if (calendarView === Views.WEEK) {
    rows.push(
      <Row key="dailyRow">
        <GutterColumn gutterWidth={gutterWidth}>
          Daily
        </GutterColumn>
        {dailyTotalsColumns}
      </Row>
    );
  }
  rows.push(
    <Row key="regularTime">
      <GutterColumn gutterWidth={gutterWidth}>
        Regular
      </GutterColumn>
      <Col sm={11} className="border">
        {regularPayTotal?.as('hours')}
      </Col>
    </Row>
  );
  rows.push(
    <Row key="overtime">
      <GutterColumn gutterWidth={gutterWidth}>
        OT
      </GutterColumn>
      <Col sm={11} className="border">
        {overtimeTotal?.as('hours')}
      </Col>
    </Row>
  );
  return (
    <Container className="my-3" fluid>
      {rows}
    </Container>
  );
}