import React from 'react';

import SFMSelectBase from './SFMSelectBase';

import {HIGH_PRIORITY, MEDIUM_PRIORITY, LOW_PRIORITY, PRIORITY_VALUE_NAME_MAP} from '../lib/priorities';

const PRIORITY_OPTIONS = Object.freeze(
  [HIGH_PRIORITY, MEDIUM_PRIORITY, LOW_PRIORITY]
    .map(value => ({value, label: PRIORITY_VALUE_NAME_MAP.get(value)}))
);

export default function PriorityPicker({priority, onSelectPriority}) {
  return (
    <SFMSelectBase value={PRIORITY_OPTIONS.find(({value}) => value === priority)} options={PRIORITY_OPTIONS}
                   placeholder="Priority" isSearchable={false} onChange={({value}) => onSelectPriority(value)}/>
  );
}