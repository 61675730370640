import React from 'react';
import {toast} from 'react-toastify';

import usePurchasesByVendor from '../../hooks/usePurchasesByVendor';
import useUpdatePurchase from '../../hooks/useUpdatePurchase';
import useDeletePurchase from '../../hooks/useDeletePurchase';

import ConnectionPagination from '../../components/ConnectionPagination';
import PurchaseTable, {purchaseOrderBy} from '../../components/PurchaseTable';
import SchoolCode from '../../components/SchoolCode';
import Purchases from './Purchases';

export default function PurchaseList({vendorID = null}) {
  const {
    vendorPurchasesLoading,
    vendorPurchasesConnection,
    refetchVendorPurchases,
    columnSortDirection,
    onSort,
    pageSize,
    setPageSize
  } = usePurchasesByVendor(vendorID, purchaseOrderBy);
  const [updatePurchase] = useUpdatePurchase();
  const [handleDeletePurchase, deletePurchaseDialog] = useDeletePurchase(refetchVendorPurchases);

  async function handleUpdatePurchase({purchaseImages, ...purchase}, vendor) {
    const {data} = await updatePurchase({...purchase, vendor});
    // noinspection ES6MissingAwait
    refetchVendorPurchases();
    const {school, task: updatedTask, job: updatedJob = {}, vendor: updatedVendor} = data.updatePurchase;
    const taskOrJob = updatedTask
      ? updatedTask.description
      : updatedJob.name;
    toast((
      <div>
      <span>
        <SchoolCode code={school.code}/>
        {taskOrJob}
      </span>
        {' '}
        <span>updated to {updatedVendor.name}</span>
      </div>
    ));
  }

  const {purchases} = vendorPurchasesConnection || {};
  const showVendor = !vendorID;

  return (
    <>
      <PurchaseTable sortDirection={columnSortDirection} onSort={onSort} loading={vendorPurchasesLoading}
                     showVendor={showVendor} showTech>
        <Purchases onUpdatePurchase={handleUpdatePurchase} onDelete={handleDeletePurchase}
                   {...{purchases, showVendor}}/>
        {deletePurchaseDialog}
      </PurchaseTable>
      {vendorPurchasesConnection && <ConnectionPagination connection={vendorPurchasesConnection} pageSize={pageSize}
      onChangePageSize={setPageSize}/>}
    </>
  );
}
