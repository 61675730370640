import {useMemo} from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import moment from 'moment';

const GET_EARLIEST_PURCHASE_DATE = gql`
    query earliestPurchaseDate{
        earliestPurchaseDate{
            id
            name
            date
        }
    }
`;

const SET_EARLIEST_PURCHASE_DATE = gql`
    mutation setEarliestPurchaseDate($date:String!){
        setEarliestPurchaseDate(date:$date){
            id
            name
            date
        }
    }
`;

const DATE_FORMAT = 'YYYY-MM-DD';
export default function useEarliestPurchaseDate() {
  const {data: getData, loading: getLoading, error: getError} = useQuery(GET_EARLIEST_PURCHASE_DATE,
    {fetchPolicy: 'cache-and-network'});

  const [gqlSetEarliestPurchaseDate, {loading: setLoading, error: setError}]
    = useMutation(SET_EARLIEST_PURCHASE_DATE);

  const earliestPurchaseDate = useMemo(() => {
    let {earliestPurchaseDate: result} = getData || {};
    if (result) {
      result = moment(result.date, DATE_FORMAT);
    }
    return result;
  }, [getData]);

  function setEarliestPurchaseDate(date) {
    return gqlSetEarliestPurchaseDate({variables: {date: date.format(DATE_FORMAT)}});
  }

  const error = getError || setError;
  if (error) {
    throw error;
  }

  return {earliestPurchaseDate, setEarliestPurchaseDate, earliestPurchaseDateLoading: getLoading || setLoading};
}
