import React from 'react';
import Table from 'react-bootstrap/Table';

import SortableTableHeader from './SortableTableHeader';

export default function SortableTable({className, style, headers, body, sortDirection, onSort}) {

  const sortOrder = sortDirection
                    ? Array.from(sortDirection.entries())
                           .reduce((reduction, [key], i) => {
                             reduction.set(key, i + 1);
                             return reduction;
                           }, new Map())
                    : null;

  return (
    <Table className={className} style={style} responsive>
      <thead>
      <tr>
        {
          headers.map(({sortable, key, ...props}) => {
            if (sortable) {
              props.sortOrder = sortOrder.get(key);
              props.sortDirection = sortDirection.get(key);
              props.onSort = e => onSort(key, e);
            }
            return (<SortableTableHeader key={key} className="bg-secondary text-nowrap align-baseline" {...props}/>);
          })
        }
      </tr>
      </thead>
      <tbody>
      {body}
      </tbody>
    </Table>
  );
}
