import React, {useEffect} from 'react';

import useSchoolJobs from '../hooks/useSchoolJobs';
import ACMSelect from './ACMSelect';

export default function JobLookup({client, job, disabled, onJobSelect, required, ...props}) {
  const {jobs, setJobClient, jobLoading} = useSchoolJobs();

  useEffect(() => {
    setJobClient(client);
  }, [client, setJobClient]);

  return (
    <ACMSelect selected={job} values={jobs} placeholder="Select Job"
               isLoading={jobLoading} onSelect={onJobSelect} disabled={disabled}
               renderLabel={({name}) => name} required={required} {...props}/>
  );
}
