export default function theme(baseTheme){
  return {
    ...baseTheme,
    borderColor:'#333',
    colors:{
      ...baseTheme.colors,
      primary:'#00a14d',
      primary25:'#e5f5ed',
      primary50:'#e5f5ed',
      primary75:'#e5f5ed',
      neutral20:'#333'
    }
  }
}
