import React from 'react';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import {MY_TASKS, ALL_TASKS, CLOSED_TASKS} from '../hooks/useFilteredTasks';

import CenteredLabel from './CenteredLabel';

export default function AllMineClosedButtonGroup({value, onChange, ...props}){
  return (
    <ToggleButtonGroup size="sm" name="task type" type="radio" value={value} onChange={onChange} {...props}>
      <ToggleButton id={MY_TASKS} variant="outline-primary" value={MY_TASKS}>
        <CenteredLabel label="My Tasks"/>
      </ToggleButton>
      <ToggleButton id={ALL_TASKS} variant="outline-primary" value={ALL_TASKS}>
        <CenteredLabel label="All Tasks"/>
      </ToggleButton>
      <ToggleButton id={CLOSED_TASKS} variant="outline-primary" value={CLOSED_TASKS}>
        <CenteredLabel label="Closed Tasks"/>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}