import React, {useEffect, useState} from 'react';
import moment from 'moment';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import usePurchaseState from '../../hooks/usePurchaseState';

import ClientLookup from '../../components/ClientLookup';
import CurrencyInputGroup from '../../components/CurrencyInputGroup';
import JobLookup from '../../components/JobLookup';
import TaskLookup from '../../components/TaskLookup';

export default function SplitFormRow({row, purchase, amountRemaining, showAddButton, onAddRow, onChange}) {
  const [client, setClient] = useState();
  const {
    job, setJob,
    task, setTask,
    amount, setAmount,
    date, isValid
  } = usePurchaseState(purchase);

  const today = moment()
    .startOf('day');
  let maxClosedAge = moment.duration(today.diff(date))
  maxClosedAge = Math.ceil(maxClosedAge);

  useEffect(() => {
    onChange(row, {job, task, amount, isValid});
  }, [row, job, task, amount, isValid, onChange]);

  function handleOnSelectClient(newClient) {
    setClient(newClient);
    setJob(null);
    setTask(null);
  }

  let feedbackMsg = 'Amount is required';
  let amountIsInvalid;
  const diff = amountRemaining - (amount || 0);
  if (diff < 0 && diff.toFixed(2) !== '-0.00') {
    feedbackMsg = 'Amount is too high';
    amountIsInvalid = true;
  }
  return (
    <Row className="border-top py-3">
      <Col sm={2}>
        <ClientLookup client={client} onClientSelect={handleOnSelectClient} required/>
      </Col>
      <Col sm={3}>
        <JobLookup client={client} job={job} onJobSelect={setJob} required/>
      </Col>
      <Col sm={3}>
        <TaskLookup client={client} task={task} maxClosedAge={maxClosedAge} onTaskSelect={setTask}/>
      </Col>
      <Col sm={2}>
        <CurrencyInputGroup amount={amount} onAmountChange={setAmount} isInvalid={amountIsInvalid}
                            feedback={
                              <Form.Control.Feedback type="invalid">{feedbackMsg}</Form.Control.Feedback>
                            }/>
      </Col>
      <Col sm={2}>
        {
          !!showAddButton &&
          <Button variant="outline-primary" disabled={!isValid}
                  onClick={() => onAddRow(row, {job, task, amount, isValid})}>
            Add Split
          </Button>
        }
      </Col>
    </Row>
  );
}
