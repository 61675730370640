import React, {useContext, useEffect} from 'react';

import SessionContext from '../context/session';
import useSchoolJobs from '../hooks/useSchoolJobs';

import ACMSelect from './ACMSelect';

export default function OwnSchoolJobList({onSelectJob}) {
  const {setJobClient, jobs, jobLoading} = useSchoolJobs();
  const {user} = useContext(SessionContext);

  useEffect(() => {
    if (!user) {
      return;
    }

    setJobClient(user.school);
  }, [user, setJobClient]);

  return (
    <ACMSelect selected={null} values={jobs} placeholder="Select Job" isLoading={jobLoading} onSelect={onSelectJob}
               renderLabel={({name}) => name} isClearable/>
  );
}
