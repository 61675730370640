import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {useDropzone} from 'react-dropzone';

export default function ACMFileChooser(
  {className, fileType, onChosen, displayComponent, multiple = false, disabled, ...containerProps}
) {
  const {getRootProps, getInputProps} = useDropzone({onDrop: onChosen, accept: fileType, multiple, disabled});

  return (
    <Col sm={12} className={`bg-secondary w-100 ${className}`}>
      <Row className="align-items-center justify-content-center flex-grow-1 flex-column py-2"
           style={{height: '50%'}} {...getRootProps(containerProps)}>
        <Col lg={5} md={8} sm={10} xs={10}>
          <Row className="text-center">
            <Col>
              <p className="fw-bold text-muted">{`Drag and drop your file${multiple ? 's' : ''} here`}</p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={7} md={8} sm={8} xs={9} className="mx-auto">
              <input {...getInputProps()}/>
              <div className="d-grid">
                <Button type="button" variant="outline-primary bg-light" disabled={disabled} >
                  Choose file...
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {displayComponent}
    </Col>
  );
}
