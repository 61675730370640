import {gql, useApolloClient} from '@apollo/client';

import {throttle} from 'lodash';
import {useCallback} from 'react';

const PAGE_SIZE = 30;
const VENDOR_LOOKUP_QUERY = gql`
    query searchVendorsByName($name:String, $pageSize:Int){
        vendorsConnection(name:$name, pageSize:$pageSize)
        @connection(key:"vendors", filter:["name"])
        {
            next
            hasMore
            total
            vendors{
                id
                name
                isAlias
            }
        }
    }
`;

export default function useVendorSearch(filter) {
  const client = useApolloClient();

  async function baseSearchVendors(name) {
    const {data, error} = await client.query({
      query: VENDOR_LOOKUP_QUERY,
      fetchPolicy: 'network-only',
      variables: {name: name, pageSize: PAGE_SIZE}
    });

    if (error && (!data || !data.length)) {
      console.error(error);
      throw error;
    }
    return data.vendorsConnection.vendors
      .filter(filter || (() => true));
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    throttle(baseSearchVendors, 60, {leading: true, trailing: true}),
    [baseSearchVendors, filter, client]
  );
}
