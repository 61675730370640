import React from 'react';
import Select from 'react-select';

import theme from '../lib/selectTheme';

function menuStyles(provided, state) {
  return {...provided, zIndex: 1000};
}

export default function SFMSelectBase({value, required, ...props}) {
  let validity = '';
  if (required) {
    validity = value ? 'is-valid' : 'is-invalid';
  }

  return (
    <Select className={`sfm-select sfm-${validity}`} classNamePrefix="sfm-select"
            theme={theme} styles={{menu: menuStyles}} value={value} {...props}/>
  );
}
