import React, {forwardRef, useContext} from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import SessionContext from '../../context/session';
import WorkOrderContext from '../../context/WorkOrderContext';
import StatusHistoryContext from '../../context/StatusHistoryContext';

import Costs from './Costs';
import PrintDescription from './PrintDescription';
import PrintedComments from './PrintedComments';
import PrintPurchaseTable from './PrintPurchaseTable';
import PrintTasksAndTimeTable from './PrintTasksAndTimeTable';
import Requester from './Requester';
import StatusHistoryTable from './StatusHistoryTable';
import Title from './Title';
import PrintCoreDetails from './PrintCoreDetails';

export default forwardRef(function PrintedWorkOrder(props, ref) {
  const {isTeacher, user} = useContext(SessionContext);
  const {existing, abbreviation, number, name, laborCosts, materialCosts, customerMaterialCosts}
    = useContext(WorkOrderContext);
  const {statusHistory} = useContext(StatusHistoryContext);
  return (
    <Container className="my-2" ref={ref}>
      <Row>
        <Col>
          <Title existing={existing} schoolCode={abbreviation} number={number} name={name} forPrint/>
        </Col>
      </Row>
      <Row>
        <Col><Requester cardClassName="h-100" header/></Col>
        <Col><PrintCoreDetails/></Col>
      </Row>
      <Row className="mt-1">
        <Col sm={12}><PrintDescription/></Col>
      </Row>
      <Row className="mt-1">
        <Col sm={12}>
          <StatusHistoryTable statusHistory={statusHistory}/>
        </Col>
      </Row>
      {
        !isTeacher && (
          <>
            <Row className="mt-1">
              <Col sm={12}>
                <Costs laborCosts={laborCosts} materialCosts={materialCosts}
                       customerMaterialCosts={customerMaterialCosts}/>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col sm={12}>
                <PrintTasksAndTimeTable/>
              </Col>
            </Row>
            {
              user.canQueryAllPurchases() && (
                <Row className="mt-1">
                  <Col sm={12}>
                    <PrintPurchaseTable/>
                  </Col>
                </Row>)
            }
            <Row className="mt-1">
              <Col sm={12}>
                <PrintedComments/>
              </Col>
            </Row>
          </>
        )
      }
    </Container>
  );
});
