import React, {useCallback, useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {useNavigate, Navigate} from 'react-router-dom';

import QueryContext from '../../context/QueryContext';
import RouteContext from '../../context/RouteContext';

import useColumnSort from '../../hooks/useColumnSort';
import useWorkOrderQueryParams, {toUrl, BASE_QUERY, isNewQueryMoreInclusive} from '../../hooks/useWorkOrderQueryParams';
import useQueryContext from '../../hooks/useQueryContext';
import useFilteredWorkOrders from '../../hooks/useFilteredWorkOrders';
import useWorkOrderStatuses, {ON_HOLD_ID} from '../../hooks/useWorkOrderStatuses';

import WorkOrderTable, {workOrderOrderBy} from '../../components/WorkOrderTable';
import ConnectionPagination from '../../components/ConnectionPagination';
import Header from './Header';

const ROUTE_CONTEXT_KEY = 'page.WorkOrders';

export default function WorkOrders() {
  const [initialized, setInitialized] = useState(false);
  const {getRouteContext} = useContext(RouteContext);
  const [woQuery, setWOQuery] = useWorkOrderQueryParams();
  const {redirect, query, handleUpdateQuery: internalHandleUpdateQuery}
    = useQueryContext(ROUTE_CONTEXT_KEY, woQuery, setWOQuery, toUrl, BASE_QUERY);
  const {columnSortDirection, onSort} = useColumnSort(new Map([['date', 'DESC']]));
  const navigate = useNavigate();
  const {workOrderStatuses} = useWorkOrderStatuses();
  const routeContext = getRouteContext(ROUTE_CONTEXT_KEY);
  let hasQuery = !!Object
    .entries(query)
    .find(([key, value]) => 'page' !== key && typeof value !== 'undefined');
  const {workOrdersConnection, workOrdersLoading, pageSize, setPageSize}
    = useFilteredWorkOrders(query, workOrderOrderBy(columnSortDirection), !(routeContext || hasQuery));

  const actionableStatusIDs = workOrderStatuses
    ?.filter(({meta}) => meta === 'actionable')
    .filter(({id}) => id !== ON_HOLD_ID)
    .map(({id}) => id);

  const handleUpdateQuery = useCallback(function _handleUpdateQuery(newQuery) {
    let vNewQuery = newQuery;

    if (isNewQueryMoreInclusive(query, newQuery)) {
      const {keywords, ...theRest} = newQuery;
      vNewQuery = {...theRest, keywords: undefined};
    }
    internalHandleUpdateQuery({...vNewQuery, page:1});
  }, [query, internalHandleUpdateQuery]);

  useEffect(() => {
    if (redirect || !actionableStatusIDs?.length || hasQuery || initialized) {
      setInitialized(hasQuery || initialized);
      return;
    }
    setInitialized(true);
    handleUpdateQuery({...query, statusIDs: actionableStatusIDs});
  }, [redirect, actionableStatusIDs, hasQuery, initialized, setInitialized, query, handleUpdateQuery]);

  if (redirect) {
    return (<Navigate to={redirect}/>);
  }

  function handleAddWorkOrder() {
    navigate('/app/work-orders/new');
  }

  function handleClearQuery() {
    handleUpdateQuery({...BASE_QUERY, statusIDs: actionableStatusIDs});
  }

  const {workOrders, total} = workOrdersConnection || {};

  return (
    <QueryContext.Provider value={{query, handleUpdateQuery, handleClearQuery}}>
      <Container className="d-flex flex-column h-100" fluid>
        <Header workOrderCount={total} onNewWorkOrder={handleAddWorkOrder}/>
        <WorkOrderTable workOrders={workOrders} sortDirection={columnSortDirection} loading={workOrdersLoading}
                        onSort={onSort}/>
        {
          workOrdersConnection &&
          (
            <ConnectionPagination connection={workOrdersConnection} pageSize={pageSize} onChangePageSize={setPageSize}/>
          )
        }
      </Container>
    </QueryContext.Provider>
  );
}
