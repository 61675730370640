import React, {useContext} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle, faPrint} from '@fortawesome/free-solid-svg-icons';

import SessionContext from '../../context/session';
import WorkOrderContext from '../../context/WorkOrderContext';

import EditFooter from '../EditFooter';
import MessageModal from '../MessageModal';

import CoreFields from './CoreFields';
import CostsFormGroup from './CostsFormGroup';
import ImagesFormGroup from './ImagesFormGroup';
import Title from './Title';
import WOTabs from './WOTabs';

export default function EditWorkOrderForm(
  {images, validated, loading, onImagesChanged, onImageDeleted, onSubmit, onCancel, onPrint}
) {
  const {isTeacher} = useContext(SessionContext);
  const {existing, abbreviation, number, editErrorMsg, setEditErrorMsg} = useContext(WorkOrderContext);

  let actionButtonLabel = 'Create';
  let progressMsg = 'Creating Work Order';
  if (existing) {
    actionButtonLabel = 'Update';
    progressMsg = 'Updating Work Order';
  }
  const readOnly = existing && isTeacher;

  return (
    <Container fluid>
      <MessageModal heading="Not Allowed" show={!!editErrorMsg} onDismissed={() => setEditErrorMsg(null)}>
        <Row>
          <Col className="text-center" sm={2}>
            <FontAwesomeIcon className="text-warning" icon={faExclamationTriangle} size="3x"/>
          </Col>
          <Col sm={10}>
            {editErrorMsg}
          </Col>
        </Row>
      </MessageModal>
      <Row className="mt-2">
        <Col sm={10}>
          <Title existing={existing} schoolCode={abbreviation} number={number} readOnly={readOnly}/>
        </Col>
        <Col sm={2}>
          <Button variant="outline-primary" onClick={onPrint}>
            <FontAwesomeIcon className="me-2" icon={faPrint}/>
            Print
          </Button>
        </Col>
      </Row>
      <Form noValidate validated={validated} onSubmit={onSubmit}>
        <CoreFields readOnly={readOnly}/>
        {
          !isTeacher && (<CostsFormGroup/>)
        }
        <hr/>
        {
          !isTeacher && (
            <WOTabs images={images} loading={loading} onImagesChanged={onImagesChanged}
                    onImageDeleted={onImageDeleted}/>
          )
        }
        {
          isTeacher &&
          <Row>
            <ImagesFormGroup images={images} readOnly={readOnly} onSelect={onImagesChanged} onDelete={onImageDeleted}/>
          </Row>
        }
        {
          !readOnly && (
            <EditFooter loading={loading} progressMsg={progressMsg} actionLabel={actionButtonLabel}
                        onCancel={onCancel}/>
          )
        }
      </Form>
    </Container>
  );
}