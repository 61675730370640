import React from 'react';
import Image from 'react-bootstrap/Image';

import logo from './sfm_logo.png';

export default function Logo() {
  return (
    <Image src={logo} alt="Logo" fluid/>
  );
}
