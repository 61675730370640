import {createContext} from 'react';

const SessionContext = createContext({
  user: null,
  authenticating: false,
  userLoading: false,
  authenticationError: null,
  currentUserError: null,
  initialLocation: null,
  isTeacher: false,
  onAuthenticateUser: async (credentials) => {},
  onEndSession: () => {}
});

export default SessionContext;
