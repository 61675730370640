import {useContext, useEffect} from 'react';
import {gql, useLazyQuery} from '@apollo/client';

import SessionContext from '../context/session';

const PAY_TYPES_QUERY = gql`
    query payTypes($techID:Int!){
        payTypes(techID:$techID){
            id
            altID
            description
            altDescription
            multiplier
        }
    }
`;

export const REGULAR_HOURLY_PAY_TYPE_ALT_ID = 10;
export const OVERTIME_HOURLY_PAY_TYPE_ALT_ID = 20;
export const VACATION_HOURLY_PAY_TYPE_ALT_ID = 30;
export const HOLIDAY_HOURLY_PAY_TYPE_ALT_ID = 40;
export const SALARY_PAY_TYPE_ALT_ID = 50;
export const VACATION_SALARY_PAY_TYPE_ALT_ID = 60;
export const HOLIDAY_SALARY_PAY_TYPE_ALT_ID = 70;
export const UNPAID_PAY_TYPE_ALT_ID = 80;
export const DOUBLETIME_HOURLY_PAY_TYPE_ALT_ID = 90;

export default function usePayTypes() {
  const {user} = useContext(SessionContext);
  const [fetchPayTypes, {data, loading, error}] = useLazyQuery(PAY_TYPES_QUERY);
  const {payTypes} = data || {};
  const {userID: techID} = user || {};

  useEffect(() => {
    if (techID) {
      fetchPayTypes({variables: {techID}});
    }
  }, [techID, fetchPayTypes]);

  if (error) {
    throw error;
  }

  return {payTypes, payTypesLoading: loading};
}