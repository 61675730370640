import moment from 'moment';

export function formatDateTime(dateString, localTime = false, format = 'MM/DD/YYYY hh:mm A') {
  if (!dateString) {
    return null;
  }
  const dateTime = moment(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
  if (!dateTime.isValid()) {
    return null;
  }
  if (localTime) {
    dateTime.utc();
  }
  return dateTime.format(format);
}

export function formatDate(dateString) {
  return formatDateTime(dateString, false, 'MM/DD/YYYY');
}

