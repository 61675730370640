import {useContext} from 'react';

import SessionContext from '../context/session';
import {replaceParams} from '../lib/path';

const AUTH_LOCATIONS = [
  {location: '/app/db', displayName: 'Home'},
  {location: '/app/work-orders', displayName: 'Work Orders'},
  {location: '/app/vendors', auth: 'canViewPurchaseStats', displayName: 'Vendors'},
  {location: '/app/purchases', auth: 'canQueryOtherThanOwnPurchases', displayName: 'Purchases'},
  {location: '/app/purchases/tech/:id', auth: 'canOnlyQueryOwnPurchases', displayName: 'Purchases'},
  {location: '/app/time-sheets', auth: 'canQueryTimeSheets', displayName: 'Time Sheets'},
  {location: '/app/reports', auth: 'canQueryMultipleReports', displayName: 'Reports'},
  {location: '/app/reports/locations', auth: 'canOnlyQueryLocations', displayName: 'Reports'}
];

export default function useAuthorizedLocations() {
  const {user} = useContext(SessionContext);
  if (!user) {
    return [];
  }
  const params = {id: user.id};
  return AUTH_LOCATIONS
    .filter(({auth}) => !auth || user[auth].call(user))
    .map(({location, displayName}) =>
      ({displayName, location: replaceParams(location, params)}));
}
