import {useCallback} from 'react';
import {gql, useApolloClient} from '@apollo/client';

const PAGE_SIZE = 30;
const USER_SEARCH_QUERY = gql`
    query usersConnection($name:String, $showInactive:Boolean, $pageSize:Int){
        usersConnection(name:$name, showInactive: $showInactive, pageSize:$pageSize)
        @connection(key:"users", filter:["name", "showInactive"]){
            next
            hasMore
            total
            users{
                id
                lastName
                firstName
                status
            }
        }
    }
`;

export default function useUsersSearch(showInactive = false) {
  const client = useApolloClient();

  return useCallback(async name => {
    if (name) {
      const {data, error} = await client.query({
        query: USER_SEARCH_QUERY,
        fetchPolicy: 'network-only',
        variables: {name, showInactive, pageSize: PAGE_SIZE}
      });

      if (error && !data?.length) {
        throw error;
      }

      return data?.usersConnection?.users;
    }
  }, [client, showInactive]);
}