import React, {useContext} from 'react';
import Card from 'react-bootstrap/Card';

import CommentsContext from '../../context/CommentsContext';
import CommentList from './CommentList';

export default function PrintedComments() {
  const {workOrderComments} = useContext(CommentsContext);

  return (
    <Card>
      <Card.Header>Comments</Card.Header>
      <Card.Body>
        <CommentList comments={workOrderComments}/>
      </Card.Body>
    </Card>
  );
}