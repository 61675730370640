import React, {useState} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';

import Header from './Header';
import Item from './Item';

const SCALE_CHANGE = 0.25;
const ROTATE_DEGREES = 90;

export default function Lightbox({images, activeIndex, onClose}) {
  const [currentImage, setCurrentImage] = useState(0);
  const [pageCounts, setPageCounts] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);
  const [rotate, setRotate] = useState(0);

  function handleSelect(index) {
    setCurrentImage(index);
    setPageNumber(1);
    setScale(1);
    setRotate(0);
  }

  function setPDFPageCount(pdfIdx, {numPages}) {
    const newPageCounts = [...pageCounts];
    newPageCounts[pdfIdx] = numPages;
    setPageCounts(newPageCounts);
  }

  return (
    <Modal size="lg" onHide={onClose} show>
      <Header pageCount={pageCounts[currentImage]} prevDisabled={pageNumber === 1}
              nextDisabled={pageNumber === pageCounts[currentImage]} zoomOutDisabled={scale === SCALE_CHANGE}
              onPreviousPage={() => setPageNumber(pageNumber - 1)} onNextPage={() => setPageNumber(pageNumber + 1)}
              onZoomIn={() => setScale(scale + SCALE_CHANGE)} onZoomOut={() => setScale(scale - SCALE_CHANGE)}
              onRotateLeft={() => setRotate(rotate - ROTATE_DEGREES)}
              onRotateRight={() => setRotate(rotate + ROTATE_DEGREES)}>
        Page {pageNumber} of {pageCounts[currentImage]}
      </Header>
      <Modal.Body className="bg-dark">
        <Carousel interval={null} defaultActiveIndex={activeIndex} onSelect={handleSelect}>
          {images.map((src, i) => {
            const itemScale = i === currentImage ? scale : 1;
            const itemRotate = i === currentImage ? rotate : 0;
            return (
              <Carousel.Item key={i}>
                <Item src={src} scale={itemScale} rotate={itemRotate} pageNumber={pageNumber}
                      onPDFLoaded={setPDFPageCount.bind(null, i)}/>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </Modal.Body>
    </Modal>
  );
}
