import React, {useCallback, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {Navigate} from 'react-router-dom';

import useSplitPurchase from '../../hooks/useSplitPurchase';
import USCurrency from '../../components/USCurrency';
import SplitFormRow from './SplitFormRow';

const INIT_SPLIT = {id: null, amount: 0, isSplit: false, isValid: false};

export default function DefineSplitForm({purchase}) {
  const [splitPurchases, setSplitPurchases] = useState([{...purchase, ...INIT_SPLIT}]);
  const [validated, setValidated] = useState(false);
  const [doSplitPurchase] = useSplitPurchase();
  const [redirect, setRedirect] = useState(null);

  const handleAddRow = useCallback((row, changes) => {
    setSplitPurchases(splitPurchases => {
      const newSplitPurchases = [...splitPurchases, {...purchase, ...INIT_SPLIT}];
      newSplitPurchases[row] = {...(newSplitPurchases[row]), ...changes};
      return newSplitPurchases;
    });
  }, [setSplitPurchases, purchase]);

  const handleChange = useCallback((row, changes) => {
    setSplitPurchases(splitPurchases => {
      const newSplits = [...splitPurchases];
      newSplits[row] = {...(splitPurchases[row]), ...changes};
      return newSplits;
    });
  }, [setSplitPurchases]);

  if (redirect) {
    return (<Navigate to={redirect} replace/>);
  }

  const amountRemaining = splitPurchases.reduce((reduction, {amount}) => reduction - amount, purchase.amount);
  const isAmountRemainingZero = Math.abs(amountRemaining).toFixed(2) === '0.00';

  async function handleSubmit(e) {
    e.preventDefault();
    setValidated(true);

    if (!isAmountRemainingZero
      || splitPurchases.find(({isValid}) => !isValid)) {
      return;
    }

    const {errors} = await doSplitPurchase(purchase, splitPurchases);
    if (!errors) {
      setRedirect('/app/purchases');
    }
  }

  const splitFormRows = splitPurchases.map((splitPurchase, i) =>
    <SplitFormRow key={i} row={i} purchase={splitPurchase} amountRemaining={amountRemaining + splitPurchase.amount}
                  onAddRow={handleAddRow} onChange={handleChange}
                  showAddButton={
                    i + 1 === splitPurchases.length
                    && !isAmountRemainingZero
                  }/>
  );

  let amountRemainingClassName;
  if (amountRemaining < 0 && !isAmountRemainingZero) {
    amountRemainingClassName = 'text-danger';
  }
  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row>
        <Col sm={12}>
          {splitFormRows}
        </Col>
      </Row>
      <Row className="border-top pt-3">
        <Col sm={{offset: 8}}>
          <div className={amountRemainingClassName}><USCurrency amount={amountRemaining}/></div>
        </Col>
        <Col className="d-flex align-items-end" sm>
          <Button variant="primary" type="submit">Split Purchase</Button>
        </Col>
      </Row>
    </Form>
  );
}
