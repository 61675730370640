import React, {useCallback} from 'react';

import usePayTypes from '../hooks/usePayTypes';
import ACMSelect from './ACMSelect';

export default function PayTypePicker({payType, onPayTypeSelect, disabled, required, abbreviated, ...props}) {
  const {payTypes, payTypesLoading} = usePayTypes();

  const renderLabel = useCallback(function _renderLabel({description, altDescription}) {
    return abbreviated
           ? altDescription
           : description;
  }, [abbreviated]);

  if (payTypesLoading) {
    return null;
  }

  return (
    <ACMSelect selected={payType} values={payTypes} placeholder="Select Pay Type"
               isLoading={payTypesLoading} onSelect={onPayTypeSelect} disabled={disabled}
               renderLabel={renderLabel} required={required} {...props}/>
  );
}
