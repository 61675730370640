import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import CommentsCard from './CommentsCard';
import DetailsCard from './DetailsCard';
import ImagesCard from './ImagesCard';
import StatusHistoryCard from './StatusHistoryCard';
import TasksAndTimeCard from './TasksAndTimeCard';

export default function WOTabs({images, loading, onImagesChanged, onImageDeleted}){
  return (
    <Tabs id="work-order-tabs" defaultActiveKey="details">
      <Tab eventKey="details" title="Details">
        <DetailsCard loading={loading}/>
      </Tab>
      <Tab eventKey="tasks" title="Tasks & Time">
        <TasksAndTimeCard/>
      </Tab>
      <Tab eventKey="comments" title="Comments">
        <CommentsCard/>
      </Tab>
      <Tab eventKey="images" title="Images">
        <ImagesCard images={images} onImagesChanged={onImagesChanged} onImageDeleted={onImageDeleted}/>
      </Tab>
      <Tab eventKey="statusHistory" title="Status History">
        <StatusHistoryCard/>
      </Tab>
    </Tabs>
  );
}