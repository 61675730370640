import {useCallback, useContext, useEffect, useMemo, useReducer, useState} from 'react';
import moment from 'moment';

import TimeSheetsContext from '../context/TimeSheetsContext';

import useJobTaskSchoolCoordinator from '../hooks/useJobTaskSchoolCoordinator';

function copyDate(originalDate, newDate) {
  if (!newDate) {
    return originalDate;
  }
  const result = moment(originalDate);
  result.year(newDate.year());
  result.month(newDate.month());
  result.date(newDate.date());

  return result;
}

function timeRangeReducer(timeRange, action) {
  let {date, clockIn, clockOut} = timeRange;

  switch (action.type) {
    case 'loadTimeSheetTimes': {
      ({clockIn, clockOut} = action.timeSheet);
      date = moment(clockIn);
      clockIn = copyDate(moment(clockIn), date);
      clockOut = copyDate(moment(clockOut), date);
      break;
    }
    case 'setDate': {
      const {newDate} = action;
      date = newDate && moment(newDate);
      clockIn = copyDate(clockIn, date);
      clockOut = copyDate(clockOut, date);
      break;
    }
    case 'setClockIn': {
      const {newClockIn} = action;
      clockIn = copyDate(newClockIn, date);
      clockOut = copyDate(clockOut, date);
      break;
    }
    case 'setClockOut': {
      const {newClockOut} = action;
      clockOut = copyDate(newClockOut, date);
      break;
    }
    default: {
      throw new Error(`Unknown action: ${action.type}`);
    }
  }

  if (clockOut?.isBefore(clockIn)) {
    clockOut.add(1, 'days');
  }
  return {date, clockIn, clockOut};
}

export default function useTimeSheetState(timeSheet) {
  const now = moment();
  const eightAM = moment(now)
    .hour(8)
    .minutes(0)
    .seconds(0)
    .milliseconds(0);
  const fourPM = moment(now)
    .hour(16)
    .minutes(0)
    .seconds(0)
    .milliseconds(0);

  let initialClockIn = timeSheet?.clockIn
                       ? moment(timeSheet.clockIn)
                       : eightAM;
  let initialClockOut = timeSheet?.clockOut
                        ? moment(timeSheet.clockOut)
                        : fourPM;
  const [timeRange, timeRangeDispatch] = useReducer(timeRangeReducer, {
    date: initialClockIn,
    clockIn: initialClockIn,
    clockOut: initialClockOut
  });
  const {getDefaultJob} = useContext(TimeSheetsContext);
  const [job, setJob] = useState(timeSheet?.job ?? getDefaultJob());
  const [task, setTask] = useState(timeSheet?.task);
  const [payType, setPayType] = useState(timeSheet?.payType);
  const [taskClosed, setTaskClosed] = useState(timeSheet?.closed?.toUpperCase() || 'NONE');
  const [notes, setNotes] = useState(timeSheet?.notes || '');

  const [school, setJobTaskSchool] = useJobTaskSchoolCoordinator(job, setJob, setTask);

  useEffect(() => {
    if (!timeSheet?.id) {
      return;
    }
    const {payType, task} = timeSheet;
    timeRangeDispatch({type: 'loadTimeSheetTimes', timeSheet});
    setPayType(payType);
    setTask(task);
  }, [timeSheet, setPayType, setTask]);

  const setDate = useCallback((newDate) => {
    timeRangeDispatch({type: 'setDate', newDate});
  }, [timeRangeDispatch]);

  const setClockIn = useCallback((newClockIn) => {
    timeRangeDispatch({type: 'setClockIn', newClockIn});
  }, [timeRangeDispatch]);

  const setClockOut = useCallback((newClockOut) => {
    timeRangeDispatch({type: 'setClockOut', newClockOut});
  }, [timeRangeDispatch]);

  const setSchool = useCallback((pSchool) => {
    if (school?.id === pSchool.id) {
      return;
    }
    setJobTaskSchool(pSchool);
    setJob(null);
  }, [setJobTaskSchool, school, setJob]);

  const resetState = useCallback(() => {
    timeRangeDispatch({
      type: 'loadTimeSheetTimes',
      timeSheet: {
        date: moment(eightAM),
        clockIn: moment(eightAM),
        clockOut: moment(fourPM)
      }
    });

    setJob(getDefaultJob());
    setTask(null);
    setPayType(null);
    setTaskClosed('NONE');
    setNotes('');

  }, [timeRangeDispatch, eightAM, fourPM, getDefaultJob, setJob, setTask, setPayType, setTaskClosed, setNotes]);

  const {date, clockIn, clockOut} = useMemo(
    () => timeRange || {},
    [timeRange]
  );

  return useMemo(
    () => ({
      ...(timeSheet || {}),
      date, setDate,
      clockIn, setClockIn,
      clockOut, setClockOut,
      school, setSchool,
      payType, setPayType,
      job, setJob,
      task, setTask,
      taskClosed, setTaskClosed,
      notes, setNotes,
      resetState
    }),
    [
      timeSheet,
      date, setDate,
      clockIn, setClockIn,
      clockOut, setClockOut,
      school, setSchool,
      payType, setPayType,
      job, setJob,
      task, setTask,
      taskClosed, setTaskClosed,
      notes, setNotes,
      resetState
    ]);
}