import React from 'react';

import DivisionPicker from '../DivisionPicker';
import FormGroup from '../FormGroup';

export default function DivisionFormGroup({division, onDivisionSelect, ...props}){
  return (
    <FormGroup controlId="division" label="Division" {...props}>
      <DivisionPicker division={division} onDivisionSelect={onDivisionSelect}/>
    </FormGroup>
  );
}
