import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {useNavigate} from 'react-router-dom';

import useForgotUsername from '../hooks/useForgotUsername';

import EmailAddressForm from '../components/EmailAddressForm';
import MessageModal from '../components/MessageModal';
import RouterButton from '../components/RouterButton';
import UnathenticatedPage from '../components/UnauthenticatedPage';

export default function ForgotUsername() {
  const [lookupUsername, {forgotUsernameSuccess, forgotUsernameLoading}] = useForgotUsername();
  const navigate = useNavigate();

  let lookupMsg;
  if ('boolean' === typeof forgotUsernameSuccess) {
    lookupMsg = 'Please contact customer support for assistance';
    if (forgotUsernameSuccess) {
      lookupMsg = 'Your username has been sent to the email address provided.';
    }
  }

  return (
    <UnathenticatedPage>
      <UnathenticatedPage.Card>
        <Row className="p-4">
          <Col>
            <h4 className="mt-3 fw-bold">Forgot Username?</h4>
            <p>Enter the email address associated with your username:</p>
            <EmailAddressForm primaryActionTitle="Email my username" disabled={forgotUsernameLoading}
                              onSubmit={lookupUsername}/>
          </Col>
        </Row>
      </UnathenticatedPage.Card>
      <UnathenticatedPage.Row>
        <Col className="d-grid" sm={4}>
          <RouterButton variant="outline-primary" to="/app">
          Sign in
        </RouterButton>
        </Col>
      </UnathenticatedPage.Row>
      {
        lookupMsg &&
        <MessageModal heading="Info" size="md" onDismissed={() => navigate('/app', {replace: true})}>
          {lookupMsg}
        </MessageModal>
      }
    </UnathenticatedPage>
  );
}
