import React, {useContext} from 'react';
import Form from 'react-bootstrap/Form';

import SessionContext from '../../context/session';
import useEarliestPurchaseDate from '../../hooks/useEarliestPurchaseDate';

import FormGroup from '../FormGroup';
import ACMSingleDatePicker from '../ACMSingleDatePicker';

export default function DateFormGroup({date, disabled, onDateChange, ...props}) {
  const {earliestPurchaseDate} = useEarliestPurchaseDate();
  const {user} = useContext(SessionContext);

  let minDate = earliestPurchaseDate;
  if (user.canSetEarliestPurchaseDate() || user.isTechManager()) {
    minDate = null;
  }

  return (
    <FormGroup controlId="date" label="Date" {...props}>
      <ACMSingleDatePicker date={date} minDate={minDate} onDateChange={onDateChange} disabled={disabled} />
      <Form.Control.Feedback type="invalid">Date is required</Form.Control.Feedback>
    </FormGroup>
  );
}
