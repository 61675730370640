import {gql} from '@apollo/client';

const PurchaseList = {
  fragments:{
    purchase: gql`
      fragment PurchaseListPurchase on Purchase {
          id
          isSplit
          date
          amount
          notes
          isLocked
          purchaseType{
              id
              description
              category
          }
          school{
              id
              code
          }
          job{
              id
              name
              schoolID
          }
          task{
              id
              description
              note
              assignment{
                  id
                  workOrder{
                      id
                      vNumber
                      abbreviation
                      number
                  }
              }
          }
          purchaseImages{
              id
          }
          vendor{
              id
              name
              isAlias
          }
          indirectVendor{
              id
              name
          }
          technician{
              id
              lastName
              firstName
          }
          bank{
              id
              name
          }
      } `
  }
};

export default PurchaseList;
