import React, {useEffect} from 'react';

import {LOW_PRIORITY} from '../../lib/priorities';
import FormGroup from '../FormGroup';
import PriorityPicker from '../PriorityPicker';

export default function PriorityFormGroup({priority, onSelectPriority, ...props}) {

  useEffect(() => {
    if (!priority) {
      onSelectPriority(LOW_PRIORITY);
    }
  }, [priority, onSelectPriority]);

  return (
    <FormGroup controlId="priority" label="Priority" {...props}>
      <PriorityPicker priority={priority} onSelectPriority={onSelectPriority}/>
    </FormGroup>
  );
}