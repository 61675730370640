import React, {useContext} from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import LightboxContext from '../../context/LightboxContext';
import FormattedDate from '../../components/FormattedDate';
import PurchaseImage from '../../components/PurchaseImage';
import PurchaseTypeIcon from '../../components/PurchaseTypeIcons';
import USCurrency from '../../components/USCurrency';

function DataCard({label, children}) {
  return (
    <Card className="bg-light border-0">
      <Card.Body className="py-2">
        <Card.Title className="sfm-label">{label}</Card.Title>
        {children}
      </Card.Body>
    </Card>
  );
}

export default function Header({purchase}) {
  const {setLightboxImages} = useContext(LightboxContext);
  const {id, amount, date, notes, technician, vendor, purchaseType, purchaseImages} = purchase;
  const {lastName, firstName} = technician;
  const {name} = vendor || {};

  return (
    <>
      <Row className="pt-1 align-items-center bg-light">
        <Col sm={4}>
          <h4 className="fw-bold">Manage Split Purchase</h4>
        </Col>
      </Row>
      <Row className="g-0">
        <Col sm>
          <DataCard label="Technician">{lastName}, {firstName}</DataCard>
        </Col>
        <Col sm>
          <DataCard label="Date"><FormattedDate date={date}/></DataCard>
        </Col>
        <Col sm>
          <DataCard label="Vendor">{name}</DataCard>
        </Col>
        <Col sm>
          <DataCard label="Amount">
            <USCurrency amount={amount}/>
            <PurchaseTypeIcon className="d-inline ms-2" purchaseType={purchaseType}/>
          </DataCard>
        </Col>
        <Col sm>
          <DataCard>
            <PurchaseImage id={`purchase-images-${id}`} purchaseImages={purchaseImages}
                           onClick={setLightboxImages}/>
          </DataCard>
        </Col>
      </Row>
      <Row className="g-0">
        <Col sm>
          <DataCard label="Notes">
            <p>{notes}</p>
          </DataCard>
        </Col>
      </Row>
    </>
  );
}
