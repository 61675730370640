import React from 'react';
import Form from 'react-bootstrap/Form';

import FormGroup from '../FormGroup';

export default function RequestFormGroup({request, onRequestChange, readOnly}) {
  return (
    <FormGroup controlId="request" label="Maintenance Request">
      <Form.Control as="textarea" maxLength={1024} value={request || ''} rows="6" readOnly={readOnly} required
                    onChange={({target}) => onRequestChange(target.value)}/>
      <Form.Control.Feedback type="invalid">Maintenance request is required</Form.Control.Feedback>
    </FormGroup>
  );
}
