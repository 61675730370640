import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {useParams} from 'react-router-dom';

import useGetVendor from '../../hooks/useGetVendor';

import AddAliasDialog from '../../components/AddAliasDialog';
import VendorConversionDialog from '../../components/VendorConversionDialog';

import Header from './Header';
import PurchaseList from './PurchaseList';

/**
 * YELLOWTAG:TJH - should be renamed to VendorDetails, BUT, that means refactoring  UnknownVendorNotice
 * @returns {*}
 * @constructor
 */
export default function PurchasesWithVendor() {
  let {vendorID} = useParams();
  const [getVendor, {vendor}] = useGetVendor();
  const [showAddAliasDialog, setShowAddAliasDialog] = useState(false);
  const [showConversionDialog, setShowConversionDialog] = useState(false)

  if (vendorID) {
    vendorID = parseInt(vendorID);
  }

  useEffect(() => {
    if(vendorID){
      getVendor(vendorID);
    }
  }, [vendorID, getVendor]);

  return (
    <Container className="d-flex flex-column h-100" fluid>
      <Header vendor={vendor} onShowAddAliasDialog={() => setShowAddAliasDialog(true)}
              onConvert={() => setShowConversionDialog(true)}/>
      <PurchaseList vendorID={vendorID}/>
      {showAddAliasDialog && (<AddAliasDialog vendor={vendor} onClose={() => setShowAddAliasDialog(false)}/>)}
      {showConversionDialog && (<VendorConversionDialog vendor={vendor} onClose={() => setShowConversionDialog(false)}/>)}
    </Container>
  );
}
