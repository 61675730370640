import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import SessionContext from '../../context/session';
import QueryContext from '../../context/QueryContext';
import TimeSheetsContext from '../../context/TimeSheetsContext';

import useTimeSheetsContext from '../../hooks/useTimeSheetsContext';
import useFilteredTimeSheets from '../../hooks/useFilteredTimeSheets';
import useAddTimesheet from '../../hooks/useAddTimesheet';
import useUpdateTimeSheet from '../../hooks/useUpdateTimeSheet';
import useDeleteTimesheet from '../../hooks/useDeleteTimesheet';

import AreYouSure from '../../components/AreYouSure';
import TimeSheetView from '../../components/TimeSheetView';
import TimeSheetDialog from '../../components/TimeSheetDialog';
import MessageModal from '../../components/MessageModal';

const NOT_UPDATING = 'not-updating';
const ADD_REQUESTED = 'add-requested';
const UPDATE_REQUESTED = 'update-requested';
const DELETE_REQUESTED = 'delete-requested';

export default function TimeSheetContainer() {
  const {user} = useContext(SessionContext);
  const {query} = useContext(QueryContext);

  const timeSheetsContext = useTimeSheetsContext();
  const {
    startDateTime, endDateTime, earliestPayrollDate, popupPortalRef,
    timeSheetUnderEdit, setTimeSheetUnderEdit,
    timeSheetToDelete, setTimeSheetToDelete
  } = timeSheetsContext;
  const [updateState, setUpdateState] = useState(NOT_UPDATING);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const [fetchTimeSheets, {timeSheetsConnection, timeSheetsLoading, refetchTimeSheets}]
    = useFilteredTimeSheets();

  const options = {onCompleted: () => refetchTimeSheets()};

  const [addTimeSheet, addTimeSheetResult] = useAddTimesheet(options);
  const [updateTimeSheet, updateTimeSheetResult] = useUpdateTimeSheet(options);
  const [deleteTimeSheet, deleteTimeSheetResult] = useDeleteTimesheet(options);

  const {addTimeSheetData, timeSheetSubmitting, addTimeSheetError} = addTimeSheetResult;
  const {updateTimeSheetResponse, timeSheetUpdating, updateTimeSheetError} = updateTimeSheetResult;
  const {deleteTimeSheetData, deleteTimeSheetLoading, deleteTimeSheetError} = deleteTimeSheetResult;

  const disabled = timeSheetSubmitting || timeSheetUpdating;

  const {error: addTimeSheetErrorResponse} = addTimeSheetData || {};
  const {error: updateTimeSheetErrorResponse} = updateTimeSheetResponse || {};
  const {error: deleteTimeSheetErrorResponse} = deleteTimeSheetData || {};

  let startOfDayTimestamp = moment().startOf('day').unix();
  const maxClosedAge = useMemo(() => {
    const today = moment.unix(startOfDayTimestamp);
    let daysClosed = moment.duration(today.diff(earliestPayrollDate))
                           .as('days');
    return Math.ceil(daysClosed);
  }, [startOfDayTimestamp, earliestPayrollDate]);

  useEffect(() => {
    if (query.technicianIDs?.length) {
      fetchTimeSheets({startDateTime, endDateTime, ...query});
    }
  }, [query, fetchTimeSheets, startDateTime, endDateTime]);

  useEffect(() => {
    if (updateState === UPDATE_REQUESTED && !timeSheetUpdating) {
      setUpdateState(NOT_UPDATING);
      setTimeSheetUnderEdit(null);
      setErrorMsg(updateTimeSheetErrorResponse);
    }
  }, [
    updateState,
    timeSheetUpdating,
    setUpdateState,
    setTimeSheetUnderEdit,
    setErrorMsg,
    updateTimeSheetErrorResponse
  ]);

  useEffect(() => {
    if (updateState === ADD_REQUESTED && !timeSheetSubmitting) {
      setUpdateState(NOT_UPDATING);
      setTimeSheetUnderEdit(null);
      setErrorMsg(addTimeSheetErrorResponse);
    }
  }, [
    updateState,
    timeSheetSubmitting,
    setUpdateState,
    setTimeSheetUnderEdit,
    setErrorMsg,
    addTimeSheetErrorResponse
  ]);

  useEffect(() => {
    if (updateState === DELETE_REQUESTED && !deleteTimeSheetLoading) {
      setUpdateState(NOT_UPDATING);
      setTimeSheetToDelete(null);
      setErrorMsg(deleteTimeSheetErrorResponse);
    }
  }, [
    updateState,
    deleteTimeSheetLoading,
    setUpdateState,
    setTimeSheetToDelete,
    setErrorMsg,
    deleteTimeSheetErrorResponse]);

  useEffect(() => {
    if (updateState === NOT_UPDATING
      && errorMsg) {
      setShowError(true);
    }
  }, [updateState, errorMsg, setShowError]);

  const handleAddOrUpdateTimeSheet = useCallback(function _handleAddOrUpdateTimeSheet(timeSheet) {
    let func = addTimeSheet;
    let updateState = ADD_REQUESTED;
    if (timeSheet.id) {
      func = updateTimeSheet;
      updateState = UPDATE_REQUESTED;
    }
    func(timeSheet);
    setUpdateState(updateState);
  }, [addTimeSheet, updateTimeSheet, setUpdateState]);

  const handleCancel = useCallback(function _handleCancel() {
    setTimeSheetUnderEdit(null);
  }, [setTimeSheetUnderEdit]);

  const handleModalDismissed = useCallback(function handleModalDismissed() {
    setShowError(false);
    setErrorMsg(null);
  }, [setShowError, setErrorMsg]);

  const handleDelete = useCallback(() => {
    deleteTimeSheet(timeSheetToDelete);
    setUpdateState(DELETE_REQUESTED);
  }, [timeSheetToDelete, deleteTimeSheet, setUpdateState]);

  const cancelDelete = useCallback(
    () => setTimeSheetToDelete(null),
    [setTimeSheetToDelete]);

  if (!user?.userID) {
    return null;
  }

  const mutationError = addTimeSheetError || updateTimeSheetError || deleteTimeSheetError;
  if (mutationError) {
    console.log(mutationError);
    throw mutationError;
  }

  const {timeSheets} = timeSheetsConnection || {};

  return (
    <TimeSheetsContext.Provider value={timeSheetsContext}>
      <Container ref={popupPortalRef} className="d-flex flex-column h-100 m-3" fluid>
        <MessageModal heading="Time Sheet Error" show={showError} onDismissed={handleModalDismissed}>
          {errorMsg}
        </MessageModal>
        {
          timeSheetUnderEdit &&
          <TimeSheetDialog timeSheet={timeSheetUnderEdit} maxClosedAge={maxClosedAge}
                           onSubmit={handleAddOrUpdateTimeSheet} onCancel={handleCancel}
                           disabled={disabled}/>
        }
        <AreYouSure title="Are you sure?" message="Deleting a time sheet cannot be undone" show={!!timeSheetToDelete}
                    buttons={[
                      <Button key="delete" variant="primary" onClick={handleDelete}>Delete</Button>,
                      <Button key="cancel" variant="outline-primary" onClick={cancelDelete}>Cancel</Button>
                    ]}/>
        <TimeSheetView timeSheets={timeSheets} loading={timeSheetsLoading}/>
      </Container>
    </TimeSheetsContext.Provider>
  );
}
