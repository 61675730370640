import React from 'react';

import useJobSearch from '../hooks/useJobSearch';
import ACMQuerySelect from './ACMQuerySelect';

export default function JobSearch({onSelectJob}) {
  const searchJobs = useJobSearch();

  return (
    <ACMQuerySelect placeholder="Select Job" fetch={searchJobs}
               onSelect={onSelectJob}
               renderLabel={({name, school}) => `${school.code}-${name}`}/>
  );
}
