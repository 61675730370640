import {gql, useQuery} from '@apollo/client';

const WORK_ORDER_TYPES_QUERY = gql`
    query workOrderTypes {
        workOrderTypes {
            id
            name
        }
    }
`;

export const UNSPECIFIED_TYPE = Object.freeze({id:null, name:'Not Specified'});

export default function useWorkOrderTypes() {
  const {data, loading, error} = useQuery(WORK_ORDER_TYPES_QUERY);

  if (error) {
    console.error(error);
    throw error;
  }

  const {workOrderTypes} = data || {};
  return {workOrderTypes, workOrderTypesLoading: loading};
}
