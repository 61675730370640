import React, {useCallback} from 'react';
import moment from 'moment';
import Form from 'react-bootstrap/Form';

const TIME_FORMAT = "HH:mm";
export default function SFMTimePicker({time, onChange, required, ...props}) {

  const handleOnChange = useCallback(function _handleOnChange({target}) {
    if (onChange) {
      const {value} = target;
      onChange(value && moment(value, TIME_FORMAT));
    }
  }, [onChange]);

  const displayTime = time && moment(time).format(TIME_FORMAT);
  return (
    <Form.Control className="sfm-input" type="time" value={displayTime} step={900}
                  onChange={handleOnChange} required={required} {...props}/>
  );
}