import React from 'react';

import FilteredListHeader from '../../components/FilteredListHeader';

import LocationsFilterForm from './LocationsFilterForm';
import LocationsFilterPills from './LocationsFilterPills';

export default function Header() {
  return (
    <FilteredListHeader title="Locations" FilterForm={<LocationsFilterForm/>} FilterPills={<LocationsFilterPills/>}/>
  );
}