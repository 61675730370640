import React from 'react';
import pkg from '../../package.json';

export default function Version() {
  return (
    <>
      <div>server: {window.SFM_SERVER_VERSION}</div>
      <div>client: {pkg.version}</div>
    </>
  );
}

