import React, {Fragment, useContext} from 'react';

import WorkOrderContext from '../../context/WorkOrderContext';
import TasksContext from '../../context/TasksContext';

import useAddTask from '../../hooks/useAddTask';
import useDeleteTasks from '../../hooks/useDeleteTask';
import useUpdateTask from '../../hooks/useUpdateTask';
import {CLOSED_ID, DISCARDED_ID} from '../../hooks/useWorkOrderStatuses';

import FormGroup from '../FormGroup';

import TaskRow from './TaskRow';
import TaskTimeSheets from './TaskTimeSheets';

const FROZEN_STATUS_IDS = Object.freeze([CLOSED_ID, DISCARDED_ID]);

export default function TechTaskFormGroup({...props}) {
  const {id: workOrderID, status, tasks: newWOTasks} = useContext(WorkOrderContext);
  const {tasks = []} = useContext(TasksContext);
  const [addTask] = useAddTask();
  const [deleteTasks] = useDeleteTasks();
  const [updateTask] = useUpdateTask();

  const vTasks = workOrderID
    ? tasks
    : newWOTasks.map((t, i) => ({...t, id: i}));

  const technicians = vTasks?.map(({technician}) => technician)
    .filter(t => !!t);
  return (
    <FormGroup controlId="assignedTechs" label="Tasks" {...props}>
      {
        vTasks.map(task => {
          const {technician: originalTech, timeSheets, ...otherTaskFields} = task;
          const technician = technicians?.find(t => t.id === originalTech?.id);
          return (
            <Fragment key={task.id}>
              <TaskRow task={{...otherTaskFields, technician}} onAddTask={addTask} onUpdateTask={updateTask}
                       onDeleteTask={deleteTasks} locked={timeSheets?.length}/>
              <TaskTimeSheets id={task.id} timeSheets={timeSheets}/>
              <hr/>
            </Fragment>
          );
        })
      }
      {
        !FROZEN_STATUS_IDS.includes(status?.id)
        && (<TaskRow task={{}} onAddTask={addTask}/>)
      }
    </FormGroup>
  );
}
