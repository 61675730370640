import {createContext} from 'react';

const NO_OP = () => {};

export default createContext({
  id: null,
  abbreviation: null,
  number: null,
  vNumber: null,
  date: null,
  school: null,
  requestDate: null,
  request: null,
  name: null,
  workOrderType: null,
  division: null,
  status: null,
  priority: null,
  requester: null,
  proposedImages: [],
  deletedImages: [],
  laborCosts: null,
  materialCosts: null,
  customerMaterialCosts: null,
  purchaseCount: null,
  editErrorMsg: null,
  isValid: false,
  existing: false,
  isDirty: false,
  isAssigned: false,
  comments:[],
  tasks:[],
  fetchedTasks:[],
  setSchool: NO_OP,
  setRequestDate: NO_OP,
  setRequest: NO_OP,
  setName: NO_OP,
  setWorkOrderType: NO_OP,
  setDivision: NO_OP,
  setStatus: NO_OP,
  setPriority: NO_OP,
  setProposedImages: NO_OP,
  setDeletedImages: NO_OP,
  setEditErrorMsg: NO_OP,
  setComments:NO_OP,
  setTasks:NO_OP,
  refetch:NO_OP
});