import {useState} from 'react';
import {gql, useQuery} from '@apollo/client';

import usePagination from './usePagination';

const LOCATIONS_QUERY = gql`
    query locationsConnection(
        $technicianIDs:[Int!], $startDate:Date, $endDate:Date, $orderBy:[OrderBy!], $pageSize:Int, $offset:Int
    )
    @connection(key:"locations", filter:["technicianIDs", "date"])
    {
        locationsConnection(technicianIDs: $technicianIDs, startDate:$startDate, endDate:$endDate, orderBy:$orderBy,
            pageSize: $pageSize, offset: $offset){
            next
            hasMore
            total
            locations {
                id
                address
                userAgent
                deviceID
                timeSheet {
                    id
                    clockIn
                    clockOut
                    notes
                    autoDate
                    job {
                        id
                        name
                    }
                }
                technician {
                    id
                    lastName
                    firstName
                }
            }
        }
    }
`;

const PAGE_SIZE = 10;

export default function useFilteredLocations({technicianIDs, startDate: mStartDate, endDate: mEndDate}, orderBy) {
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const {offset} = usePagination(pageSize);

  const [startDate, endDate] = [mStartDate, mEndDate]
    .filter(d => !!d)
    .map(d => d.format('YYYY-MM-DD'));
  const {data, loading, error, refetch} = useQuery(LOCATIONS_QUERY, {
    variables: {technicianIDs, startDate, endDate, orderBy, pageSize, offset},
    fetchPolicy: 'cache-and-network'
  });

  if (error && !data?.length) {
    throw error;
  }

  const {locationsConnection} = data || {};
  return {locationsConnection, locationsLoading: loading, refetchLocations: refetch, pageSize, setPageSize};
}