import React from 'react';
import Container from 'react-bootstrap/Container';
import {Navigate, useParams} from 'react-router-dom';

import QueryContext from '../../context/QueryContext';

import useColumnSort from '../../hooks/useColumnSort';
import useEditPurchaseDialog from '../../hooks/useEditPurchaseDialog';
import useFilteredPurchases from '../../hooks/useFilteredPurchases';
import useDeletePurchase from '../../hooks/useDeletePurchase';
import usePurchaseListQueryParams, {toUrl, BASE_QUERY} from '../../hooks/usePurchaseListQueryParams';
import useQueryContext from '../../hooks/useQueryContext';

import ConnectionPagination from '../../components/ConnectionPagination';
import PurchaseTable, {purchaseOrderBy} from '../../components/PurchaseTable';

import Header from './Header';

const ROUTE_CONTEXT_KEY = 'page.PurchasesByTech';

export default function PurchasesByTech() {
  const {id} = useParams();
  const [query, setQuery] = usePurchaseListQueryParams();
  const {redirect, ...purchaseListQueryContext} =
    useQueryContext(ROUTE_CONTEXT_KEY, query, setQuery, toUrl, BASE_QUERY);
  const {columnSortDirection, onSort} = useColumnSort(new Map([['date', 'DESC']]));
  const {purchasesConnection, purchasesLoading, refetchPurchases, pageSize, setPageSize}
    = useFilteredPurchases(
    {...(purchaseListQueryContext.query), accessID: parseInt(id)},
    purchaseOrderBy(columnSortDirection)
  );
  const [handleEditPurchase, editPurchaseDialog] = useEditPurchaseDialog(refetchPurchases);
  const [handleDeletePurchase, deletePurchaseDialog] = useDeletePurchase(refetchPurchases);

  if (redirect) {
    return (<Navigate to={redirect} replace/>);
  }

  const {purchases} = purchasesConnection || {};
  return (
    <QueryContext.Provider value={purchaseListQueryContext}>
      <Container className="d-flex flex-column h-100" fluid>
        <Header onNewPurchase={() => handleEditPurchase({})}/>
        <PurchaseTable purchases={purchases} loading={purchasesLoading} sortDirection={columnSortDirection}
                       onEdit={handleEditPurchase} onSort={onSort} onDelete={handleDeletePurchase}
                       showVendor/>
        {purchasesConnection && <ConnectionPagination connection={purchasesConnection} pageSize={pageSize}
                                                      onChangePageSize={setPageSize}/>}
        {editPurchaseDialog}
        {deletePurchaseDialog}
      </Container>
    </QueryContext.Provider>
  );
}
