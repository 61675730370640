import React from 'react';
import Table from 'react-bootstrap/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';

import {formatDateTime} from '../../lib/timeUtils';

export default function StatusHistoryTable({statusHistory}) {
  if (!statusHistory?.length) {
    return null;
  }

  return (
    <Table size="sm" bordered responsive>
      <thead className="bg-secondary">
      <tr>
        <th>Status</th>
        <th>By</th>
        <th>Date</th>
        <th>Automated</th>
      </tr>
      </thead>
      <tbody>
      {
        statusHistory.map(({id, automated, timeStamp, status, user}) => {
          let userName = '<unknown>';
          if (user) {
            userName = `${user.firstName} ${user.lastName}`;
          }
          return (
            <tr key={`status-history-${id}`}>
              <td>{status.name}</td>
              <td>{userName}</td>
              <td>{formatDateTime(timeStamp, true) || (<span className="text-muted font-italic">unknown</span>)}</td>
              <td>{automated && (
                <span className="text-center">
                  <FontAwesomeIcon className="text-primary" icon={faCheck}/>
                </span>
              )}</td>
            </tr>
          );
        })
      }
      </tbody>
    </Table>
  );
}