import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

export default function FormGroup({controlId, label, children, ...props}){
  return (
    <Form.Group controlId={controlId} as={Col} {...props}>
      <Form.Label>{label}</Form.Label>
      {children}
    </Form.Group>
  )
}