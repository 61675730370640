import {gql, useQuery} from '@apollo/client';

const WORK_ORDER_STATUSES_QUERY = gql`
    query workOrderStatuses {
        workOrderStatuses {
            id
            name
            workable
            meta
        }
    }
`;

export const INITIAL_REQUEST_ID = 1;
export const SCREENED_ID = 2;
export const APPROVED_ID = 4;
export const CLOSED_ID = 7
export const ON_HOLD_ID = 8;
export const DISCARDED_ID = 9;

export default function useWorkOrderStatuses() {
  const {data, loading, error} = useQuery(WORK_ORDER_STATUSES_QUERY);

  if (error) {
    console.error(error);
    throw error;
  }

  const {workOrderStatuses} = data || {};
  const defaultWorkOrderStatus = workOrderStatuses?.find(({id}) => id === INITIAL_REQUEST_ID);
  return {workOrderStatuses, defaultWorkOrderStatus, workOrderStatusesLoading: loading};
}

