import React from 'react';
import {faTimesCircle} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';

export default function SFMFilterPill({children, permanent, onRemove}) {
  return (
    <Col className="px-1" sm="auto">
      <p className="mb-2">
        <Badge className="sfm-filter-pill" bg="light" pill>
          {children}
          {!permanent && (
            <Button variant="light" onClick={onRemove}>
              <FontAwesomeIcon size="sm" transform="up-1 left-1 grow-2" icon={faTimesCircle}/>
            </Button>
          )}
        </Badge>
      </p>
    </Col>
  );
}
