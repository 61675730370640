import {useCallback} from 'react';
import {gql, useLazyQuery} from '@apollo/client';

import purchaseStatsFragment from '../graphql/purchase-stats-fragment';
import vendorFragment from '../graphql/vendor-fragment';

const VENDOR_ALIAS_QUERY = gql`
    query getVendorAliases($id:Int!){
        getVendorAliases(id:$id){
            ...VendorFields
            purchaseStats{
                ...PurchaseStatsFields
            }
        }
    }
    ${purchaseStatsFragment}
    ${vendorFragment}
`;

export default function useGetVendorAliases() {
  const [gqlGetVendorAliases, {data, loading, error, refetch}] = useLazyQuery(VENDOR_ALIAS_QUERY);
  const getVendorAliases = useCallback(id => gqlGetVendorAliases({variables: {id}}), [gqlGetVendorAliases]);

  if (error && !(data && data.length)) {
    throw error;
  }

  const {getVendorAliases: aliases} = data || {};
  return [getVendorAliases, {aliases, aliasesLoading: loading, refetchAliases: refetch}];
}
