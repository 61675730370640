import {useCallback} from 'react';
import {gql, useMutation} from '@apollo/client';

import vendorFragment from '../graphql/vendor-fragment';

const ADD_VENDOR_MUTATION = gql`
    mutation addVendor($name:String!){
        addVendor(name:$name){
            ...VendorFields
        }
    }

    ${vendorFragment}
`;

export default function useAddVendor(options) {
  const [gqlAddVendor, {loading, error}] = useMutation(ADD_VENDOR_MUTATION, options);

  const addVendor = useCallback(name => {
    return gqlAddVendor({variables: {name}});
  }, [gqlAddVendor]);

  return [addVendor, {addVendorLoading: loading, addVendorError: error}];
}
