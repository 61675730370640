import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle, faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import Image from 'react-bootstrap/Image';

const style = {
  thumbnail: {
    width: '100px',
    height: '100px',
    imageOrientation: 'from-image'
  },
  deleteButton: {
    minWidth: 26,
    minHeight: 26,
    opacity: 0.8
  }
};

export default function Thumbnail({src, onDelete, onClick}) {
  //YELLOWTAG:TJH - we need to rewrite EditPurchase so that it's not a dialog.
  // Then, we need to add an 'are you sure' dialog when someone wants to delete an image
  function handleDelete(event) {
    event.stopPropagation();
    onDelete();
  }

  return (
    <Card className="p-0" as={Col} style={{cursor: 'pointer'}} onClick={onClick} sm={"auto"}>
      <Card.Img as={Image} style={style.thumbnail} src={src} thumbnail/>
      <Card.ImgOverlay className="p-1 d-flex align-items-end flex-column">
        <Col className="mt-auto p-0 d-flex align-items-end flex-column" sm={3}>
          <Button className="fa-layers fa-fw" variant="dark" style={style.deleteButton}
                  onClick={handleDelete}>
            <FontAwesomeIcon transform="grow-8" icon={faCircle} inverse/>
            <FontAwesomeIcon icon={faTrashAlt} inverse/>
          </Button>
        </Col>
      </Card.ImgOverlay>
    </Card>
  );
}
