import {gql, useMutation} from '@apollo/client';

const UPDATE_WORK_ORDER = gql`
    mutation updateWorkOrder(
        $id: Int!
        $requestDate: String!
        $request: String!
        $name: String
        $workOrderTypeID: Int
        $divisionID: Int
        $statusID: Int!
        $priority: Int
        $images: [Upload!]
        $deletedImageIDs: [Int!]
    ) {
        updateWorkOrder(
            id: $id
            requestDate: $requestDate
            request: $request
            name: $name
            workOrderTypeID: $workOrderTypeID
            divisionID: $divisionID
            statusID: $statusID
            priority: $priority
            images: $images
            deletedImageIDs: $deletedImageIDs
        ) {
            id
        }
    }
`;

export default function useUpdateWorkOrder(options) {
  const [gqlUpdateWorkOrder, {loading, data, error}] = useMutation(UPDATE_WORK_ORDER, options);

  function updateWorkOrder(workOrder) {
    const {school, requestDate: mDate, workOrderType, division, status, ...theRest} = workOrder;
    const {id: schoolID} = school;
    const {id: workOrderTypeID} = workOrderType || {};
    const {id: divisionID} = division || {};
    const {id: statusID} = status || {};
    const requestDate = mDate.format('YYYY-MM-DD');

    const variables = {...theRest, schoolID, workOrderTypeID, divisionID, statusID, requestDate};

    return gqlUpdateWorkOrder({variables});
  }

  const {updateWorkOrder: updatedWorkOrder} = data || {};
  return [updateWorkOrder, {updatedWorkOrder, updateWorkOrderLoading: loading, updateWorkOrderError: error}];
}
