import React, {useState} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Navigate} from 'react-router-dom';

import useResetPasswordRequest from '../hooks/useResetPasswordRequest';

import EmailAddressForm from '../components/EmailAddressForm';
import MessageModal from '../components/MessageModal';
import RouterButton from '../components/RouterButton';
import UnauthenticatedPage from '../components/UnauthenticatedPage';

const SUCCESS_MESSAGE = 'Your reset request has been received. Please check your inbox';
export default function ForgotPassword() {
  const [resetPasswordRequest, {resetPasswordRequestSuccess, resetPasswordRequestLoading}] = useResetPasswordRequest();
  const [redirect, setRedirect] = useState(null);

  if (redirect) {
    return (<Navigate to={redirect} replace/>);
  }

  return (
    <>
      <UnauthenticatedPage>
        <UnauthenticatedPage.Card>
          <Row className="p-4">
            <Col>
              <h4 className="mt-3 fw-bold">Forgot Password?</h4>
              <p>You can reset your password here</p>
              <EmailAddressForm primaryActionTitle="Reset Password" disabled={resetPasswordRequestLoading}
                                onSubmit={resetPasswordRequest}/>
            </Col>
          </Row>
        </UnauthenticatedPage.Card>
        <UnauthenticatedPage.Row>
          <Col className="d-grid" sm={4}>
            <RouterButton variant="outline-primary" to="/app/login">
              Sign in
            </RouterButton>
          </Col>
        </UnauthenticatedPage.Row>
      </UnauthenticatedPage>
      {resetPasswordRequestSuccess && (
        <MessageModal heading="Info" size="md" onDismissed={() => setRedirect('/app/login')}>
          <p>{SUCCESS_MESSAGE}</p>
        </MessageModal>
      )}
    </>
  );
}
