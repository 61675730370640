import React, {Component} from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {Navigate, Route, Routes} from 'react-router-dom';
import {Slide, ToastContainer} from 'react-toastify';

import SessionContext from '../context/session';
import ForgotPassword from '../page/ForgotPassword';
import ForgotUsername from '../page/ForgotUsername';
import Login from '../page/Login';
import ResetPassword from '../page/ResetPassword';

import AuthenticatedView from './AuthenticatedView';

export default class MainView extends Component {
  static contextType = SessionContext;

  constructor(props) {
    super(props);
    this.state = {sessionInvalidated: false};
  }

  // noinspection JSUnusedGlobalSymbols
  static getDerivedStateFromError(error) {
    const {graphQLErrors} = error;
    const unAuthenticated = graphQLErrors && graphQLErrors
      .map(({extensions}) => extensions && extensions.code)
      .find(c => c === 'UNAUTHENTICATED' || c === 'FORBIDDEN');
    if (graphQLErrors && unAuthenticated) {
      return {sessionInvalidated: true};
    } else {
      throw error;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.sessionInvalidated && !prevState.sessionInvalidated) {
      this.context.onEndSession();
      this.setState({sessionInvalidated: false});
    }
  }

  render() {
    const {user} = this.context;

    let banner = null;
    if ('Production' !== window.SFM_APP_ENV) {
      banner = (
        <h1 className="text-center bg-primary text-white fw-bold mb-0">
          {window.SFM_APP_ENV}
        </h1>
      );
    }

    return (
      <>
        <Container className="px-0 d-flex flex-column vh-100" fluid>
          <Row className="d-block g-0">
            <Col sm={12}>
              {banner}
            </Col>
          </Row>
          <Row className="h-100 g-0">
            <Col sm={12}>
              {user
                ? (<AuthenticatedView/>)
                : (
                  <Routes>
                    <Route path="/app">
                      <Route index element={<Login/>}/>
                      <Route path="forgot-password" element={<ForgotPassword/>}/>
                      <Route path="forgot-username" element={<ForgotUsername/>}/>
                      <Route path="reset-password" element={<ResetPassword/>}/>
                    </Route>
                    <Route path="*" element={<Navigate replace to="/app"/>}/>
                  </Routes>
                )}
            </Col>
          </Row>
        </Container>
        <ToastContainer transition={Slide} autoClose={5000} toastClassName="sfm-toast"
                        progressClassName="sfm-progress-bar"/>
      </>
    );
  }
}
