import {useCallback} from 'react';
import {gql, useMutation} from '@apollo/client';

const MUTATION_AUTHENTICATE = gql`
    mutation authenticate($username:String!, $password:String!){
        authenticate(username:$username, password:$password){
            id
        }
    }
`;

export default function useAuthenticate() {
  const [gqlAuthenticate, {data, loading, error}] = useMutation(MUTATION_AUTHENTICATE);

  if (error) {
    console.error(error);
  }

  const authenticate = useCallback((credentials) => {
    gqlAuthenticate({variables: credentials});
  }, [gqlAuthenticate]);

  const {authenticate: authenticatedUser} = data || {};

  const authenticated = !!authenticatedUser
  return [authenticate, {authenticated, authenticating: loading, authenticationError:error}];
}
