import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import moment from 'moment';

import TimeSheetsContext from '../context/TimeSheetsContext';

import {useMediaQuery} from 'react-responsive';

import {ALL_TASKS, CLOSED_TASKS, MY_TASKS} from '../hooks/useFilteredTasks';
import usePayTypeSchoolAndJobSelections from '../hooks/usePayTypeSchoolAndJobSelections';
import useTimeSheetState from '../hooks/useTimeSheetState';

import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import AllMineClosedButtonGroup from './AllMineClosedButtonGroup';
import ACMSingleDatePicker from './ACMSingleDatePicker';
import PayTypePicker from './PayTypesPicker';
import SFMTimePicker from './SFMTimePicker';
import TaskClosedPicker from './TaskClosedPicker';
import FormattedDate from './FormattedDate';
import ClientLookup from './ClientLookup';
import JobLookup from './JobLookup';
import TaskLookup from './TaskLookup';
import FormGroup from './FormGroup';

function formatTimeUnit(unit) {
  return `0${unit}`.slice(-2);
}

function formatDuration(duration) {
  return `${formatTimeUnit(duration.hours())}:${formatTimeUnit(duration.minutes())}`;
}

function calculateTaskType(task, timeSheet) {
  let initialTaskType = ALL_TASKS;
  if (task?.assignment.closeDate) {
    initialTaskType = CLOSED_TASKS;
  } else if (!task || task.technician.id === timeSheet?.technician.id) {
    initialTaskType = MY_TASKS;
  }
  return initialTaskType;
}

function timeIsQuarterHour(mTime) {
  return (mTime.minutes() % 15) === 0;
}

export default function TimeSheetDialog(
  {timeSheet, maxClosedAge, onSubmit, onCancel, disabled, onClose}
) {
  const {
    earliestPayrollDate,
    setDefaultSchool,
    setDefaultJob
  } = useContext(TimeSheetsContext);
  const {
    date, setDate,
    clockIn, setClockIn,
    clockOut, setClockOut,
    payType, setPayType: syncPayType,
    school, setSchool: internalSetSchool,
    job, setJob: syncJob,
    task, setTask,
    taskClosed, setTaskClosed,
    notes, setNotes,
    resetState
  } = useTimeSheetState(timeSheet);
  const {setPayType, setJob: internalSetJob}
    = usePayTypeSchoolAndJobSelections({payType, syncPayType, school, job, syncJob});
  const [taskSetType, setTaskSetType] = useState(calculateTaskType(task, timeSheet));
  const [validated, setValidated] = useState(false);
  const isLarge = useMediaQuery({query: '(min-width: 992px)'});

  const duration = useMemo(
    () => clockIn && clockOut && moment.duration(clockOut.diff(clockIn)),
    [clockIn, clockOut]
  );

  const setSchool = useCallback(school => {
    internalSetSchool(school);
    setDefaultSchool(school);
  }, [internalSetSchool, setDefaultSchool]);

  const setJob = useCallback(job => {
    internalSetJob(job);
    setDefaultJob(job);
  }, [internalSetJob, setDefaultJob]);

  useEffect(() => {
    setTaskSetType(calculateTaskType(task, timeSheet));
  }, [timeSheet, task, setTaskSetType]);

  const handleNotesChange = useCallback(function _handleNotesChange({target}) {
    setNotes(target.value);
  }, [setNotes]);

  const handleSubmit = useCallback(function _handleSubmit(e) {
    e.preventDefault();
    setValidated(true);
    const hasTaskOrNotes = !!task || !!notes;
    const isFormValid = hasTaskOrNotes
      && timeIsQuarterHour(clockIn)
      && timeIsQuarterHour(clockOut);
    if (!isFormValid) {
      return;
    }
    onSubmit({id: timeSheet?.id, clockIn, clockOut, job, task, payType, taskClosed, notes});
    resetState();
  }, [
    setValidated, task, notes, onSubmit, timeSheet, clockIn, clockOut, job, payType, taskClosed, resetState
  ]);

  const selectClassNames = {singleValue: () => 'text-wrap'};
  let btnLabel = 'Add';
  if (timeSheet.id) {
    btnLabel = 'Update';
  }
  let rowCount = isLarge
                 ? 6
                 : 10;

  return (
    <Modal size="lg" show>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            <h4 className="fw-bold">Edit Time Sheet</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <FormGroup lg={3} controlId="tsDate" label="Date">
              <ACMSingleDatePicker date={date} minDate={earliestPayrollDate} onDateChange={setDate}
                                   formControlFeedback={
                                     <Form.Control.Feedback type="invalid">
                                       Date must be on or after <FormattedDate date={earliestPayrollDate}/>
                                     </Form.Control.Feedback>
                                   }
                                   readOnly={disabled} required/>
            </FormGroup>
            <FormGroup lg={1}>
              <Form.Label>Day</Form.Label>
              <div>
                <FormattedDate format="ddd" date={date}/>
              </div>
            </FormGroup>
            <FormGroup controlId={"clockIn"} label="Time In">
              <SFMTimePicker time={clockIn} onChange={setClockIn} disabled={disabled} required/>
              <Form.Control.Feedback type="invalid">Time must be in 15 minute intervals</Form.Control.Feedback>
            </FormGroup>
            <FormGroup controlId={"clockOut"} label="Time Out">
              <SFMTimePicker time={clockOut} onChange={setClockOut} disabled={disabled} required/>
              <Form.Control.Feedback type="invalid">Time must be in 15 minute intervals</Form.Control.Feedback>
            </FormGroup>
            <FormGroup lg={3}>
              <Form.Label>Duration</Form.Label>
              <div>
                {duration && formatDuration(duration)}
              </div>
            </FormGroup>
            <FormGroup lg={4} controlId="payType" label="Pay Type">
              <PayTypePicker classNames={selectClassNames} payType={payType} onPayTypeSelect={setPayType}
                             disabled={disabled} required/>
            </FormGroup>
            <FormGroup lg={5} label="Job/Task">
              <ClientLookup classNames={selectClassNames} client={school} onClientSelect={setSchool}
                            disabled={disabled} required/>
              <Form.Control.Feedback type="invalid">Client is required</Form.Control.Feedback>
              <JobLookup classNames={selectClassNames} client={school} job={job} onJobSelect={setJob}
                         disabled={disabled} required/>
              <Form.Control.Feedback type="invalid">Job is required</Form.Control.Feedback>
              <TaskLookup classNames={selectClassNames} client={school} task={task} maxClosedAge={maxClosedAge}
                          taskSetType={taskSetType} onTaskSelect={setTask} disabled={disabled} isClearable/>
              <AllMineClosedButtonGroup className="mt-2" value={taskSetType} onChange={setTaskSetType}
                                        disabled={disabled}/>
            </FormGroup>
            <FormGroup lg={3} controlId="taskClosed" label="Task Closed">
              <TaskClosedPicker selected={taskClosed} onSelect={setTaskClosed} disabled={!task}/>
            </FormGroup>
            <FormGroup lg controlId="tsNotes" label="Notes">
              <Form.Control as="textarea" maxLength={500} rows={rowCount} value={notes} onChange={handleNotesChange}
                            disabled={disabled} required={!task}/>
              <Form.Control.Feedback type="invalid">
                Notes are required unless a task is selected
              </Form.Control.Feedback>
            </FormGroup>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" disabled={disabled}>{btnLabel}</Button>
          <Button variant="outline-primary" onClick={onCancel} disabled={disabled}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
