import {useCallback, useContext} from 'react';
import {useMutation, gql} from '@apollo/client';

import WorkOrderContext from '../context/WorkOrderContext';
import TasksContext from '../context/TasksContext';

const DELETE_TASK_MUTATION = gql`
    mutation deleteTask($taskIDs:[Int!]!){
        deleteWorkOrderTasks(taskIDs:$taskIDs){
            message
        }
    }
`;

export default function useDeleteTasks() {
  const {id:workOrderID, setTasks: setNewWOTasks} = useContext(WorkOrderContext);
  const {refetchTasks} = useContext(TasksContext);
  const [gqlDeleteTasks, {data, loading, error}] = useMutation(DELETE_TASK_MUTATION, {onCompleted:refetchTasks});

  if (error && !data?.length) {
    throw error;
  }

  const deleteTask = useCallback(task => {
    let result;
    if(workOrderID){
      result = task && gqlDeleteTasks({variables: {taskIDs: [task.id]}});
    } else {
      setNewWOTasks(newWOTasks => newWOTasks.filter((t, i) => i !== task.id));
    }

    return result;
  }, [workOrderID, gqlDeleteTasks, setNewWOTasks]);

  return [deleteTask, {deleteTasksData: data, deleteTasksLoading: loading}];
}