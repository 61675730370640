import {useCallback, useContext, useMemo} from 'react';
import moment from 'moment';
import {gql, useMutation} from '@apollo/client';

import SessionContext from '../context/session';

const ADD_TIMESHEET_MUTATION = gql`
    mutation addTimeSheet(
        $technicianID:Int!,
        $clockIn:DateTime!,
        $clockOut:DateTime!,
        $notes: String,
        $taskClosed: TaskClosed!,
        $payrollItemID: Int!,
        $jobID:Int!,
        $taskID:Int
    ){
        utcAddTimeSheet(
            clockIn: $clockIn,
            clockOut: $clockOut,
            notes: $notes,
            taskClosed: $taskClosed,
            technicianID: $technicianID,
            payrollItemID: $payrollItemID,
            jobID: $jobID,
            taskID:$taskID
        ){
            ... on TimeSheet {
                id
            }
            ... on ErrorResponse {
                error
            }
        }
    }
`;

export default function useAddTimesheet(options) {
  const {user} = useContext(SessionContext);
  const [gqlAddTimesheet, mutationResponse] = useMutation(ADD_TIMESHEET_MUTATION, options);
  const {userID: technicianID} = user;

  const addTimesheet = useCallback(timeSheet => {
    const {
      clockIn: pClockIn, clockOut: pClockOut, job, task, payType, taskClosed, notes
    } = timeSheet;
    const [clockIn, clockOut] = [pClockIn, pClockOut]
      .map(m => moment(m).toISOString()
      );
    const variables = {
      clockIn, clockOut, notes, taskClosed, technicianID, payrollItemID: payType.altID, jobID: job.id,
      taskID: task?.id
    };
    return gqlAddTimesheet({variables});
  }, [gqlAddTimesheet, technicianID]);

  return useMemo(() => {
    const {data, loading, error} = mutationResponse;
    const response = data?.utcAddTimeSheet || {};
    return [addTimesheet, {addTimeSheetData: response, timeSheetSubmitting: loading, addTimeSheetError: error}];
  }, [addTimesheet, mutationResponse]);
}