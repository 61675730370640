import React, {forwardRef, useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';

const CURRENCY_REGEX = /^-?\d*(\.\d{0,2})?$/;

function toCurrency(float) {
  return (float && float.toFixed(2)) || '';
}

function ACMCurrencyInput({className, value, onChange, ...props}, ref) {
  const [stringValue, setStringValue] = useState(toCurrency(value));

  useEffect(() => {
    if (value !== parseFloat(stringValue)) {
      setStringValue(toCurrency(value));
    }
  }, [value, stringValue]);

  function handleChange({target}) {
    const {value} = target;
    if (!CURRENCY_REGEX.test(value)) {
      return;
    }

    setStringValue(value);
    const float = parseFloat(value);
    onChange(isNaN(float) ? null : float);
  }

  return (
    <Form.Control ref={ref} className={`border-dark ${className || ''}`} value={stringValue}
                  onChange={handleChange} {...props}/>
  );
}

export default forwardRef(ACMCurrencyInput);
