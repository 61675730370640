import React from 'react';
import moment from 'moment';

import FormattedDate from './FormattedDate';

export default function LocationsTableRow({location}) {
  const {userAgent, deviceID, address, technician, timeSheet} = location;
  const {firstName, lastName} = technician;
  const {autoDate, clockIn, clockOut, job, notes} = timeSheet;
  const {name: jobName} = job;
  const entry = [clockIn, clockOut]
    .map(t => moment(t).format('MM/DD/YYYY hh:mm a'))
    .join(' - ');
  return (
    <tr>
      <td>{firstName} {lastName}</td>
      <td>{autoDate == null ? null : <FormattedDate date={autoDate}/>}</td>
      <td>{userAgent}</td>
      <td>{deviceID}</td>
      <td>{address}</td>
      <td>{entry}</td>
      <td>{jobName}</td>
      <td>{notes}</td>
    </tr>
  );
}