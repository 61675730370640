import React, {useContext} from 'react';
import {Link} from 'react-router-dom';

import SessionContext from '../context/session';

import {UNSPECIFIED_DIVISION} from '../hooks/useDivisions';

import FormattedDate from './FormattedDate';
import SchoolCode from './SchoolCode';

export default function WorkOrderTableRow({workOrder}) {
  const {isTeacher} = useContext(SessionContext);
  const {id, date, abbreviation, number, request, name, status, division, requester, isAssigned} = workOrder;
  const {lastName, firstName} = requester || {lastName: '', firstName: ''};
  const displayName = name || `(${request.substring(0, 39)}...)`;

    return (
    <tr>
      <td>
        {date == null ? null : <FormattedDate date={date}/>}
      </td>
      <td>
        <Link to={`/app/work-orders/${id}`}>
          <SchoolCode code={abbreviation}/>{number}
        </Link>
      </td>
      <td>{firstName} {lastName}</td>
      <td>
        <Link to={`/app/work-orders/${id}`}>
          <span className="fw-bold">{displayName}</span>
        </Link>
      </td>
      <td>{status.name}</td>
      {
        !isTeacher && (
          <>
            <td>{isAssigned ? 'Yes' : 'No'}</td>
            <td>{(division || UNSPECIFIED_DIVISION).name}</td>
          </>
        )
      }
    </tr>
  );
}
