import {gql} from '@apollo/client';

export default gql`
  fragment WorkOrderFields on WorkOrder{
      id
      number
      abbreviation
      vNumber
      date
      name
      request
      isAssigned
      priority
      requester {
          id
          lastName
          firstName
      }
      status {
          id
          name
      }
      division {
          id
          name
      }
  }

`;