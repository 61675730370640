import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form';

import useJobTaskSchoolCoordinator from '../hooks/useJobTaskSchoolCoordinator';

import FormGroup from './FormGroup';
import ClientLookup from './ClientLookup';
import JobLookup from './JobLookup';
import TaskLookup from './TaskLookup';

export default function JobAndTaskPicker({job, task, maxClosedAge, onSelectJob, onSelectTask, disabled, collapse}) {
  const [school, setSchool] = useJobTaskSchoolCoordinator(job, onSelectJob, onSelectTask);

  return (
    <Card className="m-2 flex-shrink-1" as={Col} sm={12} border="primary">
      <Collapse in={!collapse}>
        <div>
          <Card.Body>
            <Row>
              <FormGroup controlId="client" label="Client">
                <ClientLookup client={school} onClientSelect={setSchool} disabled={disabled}
                              required={!collapse}/>
                <Form.Control.Feedback type="invalid">Client is required</Form.Control.Feedback>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup controlId="job" label="Job">
                <JobLookup client={school} job={job} onJobSelect={onSelectJob} disabled={disabled}
                           required={!collapse}/>
                <Form.Control.Feedback type="invalid">Job is required</Form.Control.Feedback>
              </FormGroup>
              <FormGroup controlId="task" label="Task">
                <TaskLookup client={school} task={task} maxClosedAge={maxClosedAge} onTaskSelect={onSelectTask}
                            disabled={disabled} isClearable/>
              </FormGroup>
            </Row>
          </Card.Body>
        </div>
      </Collapse>
    </Card>
  );
}
