import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function TruncatedText({id, maxWidth, text, className}){
  return (
    <OverlayTrigger placement="auto"
                    overlay={<Tooltip id={id}>{text}</Tooltip>}>
      <p style={{maxWidth}} className={`${className} text-truncate`}>{text}</p>
    </OverlayTrigger>
  )
}