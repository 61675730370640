import React, {createRef, useEffect} from 'react';
import InputGroup from 'react-bootstrap/InputGroup';

import ACMCurrencyInput from './ACMCurrencyInput';

const style = {
  borderTopColor: '#333',
  borderBottomColor: '#333',
  borderLeftColor: '#333'
};

export default function CurrencyInputGroup({amount, disabled, isInvalid, onAmountChange, feedback}) {
  let required;
  if ('boolean' !== typeof isInvalid) {
    required = true;
  }

  const controlRef = createRef();

  useEffect(() => {
    const {current} = controlRef;

    let validity = '';
    if (isInvalid) {
      validity = 'invalid';
    }
    current.setCustomValidity(validity);
  });

  return (
    <InputGroup>
      <InputGroup.Text style={style}>$</InputGroup.Text>
      <ACMCurrencyInput ref={controlRef} className="border-left-0 rounded-right" placeholder="0.00" value={amount}
                        disabled={disabled} onChange={onAmountChange} isInvalid={isInvalid} required={required}/>
      {feedback}
    </InputGroup>
  );
}
