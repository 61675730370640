import {useContext} from 'react';
import {gql, useQuery} from '@apollo/client';

import SessionContext from '../context/session';

const PURCHASE_TYPES_QUERY = gql`
    query purchaseTypes {
        purchaseTypes {
            id
            category
            export
            markup
            isCustomer
        }
    }
`;

export default function usePurchaseTypes(constrainToCustomer) {
  const {user} = useContext(SessionContext);
  const {data, loading, error} = useQuery(PURCHASE_TYPES_QUERY);

  if (error) {
    console.error(error);
    throw error;
  }

  let {purchaseTypes} = data || {};
  if (constrainToCustomer && purchaseTypes && user?.canOnlyAddForOwnSchoolWithCustPTAfterEPD()) {
    purchaseTypes = purchaseTypes.filter(purchaseType => purchaseType.isCustomer);
  }
  return {purchaseTypes, purchaseTypesLoading: loading};
}
