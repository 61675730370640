import React, {useContext} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import QueryContext from '../../context/QueryContext';

import useSplitsExist from '../../hooks/useSplitsExist';

import SFMNotice from '../../components/SFMNotice';

/**
 * @return {null}
 */
export default function SplitPurchasesNotice() {
  const {query, handleUpdateQuery} = useContext(QueryContext);
  const {splitsCount} = useSplitsExist();

  const {page, resolveAliases, ...testQuery} = query;
  if (JSON.stringify({isSplit: true}) === JSON.stringify(testQuery)) {
    return null;
  }
  return (
    <Row className="flex-shrink-0">
      <Col sm={12}>
        <SFMNotice show={splitsCount} msg={`${splitsCount} split purchases to resolve`}
                   onClick={() => handleUpdateQuery({isSplit: true, page:1})}/>
      </Col>
    </Row>
  );
}
