import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

export default function Header() {
  return (
    <Container className="flex-shrink-0" fluid>
      <Row className="pt-1 align-items-center bg-light">
        <Col>
          <h4 className="fw-bold">Overlapping Time Reports</h4>
        </Col>
      </Row>
    </Container>
  );
}
