import {gql, useQuery} from '@apollo/client';

const DIVISIONS_QUERY = gql`
    query divisions {
        divisions {
            id
            name
        }
    }
`;

export const UNSPECIFIED_DIVISION = Object.freeze({id:null, name:'Not Specified'});

export default function useWorkOrderDivisions() {
  const {data, loading, error} = useQuery(DIVISIONS_QUERY);

  if (error) {
    console.error(error);
    throw error;
  }

  const {divisions} = data || {};
  return {divisions, divisionsLoading: loading};
}
