import React, {useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import SessionContext from '../context/session';
import useAuthenticate from '../hooks/useAuthenticate';
import useCurrentUser from '../hooks/useCurrentUser';
import useLogout from '../hooks/useLogout';

import User from '../model/user';
import MainView from './MainView';

function createUser(currentUser) {
  let result = null;
  if (currentUser) {
    result = new User(currentUser);
  }
  return result;
}

/**
 * @return {null}
 */
export default function Session() {
  const [authenticate, {authenticating, authenticationError}] = useAuthenticate();
  const {currentUser, userLoading, currentUserError}
    = useCurrentUser(authenticating || authenticationError);

  const [user, setUser] = useState(createUser(currentUser));
  const [logout] = useLogout();
  const location = useLocation();
  const [initialLocation, setInitialLocation]
    = useState(
    location?.pathname.startsWith('/app')
    ? location
    : null
  );

  useEffect(() => {
    setUser(() => createUser(currentUser));
  }, [currentUser]);

  useEffect(() => {
    if (userLoading) {
      return;
    }
    if (user) {
      setInitialLocation(() => null);
    }
  }, [userLoading, user, setInitialLocation]);

  const onEndSession = useCallback(async function _onEndSession() {
    await logout();
    setUser(null);
  }, [logout, setUser]);

  if (userLoading) {
    return null;
  }

  return (
    <SessionContext.Provider value={{
      user,
      authenticating,
      userLoading,
      authenticationError,
      currentUserError,
      isTeacher: user?.userType === 'teacher',
      isTech: user?.userType === 'tech',
      initialLocation,
      onAuthenticateUser: authenticate,
      onEndSession
    }}>
      <MainView/>
    </SessionContext.Provider>
  );
}
