import {useCallback} from 'react';
import {gql, useMutation} from '@apollo/client';

const LOGOUT_MUTATION = gql`
    mutation logout{
        logout{
            message
        }
    }
`;

export default function useLogout() {
  const [gqlLogout, {data, loading, error, client}] = useMutation(LOGOUT_MUTATION);

  if (error) {
    console.error(error);
  }

  const logout = useCallback(async () => {
    await gqlLogout();
    await client.clearStore();
  }, [gqlLogout, client]);

  const {message} = data || {};
  return [logout, {message, logoutLoading: loading}];
}
