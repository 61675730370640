import React, {useState} from 'react';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import {Document, Page, pdfjs} from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function ReceiptPDF({url, pageNumber, onDocumentLoaded, scale, rotate}) {
  rotate = rotate % 360;
  if (rotate < 0) {
    rotate = 360 + rotate;
  }
  if (Object.is(rotate, -0)) {
    rotate = 0;
  }

  return (
    <Document file={url} onLoadSuccess={onDocumentLoaded}>
      <Page scale={scale} rotate={rotate} pageNumber={pageNumber}/>
    </Document>
  );
}

export default function Item({src, onPDFLoaded, pageNumber, scale, rotate}) {
  const {pathname} = new URL(src);
  const [loaded, setLoaded] = useState(false);

  function onDocumentLoaded(pdfInfo) {
    onPDFLoaded(pdfInfo);
    setLoaded(true);
  }

  const content = pathname
                    .toLowerCase()
                    .endsWith('.pdf')
                  ? (<ReceiptPDF className="d-block w-100" scale={scale} rotate={rotate} url={src}
                                 pageNumber={pageNumber}
                                 onDocumentLoaded={onDocumentLoaded}/>)
                  : (<Image className="w-100" rel="prefetch" alt="receipt" src={src} fluid
                            style={{
                              transform: `rotate(${rotate}deg) scale(${scale})`,
                              transition: 'all .2s ease-in-out'
                            }}
                            onLoad={() => setLoaded(true)}/>);
  return (
    <div className="overflow-auto" style={{height: '75vh'}}>
      {content}
      {!loaded && (
        <div className="position-absolute d-flex justify-content-center align-items-center w-100 h-100">
          <Spinner size="lg" variant="light" animation="border" role="status"/>
        </div>
      )}
    </div>
  );
}
