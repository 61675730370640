import {useMemo} from 'react';
import {gql, useQuery} from '@apollo/client';
import moment from 'moment';

const EARLIEST_PAYROLL_DATE_QUERY = gql`
    query earliestPayrollDate {
        earliestPayrollDate {
            id
            name
            date
        }
    }
`;

export default function useEarliestPayrollDate() {
  const {data, loading, error} = useQuery(EARLIEST_PAYROLL_DATE_QUERY, {fetchPolicy: 'cache-and-network'});

  if (error) {
    throw error;
  }

  return useMemo(() => {
    let {earliestPayrollDate} = data || {};
    if (earliestPayrollDate) {
      earliestPayrollDate = moment(earliestPayrollDate.date, 'YYYY-MM-DD');
    }
    return {earliestPayrollDate, earliestPayrollDateLoading: loading};
  }, [data, loading]);
}