import {gql, useMutation} from '@apollo/client';

import vendorFragment from '../graphql/vendor-fragment';

const ADD_VENDOR_ALIAS = gql`
    mutation addVendorAlias( $id:Int!, $alias:String!){
        addVendorAlias(id:$id, alias:$alias){
            ...VendorFields
        }
    }
    ${vendorFragment}
`;

export default function useAddVendorAlias() {
  const [gqlAddVendorAlias, {data, loading, error}] = useMutation(ADD_VENDOR_ALIAS);

  if (error) {
    throw error;
  }

  function addVendorAlias(vendor, alias) {
    const {id} = vendor;
    return gqlAddVendorAlias({variables: {id, alias}});
  }

  const {addVendorAlias: alias} = data || {};
  return [addVendorAlias, {alias, addVendorAliasLoading: loading}];
}
