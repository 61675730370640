import {useContext} from 'react';
import {gql, useQuery} from '@apollo/client';

import SessionContext from '../context/session';

const GET_TECHNICIANS_BY_ID = gql`
    query technicians($ids:[Int!]!){
        technicians(ids:$ids){
            id
            lastName
            firstName
        }
    }
`;

export default function useTechnicians(ids) {
  const {user} = useContext(SessionContext);
  const {data, loading, error} = useQuery(GET_TECHNICIANS_BY_ID, {
    variables: {ids},
    skip: !user.canQueryTechnicians() || !ids?.length
  });

  if (error) {
    throw error;
  }

  const {technicians} = data || {};

  return {technicians, techniciansLoading: loading};
}
