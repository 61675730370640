import React from 'react';
import AsyncSelect from 'react-select/async';
import theme from '../lib/selectTheme';

function menuStyles(provided, state) {
  return {...provided, zIndex: 1000};
}

export default function ACMQuerySelect(
  {
    selected, defaultOptions, required = false, fetch, onSelect, renderLabel, cacheOptions = true,
    ...props
  }
) {

  function toOption(obj) {
    return {label: renderLabel(obj), value:obj.id, obj};
  }

  async function handleLoadOptions(input) {
    const inputFetch = await fetch(input);
    return inputFetch.map(toOption);
  }

  function handleOnSelect(option) {
    let {obj} = option || {};
    if (Array.isArray(option)) {
      obj = option.map(({obj}) => obj);
    }

    onSelect(obj);
  }

  const isSelectedArray = Array.isArray(selected);
  let value = null;
  if(isSelectedArray){
    value = selected.map(toOption);
  }
  else if (selected) {
    value = toOption(selected);
  }
  if (!defaultOptions && selected) {
    defaultOptions = [selected];
    if(isSelectedArray){
      defaultOptions = selected;
    }
  }

  const actualDefaultOptions = defaultOptions && defaultOptions.map(toOption);

  let validity = '';
  if (required) {
    validity = value ? 'is-valid' : 'is-invalid';
  }

  return (
    <AsyncSelect classNamePrefix="sfm-select" className={`sfm-select sfm-${validity}`} theme={theme}
                 cacheOptions={cacheOptions} styles={{menu: menuStyles}} defaultOptions={actualDefaultOptions}
                 loadOptions={handleLoadOptions} value={value} onChange={handleOnSelect}
                 noOptionsMessage={() => 'Type to Search...'} {...props}/>
  );
}
