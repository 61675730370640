import React from 'react';

import ACMQuerySelect from './ACMQuerySelect';

export default function LookupByName(
  {placeholder = 'Select Technician', selected, disabled, fetch, onSelect, showInactive, ...props}
) {

  function handleRenderLabel({lastName, firstName, status}) {
    let result = `${lastName}, ${firstName}`;
    if ('Inactive' === status) {
      result = `${result} (${status})`;
    }
    return result;
  }

  return (
    <ACMQuerySelect placeholder={placeholder} selected={selected} fetch={fetch}
                    isDisabled={disabled} onSelect={onSelect} renderLabel={handleRenderLabel}
                    cacheOptions={showInactive ? 2 : 1} {...props}/>
  );
}
