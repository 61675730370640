import React from 'react';
import Modal from 'react-bootstrap/Modal';

export default function AreYouSure({title, message, buttons, show}){
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title><h4 className="fw-bold">{title}</h4></Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        {buttons}
      </Modal.Footer>
    </Modal>
  );
}
