import React from 'react';
import Table from 'react-bootstrap/Table';

import USCurrency from '../USCurrency';

export default function Costs({laborCosts, materialCosts, customerMaterialCosts}) {
  const total = [laborCosts, materialCosts]
    .filter(v => !!v)
    .reduce((s, i) => s + i, 0);
  return (
    <Table size="sm" bordered responsive>
      <thead className="bg-secondary">
      <tr>
        <th>Customer Purchases</th>
        <th>SFM Purchases</th>
        <th>Labor Costs</th>
        <th>Total Costs</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td><USCurrency amount={customerMaterialCosts}/></td>
        <td><USCurrency amount={materialCosts}/></td>
        <td><USCurrency amount={laborCosts}/></td>
        <td><USCurrency amount={total}/></td>
      </tr>
      </tbody>
    </Table>
  );
}