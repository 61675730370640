import React from 'react';

import usePurchaseTypes from '../hooks/usePurchaseTypes';

import SFMItemSelect from './SFMItemSelect';
import {DefaultIcon, PURCHASE_TYPE_ICON} from './PurchaseTypeIcons';

function renderItemIcon(data) {
  const Icon = PURCHASE_TYPE_ICON.get(data.id) || DefaultIcon;
  return <Icon size="lg"/>;
}

function renderItemText(data) {
  return data.category;
}

export default function PurchaseTypePicker({type, disabled, onTypeSelect, constrainToCustomer, ...props}) {
  const {purchaseTypes, purchaseTypesLoading} = usePurchaseTypes(constrainToCustomer);
  return (
    <SFMItemSelect placeholder="Type" isSearchable={false} value={type} options={purchaseTypes}
                   isLoading={purchaseTypesLoading} isDisabled={disabled} onChange={onTypeSelect} required
                   renderItemText={renderItemText} renderItemIcon={renderItemIcon}{...props}
    />
  );
}
