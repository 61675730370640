import React, {useCallback, useContext, useMemo, useRef} from 'react';
import moment from 'moment';

import {
  FloatingArrow,
  FloatingPortal,
  arrow,
  autoPlacement,
  autoUpdate,
  offset,
  shift,
  useFloating,
  useRole,
  useInteractions
} from '@floating-ui/react';

import theme from '../lib/selectTheme';
import TimeSheetsContext from '../context/TimeSheetsContext';

import SFMTimesheetCard from './SFMTimesheetCard';

export default function SFMTimesheetEventWrapper(props) {
  const {
    selectedTimeSheet, setTimeSheetUnderEdit, setTimeSheetToDelete, earliestPayrollDate, popupPortalRef
  } = useContext(TimeSheetsContext);
  const {children, event: timeSheet} = props;
  const selected = useMemo(() => timeSheet.id === selectedTimeSheet?.id, [timeSheet, selectedTimeSheet]);
  const arrowRef = useRef(null);
  const {refs, floatingStyles, context} = useFloating({
    whileElementsMounted: autoUpdate,
    open: selected,
    middleware: [offset(8), autoPlacement(), shift(), arrow({element: arrowRef})]
  });
  const role = useRole(context);
  const {getReferenceProps, getFloatingProps} = useInteractions([role]);

  const setPopoverRef = useCallback((node) => {
    if (!node) {
      return;
    }
    refs.setReference(node.querySelector('.rbc-event'));
  }, [refs]);

  const handleEdit = useCallback(
    () => setTimeSheetUnderEdit(timeSheet),
    [timeSheet, setTimeSheetUnderEdit]);

  const handleDelete = useCallback(
    () => setTimeSheetToDelete(timeSheet),
    [timeSheet, setTimeSheetToDelete]
  );

  const disabled = useMemo(
    () => moment(timeSheet.clockIn).isBefore(earliestPayrollDate),
    [earliestPayrollDate, timeSheet]);

  const colors = useMemo(() => {
    return theme({}).colors;
  }, []);

  return (
    <div ref={setPopoverRef} id={`sfm-timesheet-wrapper-${timeSheet.id}`} {...getReferenceProps()}>
         {children}
           {
             selected &&
             (
               <FloatingPortal root={popupPortalRef}>
                 <div ref={refs.setFloating} className="sfm-time-sheet-popover" style={floatingStyles}
                      {...getFloatingProps()}>
                   <FloatingArrow ref={arrowRef} context={context} fill={colors.primary25}/>
                   <SFMTimesheetCard timeSheet={timeSheet} onEdit={handleEdit} onDelete={handleDelete}
                                     disabled={disabled}/>
                 </div>
               </FloatingPortal>
             )
           }
    </div>
  );
}
