import {gql} from '@apollo/client';

export default gql`
  fragment VendorFields on Vendor{
      id
      name
      isAlias
      resolvesTo{
          id
          name
      }
      aliases{
          id
          name
      }
  }
`;
