import {gql, useMutation} from '@apollo/client';

const RESET_PASSWORD_LINK = gql`
    mutation resetPasswordLink($email:String!){
        resetPasswordLink(email:$email){
            message
        }
    }
`;

export default function useResetPasswordRequest() {
  const [gqlResetPassword, {data, loading, error}] = useMutation(RESET_PASSWORD_LINK);

  if (error && !(data && data.length)) {
    throw error;
  }

  function resetPassword(email) {
    return gqlResetPassword({variables: {email}});
  }

  const resetPasswordRequestSuccess = !!data;
  return [resetPassword, {resetPasswordRequestSuccess, resetPasswordRequestLoading: loading}];
}
