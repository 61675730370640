import React from 'react';
import Form from 'react-bootstrap/Form';

import FormGroup from '../FormGroup';

const style = {borderColor: '#333'};

export default function NotesFormGroup(
  {notes, disabled, vendorNotListed, vendorInNotes, onNotesChange, onVendorInNotesChange, ...props}
) {
  let feedback = 'Notes are required';
  if (notes && vendorNotListed) {
    feedback = 'You must affirm that the vendor name is included in the notes';
  }

  const validity = (!vendorNotListed || vendorInNotes)
    ? 'sfm-is-valid'
    : 'sfm-is-invalid';
  return (
    <FormGroup controlId="notes" label="Notes" {...props}>
      <Form.Control as="textarea" style={style} maxLength={200} value={notes || ''}
                    disabled={disabled} onChange={({target}) => onNotesChange(target.value)} required/>
      <Form.Check type="checkbox" id="vendor-listed-affirmation" label="The vendor name is in the notes"
                  className={validity} checked={vendorInNotes} disabled={!vendorNotListed} required={vendorNotListed}
                  onChange={({target}) => onVendorInNotesChange(target.checked)}/>
      <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
    </FormGroup>
  );
}
