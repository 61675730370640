import React, {useContext} from 'react';
import Form from 'react-bootstrap/Form';

import LightboxContext from '../../context/LightboxContext';

import PurchaseTableRow from '../../components/PurchaseTableRow';
import VendorSelectCreate from './VendorSelectCreate';

export default function Purchases({purchases, showVendor, onUpdatePurchase, onDelete}) {
  const {setLightboxImages} = useContext(LightboxContext);

  return !!purchases && purchases.map(purchase => {
    const {id, vendor} = purchase;
    const vendorCell = showVendor && (
      <VendorCell vendor={vendor}
                  onSelectVendor={vendor => onUpdatePurchase(purchase, vendor)}/>
    );
    return (
      <PurchaseTableRow key={id} purchase={purchase} vendorCell={vendorCell}
                        onImageClick={updatedImages => setLightboxImages(updatedImages)}
                        onDelete={onDelete} showTech/>
    );
  });
}

function VendorCell({vendor, onSelectVendor}) {
  return (
    <td>
      <Form>
        <VendorSelectCreate vendor={vendor} onVendorSelect={onSelectVendor}/>
      </Form>
    </td>
  );
}
