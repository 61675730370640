import {gql, useLazyQuery} from '@apollo/client';

import {useEffect, useState} from 'react';

const TASKS_QUERY = gql`
    query fetchTasksForSchool($schoolID:Int!, $maxClosedAge:Int){
        tasksConnection(schoolID:$schoolID, maxClosedAge: $maxClosedAge)
        @connection(key:"tasks", filter:["schoolID", "maxClosedAge"])
        {
            workOrderTasks{
                id
                techID
                completeDate
                description
                note
                isComplete
                technician {
                    id
                    lastName
                    firstName
                }
                assignment{
                    id
                    closeDate
                    workOrder{
                        id
                        vNumber
                        abbreviation
                        number
                    }
                }
            }
        }
    }
`;

export default function useSchoolTasks(maxClosedAge) {
  const [fetchTasks, {data, loading, error}] = useLazyQuery(TASKS_QUERY);
  const [taskClient, setTaskClient] = useState(null);

  useEffect(() => {
    if (taskClient) {
      let variables = {schoolID: taskClient.id};
      if(maxClosedAge){
        variables = {...variables, maxClosedAge}
      }
      fetchTasks({variables});
    }
  }, [taskClient, maxClosedAge, fetchTasks]);

  if (error) {
    console.error(error);
    throw error;
  }

  let tasks = null;
  if (data) {
    ({workOrderTasks: tasks} = data.tasksConnection);
  }

  return {setTaskClient, tasks, tasksLoading: loading};
}
