import React from 'react';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import CenteredLabel from './CenteredLabel';

export default function NullableBooleanToggleButtonGroup(
  {variant = 'outline-primary', name, trueLabel, falseLabel, nullLabel, value, onChange}
) {

  let derivedValue = 'null';
  if ('boolean' === typeof value) {
    derivedValue = value;
  }

  function handleChange(value) {
    let exposedValue = value;
    if (value === 'null') {
      exposedValue = null;
    }

    onChange(exposedValue);
  }

  return (
    <ToggleButtonGroup size="sm" name={name} type="radio" value={derivedValue} onChange={handleChange}>
      <ToggleButton id={`${name}-true`} variant={variant} value={true}>
        <CenteredLabel label={trueLabel}/>
      </ToggleButton>
      <ToggleButton id={`${name}-false`} variant={variant} value={false}>
        <CenteredLabel label={falseLabel}/>
      </ToggleButton>
      <ToggleButton id={`${name}-null`} variant={variant} value={'null'}>
        <CenteredLabel label={nullLabel}/>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}