import {useContext} from 'react';
import {gql, useQuery} from '@apollo/client';

import SessionContext from '../context/session';

const GET_WORK_ORDER_TASKS = gql`
    query tasks($workOrderID:Int!){
        tasks(workOrderID: $workOrderID){
            id
            techRate
            description
            note
            duration
            beginDate
            completeDate
            actualDuration
            isComplete
            technician{
                id
                lastName
                firstName
            }
            timeSheets{
                id
                clockIn
                clockOut
                totalTime
                notes
                technician {
                    id
                    lastName
                    firstName
                }
                job{
                    id
                    name
                }
            }
        }
    }
`;

export default function useTasks(workOrderID) {
  const {user} = useContext(SessionContext);
  let skip = !workOrderID || !user?.canQueryTasks();
  const {data, loading, error, refetch} = useQuery(GET_WORK_ORDER_TASKS,
    {variables: {workOrderID}, fetchPolicy: 'cache-and-network', skip});

  if (error && !(data?.length)) {
    throw error;
  }

  const {tasks} = data || {};

  return {tasks, tasksLoading: loading, refetchTasks: refetch};
}