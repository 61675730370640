import React from 'react';

import useWorkOrderTypes, {UNSPECIFIED_TYPE} from '../hooks/useWorkOrderTypes';
import SFMItemSelect from './SFMItemSelect';

export default function WorkOrderTypePicker({type, disabled, onTypeSelect, ...props}) {
  const {workOrderTypes, workOrderTypesLoading} = useWorkOrderTypes();

  let vType = type;
  if (('undefined' === typeof type) && workOrderTypes?.length) {
    vType = workOrderTypes[0];
  }
  const options = workOrderTypes && [...workOrderTypes, UNSPECIFIED_TYPE];
  return (
    <SFMItemSelect value={vType || UNSPECIFIED_TYPE} options={options} placeholder="Type"
                   isSearchable={false} isLoading={workOrderTypesLoading} isDisabled={disabled} onChange={onTypeSelect}
                   required {...props}/>
  );
}
