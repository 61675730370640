import {useCallback, useEffect, useState} from 'react';

import usePayTypes, {
  VACATION_SALARY_PAY_TYPE_ALT_ID,
  VACATION_HOURLY_PAY_TYPE_ALT_ID,
  UNPAID_PAY_TYPE_ALT_ID,
  HOLIDAY_SALARY_PAY_TYPE_ALT_ID,
  HOLIDAY_HOURLY_PAY_TYPE_ALT_ID,
  SALARY_PAY_TYPE_ALT_ID,
  REGULAR_HOURLY_PAY_TYPE_ALT_ID
} from './usePayTypes';
import useSchoolJobs from './useSchoolJobs';

const PAID_TIME_OFF_JOB_ID = 152;
const UNPAID_JOB_ID = 244;
const HOLIDAY_JOB_ID = 191;

function find(array, key, value) {
  return array.find(i => i[key] === value);
}

const SPECIAL_PAY_TYPE_KEYS = [
  VACATION_SALARY_PAY_TYPE_ALT_ID, VACATION_HOURLY_PAY_TYPE_ALT_ID, UNPAID_PAY_TYPE_ALT_ID,
  HOLIDAY_SALARY_PAY_TYPE_ALT_ID, HOLIDAY_HOURLY_PAY_TYPE_ALT_ID
];

const STANDARD_PAY_FILTER = ({altID}) => [SALARY_PAY_TYPE_ALT_ID, REGULAR_HOURLY_PAY_TYPE_ALT_ID].includes(altID);

export default function usePayTypeSchoolAndJobSelections({payType, syncPayType, school, job, syncJob}) {
  const {payTypes} = usePayTypes();
  const {setJobClient, jobs} = useSchoolJobs();

  const [payrollJobMap, setPayrollJobMap] = useState(new Map([]));
  const [jobPayrollMap, setJobPayrollMap] = useState(new Map([]));

  useEffect(() => {
    school && setJobClient(school);
  }, [school, setJobClient]);

  useEffect(() => {
    if (!(payTypes && jobs)) {
      return;
    }

    setPayrollJobMap(
      new Map([
        [find(payTypes, 'altID', VACATION_SALARY_PAY_TYPE_ALT_ID), find(jobs, 'id', PAID_TIME_OFF_JOB_ID)],
        [find(payTypes, 'altID', VACATION_HOURLY_PAY_TYPE_ALT_ID), find(jobs, 'id', PAID_TIME_OFF_JOB_ID)],
        [find(payTypes, 'altID', UNPAID_PAY_TYPE_ALT_ID), find(jobs, 'id', UNPAID_JOB_ID)],
        [find(payTypes, 'altID', HOLIDAY_SALARY_PAY_TYPE_ALT_ID), find(jobs, 'id', HOLIDAY_JOB_ID)],
        [find(payTypes, 'altID', HOLIDAY_HOURLY_PAY_TYPE_ALT_ID), find(jobs, 'id', HOLIDAY_JOB_ID)]
      ])
    );

    setJobPayrollMap(
      new Map([
        [
          find(jobs, 'id', PAID_TIME_OFF_JOB_ID),
          payTypes.find(({altID}) => [VACATION_SALARY_PAY_TYPE_ALT_ID, VACATION_HOURLY_PAY_TYPE_ALT_ID].includes(altID))
        ],
        [find(jobs, 'id', UNPAID_JOB_ID), find(payTypes, 'altID', UNPAID_PAY_TYPE_ALT_ID)],
        [
          find(jobs, 'id', HOLIDAY_JOB_ID),
          payTypes.find(({altID}) => [HOLIDAY_SALARY_PAY_TYPE_ALT_ID, HOLIDAY_HOURLY_PAY_TYPE_ALT_ID].includes(altID))
        ]
      ])
    );

  }, [payTypes, jobs]);

  const setPayType = useCallback(payType => {
    syncPayType(payType);
    syncJob(payrollJobMap?.get(payType) || job || (jobs && jobs[0]));
  }, [syncPayType, syncJob, payrollJobMap, job, jobs]);

  useEffect(() => {
    if (payTypes && !payType) {
      setPayType(payTypes.find(STANDARD_PAY_FILTER));
    }
  }, [payTypes, payType, setPayType]);

  const setJob = useCallback(job => {
    syncJob(job);
    let newPayType = jobPayrollMap?.get(job);
    if (!newPayType && payType && SPECIAL_PAY_TYPE_KEYS.includes(payType.altID)) {
      newPayType = payTypes?.find(STANDARD_PAY_FILTER);
    }
    syncPayType(newPayType || payType);
  }, [syncJob, syncPayType, jobPayrollMap, payTypes, payType]);

  return {payTypes, setPayType, jobs, setJob};
}