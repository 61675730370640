import {useCallback} from 'react';
import {gql, useLazyQuery} from '@apollo/client';

import purchaseStatsFragment from '../graphql/purchase-stats-fragment';
import vendorFragment from '../graphql/vendor-fragment';

const VENDOR_QUERY = gql`
    query getVendor($id:Int!){
        getVendor(id:$id){
            ...VendorFields
            purchaseStats{
                ...PurchaseStatsFields
            }
        }
    }
    ${purchaseStatsFragment}
    ${vendorFragment}
`;

export default function useGetVendor() {
  const [gqlGetVendor, {loading, data, error}] = useLazyQuery(VENDOR_QUERY);
  const getVendor = useCallback(id => gqlGetVendor({variables: {id}}), [gqlGetVendor]);

  if (error && !(data && data.length)) {
    throw error;
  }

  const {getVendor: vendor} = data || {};

  return [getVendor, {vendor, vendorLoading: loading}];
}
