import React from 'react';
import Form from 'react-bootstrap/Form';

import WorkOrderTypePicker from '../WorkOrderTypePicker';
import FormGroup from '../FormGroup';

export default function WorkOrderTypeFormGroup({workOrderType, disabled, onWorkOrderTypeChange, ...props}) {
  return (
    <FormGroup controlId="workOrderType" label="Type" {...props}>
      <WorkOrderTypePicker type={workOrderType} disabled={disabled} onTypeSelect={onWorkOrderTypeChange}/>
      <Form.Control.Feedback type="invalid">
        WorkOrder Type is required
      </Form.Control.Feedback>
    </FormGroup>
  );
}
