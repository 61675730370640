import {useCallback, useMemo} from 'react';
import {gql, useMutation} from '@apollo/client';
import moment from 'moment';

const UPDATE_TIMESHEET_MUTATION = gql`
    mutation updateTimeSheet(
        $id:Int!, $clockIn:DateTime!, $clockOut:DateTime!, $notes:String, $taskClosed:TaskClosed!,
        $payrollItemID:Int!, $jobID:Int!, $taskID:Int
    ) {
        utcUpdateTimeSheet(
            id: $id, clockIn: $clockIn, clockOut: $clockOut, notes:$notes, taskClosed: $taskClosed,
            payrollItemID: $payrollItemID, jobID: $jobID, taskID: $taskID
        ){
            ... on TimeSheet {
                id
                clockIn
                clockOut
                totalTime
                notes
                technician {
                    id
                }
                task {
                    id
                    description
                    assignment {
                        id
                        workOrder {
                            id
                            vNumber
                        }
                    }
                    technician {
                        id
                        lastName
                        firstName
                    }
                }
                job {
                    id
                    name
                    school {
                        id
                        name
                        code
                    }
                }
                payType {
                    id
                    altID
                    description
                    multiplier
                }
            }

            ... on ErrorResponse {
                error
            }
        }
    }
`;

export default function useUpdateTimeSheet(options) {
  const [gqlUpdateTimeSheet, mutationResponse] = useMutation(UPDATE_TIMESHEET_MUTATION, options);

  const updateTimeSheet = useCallback(function _updateTimeSheet(timeSheet) {
    const {id, clockIn: pClockIn, clockOut: pClockOut, notes, taskClosed, payType, job, task} = timeSheet;
    const [clockIn, clockOut] = [pClockIn, pClockOut]
      .map(m => moment(m).toISOString());
    const variables = {
      id, clockIn, clockOut, notes, taskClosed, payrollItemID: payType.altID, jobID: job.id, taskID: task?.id
    };
    return gqlUpdateTimeSheet({variables});
  }, [gqlUpdateTimeSheet]);

  return useMemo(() => {
    const {data, loading, error} = mutationResponse;
    const response = data?.utcUpdateTimeSheet || {};
    return [
      updateTimeSheet,
      {updateTimeSheetResponse: response, timeSheetUpdating: loading, updateTimeSheetError: error}
    ];
  }, [updateTimeSheet, mutationResponse]);
}