import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

export default function LoginForm({onSubmit}) {
  const [disabled, setDisabled] = useState(true);

  function handleChange({target: {form}}) {
    const {usernameInput: {value: username}, passwordInput: {value: password}} = form.elements;

    setDisabled(!username.length && !password.length);
  }

  function handleSubmit(event) {
    event.preventDefault();

    const {usernameInput: {value: username}, passwordInput: {value: password}} = event.target.elements;
    onSubmit({username, password});
  }

  return (
    <div className="p-4">
      <h4 className="fw-bold">Sign In</h4>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="usernameInput">
          <LoginLabel>USERNAME</LoginLabel>
          <Form.Control placeholder="Enter your user name" as="input" type="text"
                        onChange={handleChange}/>
        </Form.Group>
        <Form.Group controlId="passwordInput">
          <LoginLabel>PASSWORD</LoginLabel>
          <Form.Control placeholder="Enter your password" as="input" type="password"
                        onChange={handleChange}/>
        </Form.Group>
        <div className="mt-3 d-grid">
          <Button type="submit" size="lg" disabled={disabled}>Sign In</Button>
        </div>
      </Form>
    </div>
  );
}

function LoginLabel({className, ...rest}) {
  return (
    <Form.Label {...rest} className={`text-muted fw-bold ${className}`} style={{fontSize: '12px'}}/>
  );
}
