import {gql, useQuery} from '@apollo/client';

const GET_USERS_BY_ID = gql`
    query users($ids:[Int!]!){
        users(ids:$ids){
            id
            lastName
            firstName
        }
    }
`;

export default function useUsers(ids) {
  const {data, loading, error} = useQuery(GET_USERS_BY_ID, {variables: {ids}, skip: !ids?.length});

  if (error && !data?.length) {
    throw error;
  }

  const {users} = data || {};

  return {users, usersLoading: loading};
}