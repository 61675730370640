function getExcludeKeys(user) {
  const results = [];
  if (!user.canQueryOtherThanOwnPurchases()) {
    results.push('banks', 'bankIDs');
  }
  if (!user.canQuerySchools()) {
    results.push('clients', 'clientIDs');
  }

  return results;
}

export function collectionMemberMapper(collection) {
  return id => (collection || [])
    .find(c =>
      c.id === id
      || (Number.isNaN(id) && !c.id)
    );
}

export function sterilizeQuery(user, filter) {
  const exclude = getExcludeKeys(user);

  return Object.keys(filter)
    .reduce((reduction, key) => {
      if (exclude.includes(key) && typeof (reduction[key]) !== 'undefined') {
        delete reduction[key];
      }
      return reduction;
    }, filter);
}

export const EMPTY_QUERY = JSON.stringify({});
