import React, {useContext} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import SessionContext from '../context/session';
import QueryContext from '../context/QueryContext';

import usePurchaseTypes from '../hooks/usePurchaseTypes';

import FormGroup from './FormGroup';
import ACMDateRangePicker from './ACMDateRangePicker';
import BankPicker from './BankPicker';
import ClientLookup from './ClientLookup';
import JobList from './JobList';
import JobSearch from './JobSearch';
import OwnSchoolJobList from './OwnSchoolJobList';
import {SFM_CC_PURCHASE_TYPE_ID} from './PurchaseTypeIcons';
import PurchaseTypePicker from './PurchaseTypePicker';
import TechnicianFormGroup from './TechnicianFormGroup';
import NullableBooleanToggleButtonGroup from './NullableBooleanToggleButtonGroup';
import WorkOrderSearch from './WorkOrderSearch';

//YELLOWTAG:TJH - large component - needs to be broken up
export default function PurchaseFilterForm() {
  const {user} = useContext(SessionContext);
  const {query, handleUpdateQuery} = useContext(QueryContext);
  const {purchaseTypes: allPurchaseTypes} = usePurchaseTypes();

  const {
    startDate, endDate, isSplit, resolveAliases, isReturn, clientIDs = [], purchaseTypeIDs = [], bankIDs = [],
    technicianIDs = [], jobIDs = [], workOrderIDs = []
  } = query;

  function handleDatesChange(newDates) {
    handleUpdateQuery({...query, ...newDates, page: 1});
  }

  function handleAddClient({id}) {
    if (!clientIDs.includes(id)) {
      handleUpdateQuery({...query, clientIDs: [...clientIDs, id], page: 1});
    }
  }

  function handleAddPurchaseType({id}) {
    if (!purchaseTypeIDs.includes(id)) {
      handleUpdateQuery({...query, purchaseTypeIDs: [...purchaseTypeIDs, id], page: 1});
    }
  }

  function handleIsSplitChange(isSplit) {
    handleUpdateQuery({...query, isSplit, page: 1});
  }

  function handleIsReturnChange(isReturn) {
    handleUpdateQuery({...query, isReturn, page:1});
  }


  function handleResolveAliasesChange({target}) {
    handleUpdateQuery({...query, resolveAliases: target.checked, page:1});
  }

  function handleAddBank({id}) {
    if (!bankIDs.includes(id)) {
      const newFilter = {...query, bankIDs: [...bankIDs, id], page:1};
      if (!purchaseTypeIDs.find(id => id === SFM_CC_PURCHASE_TYPE_ID)) {
        newFilter.purchaseTypeIDs = [
          ...purchaseTypeIDs,
          allPurchaseTypes.find(({id}) => id === SFM_CC_PURCHASE_TYPE_ID)?.id
        ];
      }
      handleUpdateQuery(newFilter);
    }
  }

  function handleAddTechnician({id}) {
    if (!technicianIDs.includes(id)) {
      handleUpdateQuery({...query, technicianIDs: [...technicianIDs, id], page:1});
    }
  }

  function handleAddJob(job) {
    if (job && !jobIDs.find(id => id === job.id)) {
      handleUpdateQuery({...query, jobIDs: [...jobIDs, job.id]});
    }
  }

  function handleAddWorkOrder(workOrder) {
    handleUpdateQuery({...query, workOrderIDs: [...workOrderIDs, workOrder.id], page:1});
  }

  return (
    <Form>
      <Row className="align-items-start">
        <FormGroup controlId="date" label="Date Range" sm={3}>
          <InputGroup>
            <ACMDateRangePicker startDate={startDate} endDate={endDate} onDatesChange={handleDatesChange}/>
          </InputGroup>
        </FormGroup>
        {
          !user.canOnlyQueryJobsByOwnSchool() && (
            <FormGroup controlId="client" label="Client" sm={3}>
              <ClientLookup onClientSelect={handleAddClient} controlShouldRenderValue={false}/>
            </FormGroup>
          )
        }
        {
          user.canQueryAllJobs() &&
          <FormGroup controlId="jobs" label="Jobs" sm={3}>
            <JobSearch onSelectJob={handleAddJob}/>
          </FormGroup>
        }
        <FormGroup controlId="workOrders" label="Work Order" sm={3}>
          <WorkOrderSearch onSelectWorkOrder={handleAddWorkOrder}/>
        </FormGroup>
        {
          !user.canQueryAllJobs() &&
          <FormGroup controlId="jobs" label="Jobs" sm={3}>
            <JobList onSelectJob={handleAddJob}/>
          </FormGroup>
        }
        {
          user.canOnlyQueryOwnPurchases()
          && user.canOnlyQueryJobsByOwnSchool()
          &&
          <FormGroup controlId="jobs" label="Jobs" sm={3}>
            <OwnSchoolJobList onSelectJob={handleAddJob}/>
          </FormGroup>
        }
        <FormGroup controlId="purchaseTypes" label="Purchase Types" sm={3}>
          <PurchaseTypePicker value={null} onTypeSelect={handleAddPurchaseType} controlShouldRenderValue={false}/>
        </FormGroup>
        {
          user.canQueryAllPurchases() &&
          <FormGroup controlId="bank" label="Bank" sm={3}>
            <BankPicker onBankSelect={handleAddBank}/>
          </FormGroup>
        }
        {
          user.canQueryTechnicians() &&
          <TechnicianFormGroup sm={3} technician={null} onSelectTechnician={handleAddTechnician}/>
        }
        <Col sm={3}>
          <Col className="mb-1" sm={12}>&nbsp;</Col>
          <NullableBooleanToggleButtonGroup name="isSplit" trueLabel="Split" falseLabel="Not Split" nullLabel="Any"
                                            value={isSplit} onChange={handleIsSplitChange}/>
        </Col>
        <Col sm={3}>
          <Col className="mb-1" sm={12}>&nbsp;</Col>
          <NullableBooleanToggleButtonGroup name="isReturn" falseLabel="Purchase" trueLabel="Return" nullLabel="Any"
                                            value={isReturn} onChange={handleIsReturnChange}/>
        </Col>
        {
          user.canQueryAllPurchases() &&
          <Col sm={3}>
            <Col className="mb-1" sm={12}>&nbsp;</Col>
            <Form.Check type="switch" id="resolve-aliases" label="Resolve aliases" checked={resolveAliases}
                        onChange={handleResolveAliasesChange}/>
          </Col>
        }
      </Row>
    </Form>
  );
}
