import React from 'react';
import Button from 'react-bootstrap/Button';
import {useNavigate} from 'react-router-dom';

export default function RouterButton({to, onClick, staticContext, ...rest}) {
  const navigate = useNavigate();
  return (
    <Button
      {...rest}
      onClick={event => {
        onClick && onClick(event);
        to && navigate(to);
      }}>
    </Button>
  );
}
