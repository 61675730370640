import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function BulkDeleteDialog({show, selectedPurchases, onConfirm, onCancel}) {

  const lockedCount = selectedPurchases
    .filter(({isLocked}) => isLocked)
    .length;

  const isAre = lockedCount === 1 ? 'is' : 'are';
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>
          <h4 className="fw-bold">Bulk Delete?</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete {selectedPurchases.length} purchases ({lockedCount} {isAre} locked)?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onConfirm}>Delete</Button>
        <Button variant="outline-primary" onClick={onCancel}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}
