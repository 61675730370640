import React from 'react';

import useWorkOrderNumberSearch from '../hooks/useWorkOrderNumberSearch';
import ACMQuerySelect from './ACMQuerySelect';

export default function WorkOrderSearch({onSelectWorkOrder, ...props}) {
  const workOrderNumberSearch = useWorkOrderNumberSearch();

  function handleRenderLabel({vNumber}) {
    return `${vNumber}`;
  }

  return (
    <ACMQuerySelect placeholder="WO Number" selected={null} fetch={workOrderNumberSearch}
                    onSelect={onSelectWorkOrder} renderLabel={handleRenderLabel} cacheOptions={1} {...props}/>
  );
}