import {useCallback, useEffect, useState} from 'react';

export default function useTimer(shouldStartTimer, timeout = 500) {
  const [alarm, setAlarm] = useState(false);
  const [alarmRef, setAlarmRef] = useState(null);

  const clearAlarmTimeout = useCallback(() => {
    if (alarmRef) {
      clearTimeout(alarmRef);
      setAlarmRef(null);
    }
  }, [alarmRef, setAlarmRef]);

  useEffect(() => {
    if (shouldStartTimer) {
      if (!(alarm || alarmRef)) {
        const ref = setTimeout(() => {
          setAlarm(true);
          setAlarmRef(null);
        }, timeout);
        setAlarmRef(ref);
      }
    } else if (alarmRef || alarm) {
      clearTimeout(alarmRef);
      setAlarmRef(null);
      setAlarm(false);
    }

    return clearAlarmTimeout;
  }, [shouldStartTimer, alarm, setAlarm, alarmRef, setAlarmRef, timeout, clearAlarmTimeout]);

  return [alarm, clearAlarmTimeout, setAlarm];
}
