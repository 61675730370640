import React from 'react';
import Form from 'react-bootstrap/Form';

import FormGroup from '../FormGroup';
import CurrencyInputGroup from '../CurrencyInputGroup';

export default function AmountFormGroup({amount, disabled, onAmountChange, ...props}) {
  return (
    <FormGroup controlId="amount" label="Amount" {...props}>
      <CurrencyInputGroup amount={amount} disabled={disabled} onAmountChange={onAmountChange}
                          feedback={<Form.Control.Feedback type="invalid">Amount is required</Form.Control.Feedback>}/>
    </FormGroup>
  );
}
