import React, {useContext} from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';

import SessionContext from '../../context/session';
import QueryContext from '../../context/QueryContext';

import FormGroup from '../../components/FormGroup';
import TechnicianFormGroup from '../../components/TechnicianFormGroup';
import ACMDateRangePicker from '../../components/ACMDateRangePicker';

export default function LocationsFilterForm() {
  const {user} = useContext(SessionContext);
  const {query, handleUpdateQuery} = useContext(QueryContext);

  const {startDate, endDate, technicianIDs = []} = query;

  function handleAddTechnician({id}) {
    if (!technicianIDs.includes(id)) {
      handleUpdateQuery({...query, technicianIDs: [...technicianIDs, id], page:1});
    }
  }

  function handleDatesChange(newDates) {
    handleUpdateQuery({...query, ...newDates, page:1});
  }

  return (
    <Form>
      <Row className="align-items-start">
        {
          user.canQueryTechnicians() && (
            <TechnicianFormGroup sm={3} label="Technician" technician={null} onSelectTechnician={handleAddTechnician}/>
          )
        }
        <FormGroup controlId="dates" sm={3} label="Date">
          <InputGroup>
            <ACMDateRangePicker startDate={startDate} endDate={endDate} onDatesChange={handleDatesChange}/>
          </InputGroup>
        </FormGroup>
      </Row>
    </Form>
  );
}