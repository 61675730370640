import {useState} from 'react';
import {gql, useQuery} from '@apollo/client';

import PurchaseList from '../graphql/purchase-list';
import useColumnSort from './useColumnSort';
import usePagination from './usePagination';

const PAGE_SIZE = 10;
const PURCHASES_BY_VENDOR_QUERY = gql`
    query purchasesConnection($vendorID: Int, $pageSize: Int, $offset: Int, $orderBy:[OrderBy!]){
        purchasesConnection(vendorID: $vendorID, pageSize:$pageSize, offset:$offset, orderBy:$orderBy)
        @connection(key: "purchases" filter:["vendorID", "orderBy"])
        {
            next
            hasMore
            total
            purchases{
                ...PurchaseListPurchase
                technician{
                    id
                    firstName
                    lastName
                }
            }
        }
    }
    ${PurchaseList.fragments.purchase}
`;

export default function usePurchasesByVendor(vendorID, orderBy) {
  const {columnSortDirection, onSort} = useColumnSort(new Map([['date', 'DESC']]));
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const {offset} = usePagination(pageSize);
  const {loading, error, data, refetch} = useQuery(PURCHASES_BY_VENDOR_QUERY, {
    variables: {
      vendorID,
      orderBy: orderBy(columnSortDirection),
      pageSize,
      offset
    },
    fetchPolicy: 'cache-and-network'
  });

  if (error && !(data && data.length)) {
    throw error;
  }

  const {purchasesConnection} = data || {};

  return {
    vendorPurchasesConnection: purchasesConnection,
    vendorPurchasesLoading: loading,
    refetchVendorPurchases: refetch,
    pageSize,
    setPageSize,
    columnSortDirection,
    onSort
  };
}
