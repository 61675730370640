import React, {useContext} from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import WorkOrderContext from '../../context/WorkOrderContext';

import FormattedDate from '../FormattedDate';

import DivisionFormGroup from './DivisionFormGroup';
import WorkOrderStatusFormGroup from './WorkOrderStatusFormGroup';
import WorkOrderTypeFormGroup from './WorkOrderTypeFormGroup';
import PriorityFormGroup from './PriorityFormGroup';
import Requester from './Requester';

export default function DetailsCard({loading}) {
  const {
    workOrderType, division, status, priority, requester, date, setWorkOrderType, setDivision, setStatus, setPriority
  } = useContext(WorkOrderContext);

  return (
    <Card body>
      <Form.Group as={Col} controlId="details">
        <Row>
          <WorkOrderTypeFormGroup lg={3} workOrderType={workOrderType} onWorkOrderTypeChange={setWorkOrderType}
                                  disabled={loading}/>
          <DivisionFormGroup lg={3} division={division} onDivisionSelect={setDivision}/>
          <WorkOrderStatusFormGroup lg={3} status={status} onStatusChange={setStatus}/>
          <PriorityFormGroup lg={3} priority={priority} onSelectPriority={setPriority}/>
        </Row>
        {
          requester && (
            <>
              <hr/>
              <Row>
                <Form.Group as={Col} xl={5} lg={8} md={10} controlId="requester">
                  <Form.Label>Requester</Form.Label>
                  <Requester/>
                </Form.Group>
                <Form.Group as={Col} xl={7} lg={4} md={2} controlId="submittedDate">
                  <Form.Label>Submitted on</Form.Label>
                  <Row className="mx-1">
                    <FormattedDate date={date}/>
                  </Row>
                </Form.Group>
              </Row>
            </>
          )
        }
      </Form.Group>
    </Card>
  );
}