import React, {useContext} from 'react';

import QueryContext from '../context/QueryContext';
import SessionContext from '../context/session';

import {collectionMemberMapper} from '../lib/filterUtils';

import useWorkOrderDivisions, {UNSPECIFIED_DIVISION} from '../hooks/useDivisions';
import useSchools from '../hooks/useSchools';
import useTechnicians from '../hooks/useTechnicians';
import useUsers from '../hooks/useUsers';
import useWorkOrders from '../hooks/useWorkOrders';
import useWorkOrderStatuses from '../hooks/useWorkOrderStatuses';
import useWorkOrderTypes, {UNSPECIFIED_TYPE} from '../hooks/useWorkOrderTypes';

import ClientPills from './ClientPills';
import DateRangePill from './DateRangePill';
import FilterPills from './FilterPills';
import SFMFilterPill from './SFMFilterPill';
import TechnicianPills from './TechnicianPills';

export default function WorkOrderFilterPills() {
  const {query, handleUpdateQuery, handleClearQuery} = useContext(QueryContext);
  const {user} = useContext(SessionContext);

  const {
    clientIDs = [], statusIDs = [], typeIDs = [], divisionIDs = [], technicianIDs = [], requesterIDs = [],
    workOrderIDs = [], isAssigned, keywords, isTimeCharged, isAllTasksComplete
  } = query;

  const {schools: allClients} = useSchools();
  const {workOrderStatuses} = useWorkOrderStatuses();
  const {workOrderTypes} = useWorkOrderTypes();
  const {divisions: allDivisions} = useWorkOrderDivisions();
  const {technicians} = useTechnicians(technicianIDs);
  const {users: requesters} = useUsers(requesterIDs);
  const {workOrders} = useWorkOrders(workOrderIDs);

  const techniciansToDisplay = technicianIDs.includes(user.userID)
                               ? [...(technicians || []), {...user, id: user.userID}]
                               : technicians;

  function handleClearDateFilter() {
    handleUpdateQuery({...query, startDate: null, endDate: null, page: 1});
  }

  function handleRemoveCollectionMember(collectionKey, member) {
    const collection = query[collectionKey] || [];
    handleUpdateQuery({
      ...query,
      [collectionKey]: collection.filter(id =>
        !(
          id === member.id
          || (Number.isNaN(id) && !member.id)
        )
      ),
      page: 1
    });
  }

  function handleRemoveIsAssigned() {
    handleUpdateQuery({...query, isAssigned: undefined, page:1});
  }

  function handleRemoveKeywords() {
    handleUpdateQuery({...query, keywords: undefined, page:1});
  }

  function handleRemoveProgress() {
    handleUpdateQuery({...query, isAllTasksComplete: undefined, isTimeCharged: undefined, page:1});
  }

  const clients = clientIDs.map(collectionMemberMapper(allClients))
                           .filter(c => !!c);
  const statuses = statusIDs.map(collectionMemberMapper(workOrderStatuses))
                            .filter(c => !!c);
  const types = typeIDs
    .map(collectionMemberMapper(workOrderTypes && [...workOrderTypes, UNSPECIFIED_TYPE]))
    .filter(c => !!c);
  const divisions = divisionIDs
    .map(collectionMemberMapper(allDivisions && [...allDivisions, UNSPECIFIED_DIVISION]))
    .filter(c => !!c);
  const {startDate, endDate} = query;

  return (
    <FilterPills clearDisabled={false} onClearQuery={handleClearQuery}>
      {
        workOrders.map(workOrder => (
          <SFMFilterPill key={`work-order-${workOrder.id}`}
                         onRemove={() => handleRemoveCollectionMember('workOrderIDs', workOrder)}>
            {workOrder.vNumber}
          </SFMFilterPill>
        ))
      }
      <DateRangePill startDate={startDate} endDate={endDate} onClearDateFilter={handleClearDateFilter}/>
      <ClientPills clients={clients}
                   onRemoveClient={(client) => handleRemoveCollectionMember('clientIDs', client)}/>
      {
        statuses.map(status => (
          <SFMFilterPill key={`status-${status.id}`}
                         onRemove={() => handleRemoveCollectionMember('statusIDs', status)}>
            {status.name}
          </SFMFilterPill>
        ))
      }
      {
        types.map(type => (
          <SFMFilterPill key={`type-${type.id}`}
                         onRemove={() => handleRemoveCollectionMember('typeIDs', type)}>
            Type:{type.name}
          </SFMFilterPill>
        ))
      }
      {
        divisions.map(division => (
          <SFMFilterPill key={`division-${division.id}`}
                         onRemove={() => handleRemoveCollectionMember('divisionIDs', division)}>
            Division:{division.name}
          </SFMFilterPill>
        ))
      }
      {
        'boolean' === typeof isAssigned && (
          <SFMFilterPill key="assigned" onRemove={handleRemoveIsAssigned}>
            {isAssigned ? 'Assigned' : 'Not Assigned'}
          </SFMFilterPill>
        )
      }
      {
        requesters?.map(requester => (
          <SFMFilterPill key={`requester-${requester.id}`}
                         onRemove={() => handleRemoveCollectionMember('requesterIDs', requester)}>
            Requester:{`${requester.firstName} ${requester.lastName}`}
          </SFMFilterPill>
        ))
      }
      {
        keywords && (
          <SFMFilterPill key="keywords" onRemove={handleRemoveKeywords}>
            {keywords}
          </SFMFilterPill>
        )
      }
      {
        ('boolean' === typeof isTimeCharged
          || 'boolean' === typeof isAllTasksComplete)
        && (
          <SFMFilterPill key="progress" onRemove={handleRemoveProgress}>
            {isAllTasksComplete ? 'Tasks Complete' : 'Tasks Started'}
          </SFMFilterPill>
        )
      }
      <TechnicianPills technicians={techniciansToDisplay}
                       onRemoveTechnician={
                         (technician) => handleRemoveCollectionMember('technicianIDs', technician)
                       }/>
    </FilterPills>
  );
}