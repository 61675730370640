import React, {useEffect, useState} from 'react';

import {gql, useLazyQuery} from '@apollo/client';
import {faImage} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const FETCH_PURCHASE_IMAGES = gql`
    query purchaseImages($ids:[Int!]!){
        purchaseImages(ids:$ids){
            id
            uri
        }
    }
`;
/**
 * @return {null}
 */
export default function PurchaseImage({id, purchaseImages, onClick}) {

  const [fetchPurchaseImages, {data, error}] = useLazyQuery(FETCH_PURCHASE_IMAGES,
    {fetchPolicy: 'network-only'});
  const [callOnClick, setCallOnClick] = useState(false);

  useEffect(() => {
    if (data && callOnClick) {
      onClick(data.purchaseImages);
      setCallOnClick(false);
    }
  }, [onClick, data, callOnClick]);

  if (!purchaseImages.length) {
    return null;
  }

  if (error) {
    console.error(error);
    throw error;
  }

  function fetchImageUris() {
    fetchPurchaseImages({variables: {ids: purchaseImages.map(pi => pi.id)}});
    setCallOnClick(true);
  }

  return (
    <OverlayTrigger placement="auto" overlay={<Tooltip id={id}>View Purchase Images</Tooltip>} flip>
      <Button variant="outline-light" onClick={fetchImageUris}>
        <FontAwesomeIcon icon={faImage} size="lg" className="text-primary"/>
      </Button>
    </OverlayTrigger>
  );
}
