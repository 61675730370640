import React from 'react';

import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';

import SFMSelectBase from './SFMSelectBase';

function menu(base) {
  return {...base, width: 216, zIndex: 1000};
}

const style = {height: 290};

function MenuList({innerRef, innerProps, children}) {
  return (
    <ListGroup ref={innerRef} className="overflow-auto" style={style} variant="flush" {...innerProps}>
      {children}
    </ListGroup>
  );
}

function Option({innerRef, innerProps, data, getValue, isFocused, selectProps}) {
  const currentValue = getValue();
  const active = currentValue.length && currentValue[0] === data;
  let className = '';
  if (isFocused) {
    className = 'bg-secondary';
  }
  const icon = selectProps.renderItemIcon(data);
  const text = selectProps.renderItemText(data);
  return (
    <ListGroup.Item ref={innerRef} className={className} style={{cursor: 'default'}} active={active} {...innerProps}>
      {icon
        ? (
          <Row>
            <Col sm={2}>{icon}</Col>
            <Col sm={10}>{text}</Col>
          </Row>
        )
        : (
          <Row>
            <Col>{text}</Col>
          </Row>
        )}
    </ListGroup.Item>
  );
}

function SingleValue({innerRef, innerProps, data, getValue, selectProps}) {
  const icon = selectProps.renderItemIcon(data);
  const text = selectProps.renderItemText(data);
  return (
    <Row ref={innerRef} {...innerProps}>
      <Col>{icon || text}</Col>
    </Row>
  );
}

function renderItemName(item) {
  return item.name;
}

function renderNullIcon() {
  return null;
}

export default function SFMQuerySelect(props) {
  return (
    <SFMSelectBase styles={{menu}} components={{MenuList, Option, SingleValue}} renderItemText={renderItemName}
                   renderItemIcon={renderNullIcon} {...props}/>
  );
}
