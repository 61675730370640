import {
  BooleanParam,
  encodeQueryParams,
  NumberParam,
  NumericArrayParam,
  useQueryParams
} from 'use-query-params';
import queryString from 'query-string';

import {MomentParam} from './customQueryParams';

const PARAM_CONFIG_MAP = {
  startDate: MomentParam,
  endDate: MomentParam,
  clientIDs: NumericArrayParam,
  purchaseTypeIDs: NumericArrayParam,
  page: NumberParam,
  isSplit: BooleanParam,
  resolveAliases: BooleanParam,
  bankIDs: NumericArrayParam,
  technicianIDs: NumericArrayParam,
  isReturn: BooleanParam,
  jobIDs: NumericArrayParam,
  workOrderIDs: NumericArrayParam
};

export function toUrl(query, {pathname}) {
  const qs = queryString.stringify(encodeQueryParams(PARAM_CONFIG_MAP, query));
  return `${pathname}?${qs}`;
}

export default function usePurchaseListQueryParams() {
  return useQueryParams(PARAM_CONFIG_MAP);
}

export const BASE_QUERY = Object.freeze({
  page: 1,
  startDate: undefined,
  endDate: undefined,
  clientIDs: undefined,
  purchaseTypeIDs: undefined,
  bankIDs: undefined,
  technicianIDs: undefined,
  jobIDs: undefined,
  workOrderIDs: undefined,
  isSplit: undefined,
  resolveAliases: undefined,
  isReturn: undefined
});