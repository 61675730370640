import React from 'react';
import Container from 'react-bootstrap/Container';
import {useParams} from 'react-router-dom';

import usePurchase from '../../hooks/usePurchase';
import useTimer from '../../hooks/useTimer';

import QuerySpinner from '../../components/QuerySpinner';
import DefineSplitForm from './DefineSplitForm';
import Header from './Header';

/**
 * @return {null}
 */
export default function ManageSplitPurchases() {
  const {id} = useParams();
  const {purchase, purchaseLoading} = usePurchase(parseInt(id));
  const [alarm] = useTimer(purchaseLoading);

  if (alarm) {
    return <QuerySpinner/>;
  }
  if (purchaseLoading) {
    return null;
  }

  return (
    <Container fluid>
      <Header purchase={purchase}/>
      <DefineSplitForm purchase={purchase}/>
    </Container>
  );
}
