import React from 'react';
import {createFilter} from 'react-select';

import SFMSelectBase from './SFMSelectBase';

export default function ACMSelect({selected, values, disabled, renderLabel, onSelect, ...props}) {
  function toOption(value) {
    return {label: renderLabel(value), value};
  }

  function handleChange(option) {
    const {value} = option || {};
    onSelect(value);
  }

  const value = selected && toOption(selected);
  const options = values && values.map(toOption);
  const filterOption = createFilter({ignoreCase: true});
  return (
    <SFMSelectBase value={value} options={options} filterOption={filterOption} isDisabled={disabled || !values}
                   onChange={handleChange} {...props}/>
  );
}
