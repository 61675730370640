import React from 'react';
import Container from 'react-bootstrap/Container';

import useOverlappingTimes from '../../hooks/useOverlappingTimes';
import useColumnSort from '../../hooks/useColumnSort';

import OverlappingTimeTable, {overlappingTimeOrderBy} from '../../components/OverlappingTimeTable';
import ConnectionPagination from '../../components/ConnectionPagination';
import Header from './Header';

export default function OverlappingTimes() {
  const {columnSortDirection, onSort} = useColumnSort(
    new Map([
      ['periodFrom', 'DESC'],
      ['techType', 'ASC']
    ])
  );
  const {
    overlappingTimesConnection,
    overlappingTimesLoading,
    pageSize,
    setPageSize
  } = useOverlappingTimes(overlappingTimeOrderBy(columnSortDirection));

  const {overlappingTimes} = overlappingTimesConnection || {};

  return (
    <Container className="d-flex flex-column h-100" fluid>
      <Header/>
      <OverlappingTimeTable
        sortDirection={columnSortDirection}
        onSort={onSort}
        loading={overlappingTimesLoading}
        overlappingTimes={overlappingTimes}
      />
      {overlappingTimesConnection && (
        <ConnectionPagination
          connection={overlappingTimesConnection}
          pageSize={pageSize}
          onChangePageSize={setPageSize}
        />
      )}
    </Container>
  );
}
