import React, {useContext} from 'react';
import Table from 'react-bootstrap/Table';

import WorkOrderContext from '../../context/WorkOrderContext';

import useFilteredPurchases from '../../hooks/useFilteredPurchases';

import FormattedDate from '../FormattedDate';
import USCurrency from '../USCurrency';

const ORDER_BY = [{attribute:'id', direction:'ASC'}];
export default function PrintPurchaseTable() {
  const {id} = useContext(WorkOrderContext);
  const workOrderIDs = id && [id];
  const {purchasesConnection, purchasesLoading}
    = useFilteredPurchases({workOrderIDs}, ORDER_BY, 100);

  const {purchases} = purchasesConnection || {};
  if (purchasesLoading || !purchases?.length) {
    return null;
  }

  return (
    <Table size="sm" bordered>
      <thead className="bg-secondary page-break">
      <tr>
        <th className="text-center" colSpan="6">Purchases</th>
      </tr>
      <tr>
        <th>Technician</th>
        <th>Pay Type</th>
        <th>Date</th>
        <th>Amount</th>
        <th>Vendor</th>
        <th>Notes</th>
      </tr>
      </thead>
      <tbody>
      {
        purchases.map(({id, technician, purchaseType, date, amount, vendor, notes}) => (
          <tr key={`purchase-${id}`}>
            <td>{technician.firstName} {technician.lastName}</td>
            <td>{purchaseType.category}</td>
            <td><FormattedDate date={date}/></td>
            <td><USCurrency amount={amount}/></td>
            <td>{vendor?.name}</td>
            <td>{notes}</td>
          </tr>
        ))
      }
      </tbody>
    </Table>
  );
}