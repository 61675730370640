import {useCallback} from 'react';
import {gql, useLazyQuery} from '@apollo/client';

const PAGE_SIZE = 10000;

const FILTERED_TIME_SHEETS_QUERY = gql`
    query filteredTimeSheets(
        $dateTimeRange: QueryDateTimeRange
        $technicianIDs:[Int!]
    ) @connection(
        key: "timeSheets",
        filter:[ "dateTimeRange" "technicianIDs" ]
    ){
        utcTimeSheetsConnection(dateTimeRange:$dateTimeRange, technicianIDs: $technicianIDs, pageSize: ${PAGE_SIZE}, offset: 0){
            next
            hasMore
            total
            timeSheets {
                id
                clockIn
                clockOut
                totalTime
                notes
                autoDate
                areAllTasksComplete
                closed
                technician {
                    id
                    lastName
                    firstName
                }
                task{
                    id
                    description
                    note
                    isComplete
                    assignment {
                        id
                        workOrder {
                            id
                            vNumber
                        }
                        closeDate
                    }
                    technician {
                        id
                        lastName
                        firstName
                    }
                }
                job {
                    id
                    name
                    school {
                        id
                        name
                        code
                    }
                }
                payType {
                    id
                    altID
                    description
                    altDescription
                    multiplier
                }
            }
        }
    }
`;

export default function useFilteredTimeSheets(skip) {
  const [gqlFetchTimeSheets, {data, loading, error, refetch}] = useLazyQuery(FILTERED_TIME_SHEETS_QUERY);

  const fetchTimeSheets = useCallback((query) => {
    const {startDateTime, endDateTime, ...remainingQuery} = query;
    let dateTimeRange;
    if (startDateTime || endDateTime) {
      dateTimeRange = {
        startDateTime: startDateTime
          ?.utc()
          .format(),
        endDateTime: endDateTime
          ?.utc()
          .format()
      };
    }

    gqlFetchTimeSheets({
      skip,
      fetchPolicy: 'cache-and-network',
      variables: {...remainingQuery, dateTimeRange}
    });
  }, [gqlFetchTimeSheets, skip]);

  if (error && !data?.length) {
    throw error;
  }

  const {utcTimeSheetsConnection: timeSheetsConnection} = data || {};

  return [
    fetchTimeSheets,
    {
      timeSheetsConnection,
      timeSheetsLoading: loading,
      refetchTimeSheets: refetch
    }
  ];
}