import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function IconButton({id, overlayTip, icon, buttonSize, iconSize = 'lg',...props}) {
  return (
    <OverlayTrigger placement="auto" overlay={<Tooltip id={id}>{overlayTip}</Tooltip>} flip>
      <Button variant="outline-light" {...props}>
        <FontAwesomeIcon className="text-primary" size={iconSize} icon={icon}/>
      </Button>
    </OverlayTrigger>
  );
}
