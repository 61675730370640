import {createContext} from 'react';

const NO_OP = () => {};

export default createContext({
  query:{
    startDate: null,
    endDate: null
  },
  handleUpdateQuery: NO_OP,
  handleClearQuery: NO_OP
});