import {useContext} from 'react';
import {gql, useQuery} from '@apollo/client';

import SessionContext from '../context/session';

const JOBS_QUERY = gql`
    query listJobs{
        jobs{
            id
            name
            isSFMPurchaseAssignable
            school{
                id
                code
                name
            }
        }
    }
`;

export default function useJobList() {
  const {user} = useContext(SessionContext);
  const {data, loading, error} = useQuery(JOBS_QUERY, {
    skip: !user || user.canQueryAllJobs()
  });

  const {jobs} = data || {};

  return {jobs, jobsLoading: loading, jobsError: error};
}
