import {useContext, useMemo} from 'react';

import SessionContext from '../context/session';

export const MY_TASKS = 'myTasks';
export const ALL_TASKS = 'allTasks';
export const CLOSED_TASKS = 'closedTasks';

export default function useFilteredTasks(tasks, taskSetType) {
  const {user} = useContext(SessionContext);
  return useMemo(() => {
    const openTasks = tasks?.filter(t => !t.assignment.closeDate);
    let result = tasks;
    if (taskSetType === ALL_TASKS) {
      result = openTasks;
    }
    if (taskSetType === MY_TASKS) {
      result = openTasks?.filter(t => t.techID === user.userID);
    } else if (taskSetType === CLOSED_TASKS) {
      result = tasks?.filter(t => t.assignment.closeDate);
    }

    return result;

  }, [tasks, taskSetType, user]);
}