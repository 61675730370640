import React from 'react';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';

import IconButton from './IconButton';

export default function TrashButton({id, ...props}) {
  return (
    <IconButton id={id} overlayTip="Delete" icon={faTrashAlt} {...props}/>
  );
}
