import {gql, useMutation} from '@apollo/client';

import purchaseStatsFragment from '../graphql/purchase-stats-fragment';

const DELETE_VENDOR = gql`
    mutation deleteVendor(
        $id:Int!
        $assignToID:Int
    ){
        deleteVendor(
            id:$id
            assignToID:$assignToID
        ){
            id
            purchaseStats{
                ...PurchaseStatsFields
            }
        }
    }
    ${purchaseStatsFragment}
`;

export default function useDeleteVendor() {
  const [gqlDeleteVendor, {loading, data, error}] = useMutation(DELETE_VENDOR);

  if (error && !(data && data.length)) {
    throw error;
  }

  function deleteVendor(vendorToDelete, assignPurchasesTo) {
    const variables = {
      id: vendorToDelete.id,
      assignToID: (assignPurchasesTo || {}).id
    };
    return gqlDeleteVendor({variables});
  }

  const {deleteVendor:assignedVendor} = data || {};
  return [deleteVendor, {assignedVendor, deleteVendorLoading:loading}];
}
