import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';

export default function FilteredListHeader({title, FilterPills, Actions, FilterForm, children}) {
  const [showFilters, setShowFilters] = useState(false);

  return (
    <Container className="flex-shrink-0" fluid>
      <Row className="pt-1 align-items-center bg-light">
        <Col sm={2}>
          <h4 className="fw-bold">{title}</h4>
        </Col>
      </Row>
      <Row className="pb-2 bg-light align-bottom">
        <Col className="mt-auto" sm={2}>
          <Form.Check id="show-filters" type="switch" label="Show Filters" checked={showFilters}
                      onChange={() => setShowFilters(!showFilters)}/>
        </Col>
        <Col className="mt-auto" sm={8}>
          {FilterPills}
        </Col>
        <Col className="mt-auto" sm={2}>
          {Actions}
        </Col>
      </Row>
      <Row>
        <Card className="m-2 flex-shrink-1 bg-light" border="primary" as={Col}>
          <Collapse in={showFilters}>
            <div>
              <Card.Body>
                {FilterForm}
              </Card.Body>
            </div>
          </Collapse>
        </Card>
      </Row>
      {children}
    </Container>
  );
}