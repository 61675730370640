import {useCallback, useEffect, useState} from 'react';
import {gql, useMutation} from '@apollo/client';

const DELETE_PURCHASES_MUTATION = gql`
  mutation deletePurchases($ids:[Int!]!){
      deletePurchases(ids:$ids){
          message
      }
  }
`;

export default function useBulkDeletePurchases(refetchPurchases){
  const [selectedPurchases, setSelectedPurchases] = useState([]);
  const [gqlDeletePurchase, {data, loading, error}] = useMutation(DELETE_PURCHASES_MUTATION);

  useEffect(() => {
    if(loading || !data){
      return;
    }
    const {deletePurchases} = data;
    const {message} = deletePurchases || {};

    if(message){
      refetchPurchases();
    }
  }, [data, loading, refetchPurchases]);

  const setBulkDeletePurchases = useCallback((purchase, checked) => {
    setSelectedPurchases(selectedPurchases => {
      let result;
      if (checked) {
        result = [...selectedPurchases, purchase];
      } else {
        result = selectedPurchases.filter(({id}) => id !== purchase.id);
      }
      return result;
    });
  }, [setSelectedPurchases]);

  const bulkDeletePurchases = useCallback(() => {
    gqlDeletePurchase({variables:{ids: selectedPurchases.map(({id}) => id)}});
  },[gqlDeletePurchase, selectedPurchases]);

  if(error){
    throw error;
  }

  return [bulkDeletePurchases, selectedPurchases, setBulkDeletePurchases];
}
