import React, {useContext} from 'react';
import Card from 'react-bootstrap/Card';

import WorkOrderContext from '../../context/WorkOrderContext';

export default function PrintDescription() {
  const {request} = useContext(WorkOrderContext);
  return (
    <Card className="w-100">
      <Card.Header>Description of Request</Card.Header>
      <Card.Body>
        <pre className="border-0">{request}</pre>
      </Card.Body>
    </Card>
  );
}