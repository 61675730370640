import React, {useContext, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import QueryContext from '../../context/QueryContext';
import SessionContext from '../../context/session';

import ACMDateRangePicker from '../../components/ACMDateRangePicker';
import ClientLookup from '../../components/ClientLookup';
import DivisionPicker from '../../components/DivisionPicker';
import FormGroup from '../../components/FormGroup';
import NullableBooleanToggleButtonGroup from '../../components/NullableBooleanToggleButtonGroup';
import TechnicianFormGroup from '../../components/TechnicianFormGroup';
import WorkOrderSearch from '../../components/WorkOrderSearch';
import WorkOrderStatusPicker from '../../components/WorkOrderStatusPicker';
import WorkOrderTypePicker from '../../components/WorkOrderTypePicker';

import AssignedToMeOrAnyoneButtonGroup, {
  ANY,
  ASSIGNED_TO_ANYONE,
  ASSIGNED_TO_ME,
  NOT_ASSIGNED
} from './AssignedToMeOrAnyoneButtonGroup';
import UserFormGroup from './UserFormGroup';

const {SFM_WORK_ORDER_KEYWORD_SEARCH_THRESHOLD} = window;

export default function WorkOrderFilterForm({workOrderCount}) {
  const {query, handleUpdateQuery} = useContext(QueryContext);
  const {user, isTeacher} = useContext(SessionContext);
  const [controlKeywords, setControlKeywords] = useState('');

  const {
    startDate, endDate, clientIDs = [], statusIDs = [], typeIDs = [], divisionIDs = [], technicianIDs = [],
    requesterIDs = [], workOrderIDs = [], isAssigned, isTimeCharged, isAllTasksComplete
  } = query;

  let assignedToState = ANY;
  if (technicianIDs.length === 1 && technicianIDs[0] === user.userID) {
    assignedToState = ASSIGNED_TO_ME;
  } else if (isAssigned) {
    assignedToState = ASSIGNED_TO_ANYONE;
  } else if (isAssigned === false) {
    assignedToState = NOT_ASSIGNED;
  }

  let taskProgressState = null;
  if (isTimeCharged && isAllTasksComplete === false) {
    taskProgressState = false;
  } else if (isAllTasksComplete) {
    taskProgressState = true;
  }

  function handleDatesChange(newDates) {
    handleUpdateQuery({...query, ...newDates});
  }

  function handleAddClient({id}) {
    if (!clientIDs.includes(id)) {
      handleUpdateQuery({...query, clientIDs: [...clientIDs, id]});
    }
  }

  function handleAddStatus({id}) {
    if (!statusIDs.includes(id)) {
      handleUpdateQuery({...query, statusIDs: [...statusIDs, id]});
    }
  }

  function handleAddType({id}) {
    if (!typeIDs.includes(id)) {
      handleUpdateQuery({...query, typeIDs: [...typeIDs, id]});
    }
  }

  function handleAddDivision({id}) {
    if (!divisionIDs.includes(id)) {
      handleUpdateQuery({...query, divisionIDs: [...divisionIDs, id]});
    }
  }

  function handleAddTechnician({id}) {
    if (!technicianIDs.includes(id)) {
      handleUpdateQuery({...query, technicianIDs: [...technicianIDs, id], isAssigned: undefined});
    }
  }

  function handleSetKeywordsFilter() {
    handleUpdateQuery({...query, keywords: controlKeywords});
    setControlKeywords('');
  }

  function handleControlKeywordKeyDown(e) {
    if (e.key === 'Enter') {
      handleSetKeywordsFilter();
    }
  }

  function handleIsAssignedChange(isAssigned) {
    let vTechnicianIDs = technicianIDs;
    if (null !== isAssigned) {
      vTechnicianIDs = [];
    }

    handleUpdateQuery({...query, isAssigned, technicianIDs: vTechnicianIDs});
  }

  function handleAddRequester({id}) {
    if (!requesterIDs.includes(id)) {
      handleUpdateQuery({...query, requesterIDs: [...requesterIDs, id]});
    }
  }

  function handleAddWorkOrder({id}) {
    if (!workOrderIDs.includes(id)) {
      handleUpdateQuery({...query, workOrderIDs: [...workOrderIDs, id]});
    }
  }

  function handleAssignedToChanged(newAssignedToState) {
    if (newAssignedToState === ASSIGNED_TO_ME) {
      handleAddTechnician({id: user.userID});
    } else if (newAssignedToState === ASSIGNED_TO_ANYONE) {
      handleIsAssignedChange(true);
    } else if (newAssignedToState === NOT_ASSIGNED) {
      handleIsAssignedChange(false);
    } else if (newAssignedToState === ANY) {
      handleUpdateQuery({...query, isAssigned: null, technicianIDs: []});
    }
  }

  function handleTaskProgressChange(newTaskProgressState) {
    let isTimeCharged = undefined;
    let isAllTasksComplete = undefined;
    if ('boolean' === typeof newTaskProgressState) {
      isAllTasksComplete = newTaskProgressState;
      if (!newTaskProgressState) {
        isTimeCharged = true;
      }
    }
    handleUpdateQuery({...query, isTimeCharged, isAllTasksComplete});
  }

  const isCountOverKeywordThreshold = workOrderCount > SFM_WORK_ORDER_KEYWORD_SEARCH_THRESHOLD;
  return (
    <Form>
      <Row className="align-items-start">
        <FormGroup controlId="workOrder" sm={3} label="Work Order Number">
          <WorkOrderSearch onSelectWorkOrder={handleAddWorkOrder}/>
        </FormGroup>
        <FormGroup controlId="date" sm={3} label="Submitted Date">
          <InputGroup>
            <ACMDateRangePicker startDate={startDate} endDate={endDate} onDatesChange={handleDatesChange}/>
          </InputGroup>
        </FormGroup>
        {
          !user.constrainWOToOwnSchool()
          && (
            <FormGroup controlId="client" sm={3} label="Client">
              <ClientLookup onClientSelect={handleAddClient} controlShouldRenderValue={false}/>
            </FormGroup>
          )
        }
        <FormGroup controlId="status" sm={3} label="Status">
          <WorkOrderStatusPicker onStatusSelect={handleAddStatus} controlShouldRenderValue={false}/>
        </FormGroup>
        {
          !isTeacher
          && (
            <>
              <FormGroup controlId="type" sm={3} label="Type">
                <WorkOrderTypePicker onTypeSelect={handleAddType} controlShouldRenderValue={false}/>
              </FormGroup>
              <FormGroup controlId="division" sm={3} label="Division">
                <DivisionPicker onDivisionSelect={handleAddDivision} controlShouldRenderValue={false}/>
              </FormGroup>
            </>
          )
        }
        {
          !user.constrainWOToSelf()
          && (<UserFormGroup sm={3} label="Requester" user={null} onSelectUser={handleAddRequester}/>)
        }
        {
          user.canQueryTechnicians()
          && (
            <TechnicianFormGroup sm={3} label="Assigned to" technician={null} onSelectTechnician={handleAddTechnician}/>
          )
        }
        {
          !user.canQueryTechnicians()
          && !isTeacher
          && (
            <Form.Group controlId="assignedToMeControl" as={Col}>
              <Col>&nbsp;</Col>
              <AssignedToMeOrAnyoneButtonGroup value={assignedToState} onChange={handleAssignedToChanged}/>
            </Form.Group>
          )
        }
        {
          !isTeacher
          && user.canQueryTechnicians()
          && (
            <Form.Group controlId="assignedTo" as={Col} sm={9} md={6} xl={3}>
              <Col>&nbsp;</Col>
              <NullableBooleanToggleButtonGroup name="isAssigned" trueLabel="Assigned" falseLabel="Not Assigned"
                                                nullLabel="Any" value={isAssigned} onChange={handleIsAssignedChange}/>
            </Form.Group>
          )
        }
        {
          user.canQueryWorkOrderProgress()
          && (
            <Form.Group controlId="taskProgressControl" as={Col} sm={9} md={6} xl={3}>
              <Col>&nbsp;</Col>
              <NullableBooleanToggleButtonGroup name="taskProgress" trueLabel="Tasks Complete"
                                                falseLabel="Tasks Started" nullLabel="Any" value={taskProgressState}
                                                onChange={handleTaskProgressChange}/>
            </Form.Group>
          )
        }
        <Form.Group controlId="keywords" as={Col} sm={6}>
          <Form.Label>Keywords</Form.Label>
          <InputGroup>
            <Form.Control className="sfm-input" value={controlKeywords} disabled={isCountOverKeywordThreshold}
                          onChange={e => setControlKeywords(e.target.value)} onKeyDown={handleControlKeywordKeyDown}/>
            <Button onClick={handleSetKeywordsFilter}>Set Keywords</Button>
          </InputGroup>
          <Form.Text muted>
            Optional: Use a '+' to indicate a word must be found. Use a '-' to indicate a word must <em>not</em> be
            found. Use '*' for wildcard searches. ("+fire +alarm", "+alarm -test", "drain*")
          </Form.Text>
          {
            isCountOverKeywordThreshold &&
            (
              <Form.Text className="text-warning">
                Keyword search disabled until filter produces {SFM_WORK_ORDER_KEYWORD_SEARCH_THRESHOLD} records or
                less
              </Form.Text>
            )
          }
        </Form.Group>
      </Row>
    </Form>
  );
}