import React from 'react';

import useDivisions, {UNSPECIFIED_DIVISION} from '../hooks/useDivisions';
import SFMItemSelect from '../components/SFMItemSelect';

export default function DivisionPicker({division, disabled, onDivisionSelect, ...props}) {
  const {divisions, divisionsLoading} = useDivisions();

  const options = divisions && [...divisions, UNSPECIFIED_DIVISION];

  let value = division;
  if (('undefined' === typeof division) && divisions?.length) {
    value = divisions[0];
  }
  return (
    <SFMItemSelect value={value || UNSPECIFIED_DIVISION} options={options} placeholder="Division"
                   isSearchable={false} isLoading={divisionsLoading} isDisabled={disabled} onChange={onDivisionSelect}
                   required {...props}/>
  );
}
