import React from 'react';

import ImageSelect from '../ImageSelect';
import FormGroup from '../FormGroup';

export default function ImagesFormGroup({images, readOnly, onSelect, onDelete}) {
  return (
    <FormGroup controlId="images" label="Images">
      <ImageSelect images={images} disabled={readOnly} onSelect={onSelect} onDelete={onDelete} required/>
    </FormGroup>
  );
}
