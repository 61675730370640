import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';

import useAddVendorAlias from '../hooks/useAddVendorAlias';
import useVendorSearch from '../hooks/useVendorSearch';

import FormGroup from './FormGroup';

export default function AddAliasDialog({vendor, onClose}) {
  const [name, setName] = useState('');
  const [validated, setValidated] = useState(false);
  const searchVendors = useVendorSearch();
  const [similarVendors, setSimilarVendors] = useState([]);
  const [addVendorAlias, {addVendorAliasLoading}] = useAddVendorAlias();

  const vendorExists = !!similarVendors.find(sv => sv.trim().toLowerCase() === name.trim().toLowerCase());

  async function handleSubmit(e) {
    e.preventDefault();
    setValidated(true);

    if (vendorExists || !name || !vendor) {
      return;
    }

    const {error} = await addVendorAlias(vendor, name);
    if (!error) {
      onClose();
    }
  }

  async function handleAliasChange({target}) {
    const name = target.value;
    setName(name);

    let similarVendors = [];
    if (name) {
      const vendors = await searchVendors(name);
      similarVendors = vendors.map(({name}) => name);
    }
    setSimilarVendors(similarVendors);
  }

  let feedback = 'Alias is required';
  let validity = 'valid';
  if (vendorExists) {
    feedback = 'Alias name exists';
    validity = 'invalid';
  }

  return (
    <Modal onHide={onClose} show animation>
      <Form validated={validated} onSubmit={handleSubmit} noValidate>
        <Modal.Header>
          <Modal.Title><h4 className="fw-bold">Add Alias to {vendor.name}</h4></Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          <Row className="flex-shrink-0">
            <FormGroup controlId="alias" label="Alias name" sm={12}>
              <Form.Control placeholder="Alias" value={name} className={validated && `is-${validity}`}
                            disabled={addVendorAliasLoading} onChange={handleAliasChange} required/>
              <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
            </FormGroup>
          </Row>
          <Row className="flex-shrink-1 flex-grow-0">
            <FormGroup className="d-flex flex-column" controlId="similar-vendors" style={{height: '275px'}}
                       label="Similar Vendors" sm={12}>
              <ListGroup className="overflow-auto flex-shrink-1">
                {similarVendors.map(sv => (<ListGroup.Item key={sv}>{sv}</ListGroup.Item>))}
              </ListGroup>
            </FormGroup>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" disabled={addVendorAliasLoading}>Add Alias</Button>
          <Button variant="outline-primary" disabled={addVendorAliasLoading} onClick={onClose}>Cancel</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
