import React, {useContext} from 'react';
import Card from 'react-bootstrap/Card';

import StatusHistoryContext from '../../context/StatusHistoryContext';

import StatusHistoryTable from './StatusHistoryTable';

export default function StatusHistoryCard() {
  const {statusHistory} = useContext(StatusHistoryContext);
  return (
    <Card body>
      <StatusHistoryTable statusHistory={statusHistory}/>
    </Card>
  );
}