import {gql, useQuery} from '@apollo/client';

import PurchaseList from '../graphql/purchase-list';

const SPLITS_EXIST_QUERY = gql`
    query purchasesConnection{
        purchasesConnection(isSplit:true) @connection(key:"purchases"){
            next
            hasMore
            total
            purchases{
                ...PurchaseListPurchase
            }
        }
    }
    ${PurchaseList.fragments.purchase}
`;

export default function useSplitsExist() {
  const {data, loading, error} = useQuery(SPLITS_EXIST_QUERY, {fetchPolicy: 'cache-and-network'});

  if (error) {
    throw error;
  }

  const {purchasesConnection} = data || {};
  const {total} = purchasesConnection || {};

  return {splitsCount: total, splitsLoading: loading};
}
