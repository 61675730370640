import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Navigate, useNavigate, useLocation} from 'react-router-dom';
import queryString from 'query-string';

import useConnection from '../../hooks/useConnection';
import Items from './Items';

export default function ConnectionPagination({connection, pageSize, onChangePageSize}) {
  const {pathname, search} = useLocation();
  const {page, ...queryParams} = queryString.parse(search);
  const {pageCount} = useConnection(connection, pageSize);
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  useEffect(() => setValidated(false), [setValidated, pageSize]);
  if (page > pageCount) {
    return (<Navigate to={`${pathname}?${queryString.stringify({...queryParams, page: pageCount})}`} replace/>);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setValidated(true);
    const {value} = e.target.goTo;
    const targetPage = parseInt(value);
    if (targetPage > 0 && targetPage <= pageCount) {
      navigate(`${pathname}?${queryString.stringify({...queryParams, page: targetPage})}`);
    }
  }

  function handlePageSizeChange({target}) {
    onChangePageSize(parseInt(target.value));
  }

  return (
    <Row className="my-2 justify-content-center">
      <Col sm={6}>
        <Pagination>
          <Form>
            <Form.Group className="me-2" controlId="records-per-page">
              <Form.Select value={pageSize} onChange={handlePageSizeChange}>
                <option>10</option>
                <option>25</option>
                <option>50</option>
              </Form.Select>
              <Form.Label className="mx-3">Records Per Page</Form.Label>
            </Form.Group>
          </Form>
          <Items connection={connection} pageSize={pageSize}/>
          <Form className="ms-2" onSubmit={handleSubmit} validated={validated}>
            <Form.Group controlId="goto-page">
              <InputGroup>
                <Form.Control type="number" name="goTo" placeholder="1" min={1} max={pageCount}/>
                <Button type="submit" variant="outline-primary" size="sm">Go</Button>
              </InputGroup>
              <Form.Control.Feedback type="invalid">Page must be between 1 and {pageCount}</Form.Control.Feedback>
              <Form.Label className="ms-3 me-2">Go to page</Form.Label>
            </Form.Group>
          </Form>
        </Pagination>
      </Col>
    </Row>
  );
}
