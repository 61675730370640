import React, {Fragment, useContext} from 'react';
import Table from 'react-bootstrap/Table';

import TasksContext from '../../context/TasksContext';

import {formatDate} from '../../lib/timeUtils';

export default function PrintTasksAndTimeTable() {
  const {tasks, tasksLoading} = useContext(TasksContext);
  if (tasksLoading || !tasks?.length) {
    return null;
  }

  return (
    <Table size="sm" bordered>
      <thead className="bg-secondary page-break">
      <tr>
        <th className="text-center" colSpan="15">Tasks and Time</th>
      </tr>
      </thead>
      <tbody>
      {
        tasks.map(({id, description, technician, completeDate, timeSheets}) => (
          <Fragment key={`task-${id}`}>
            <tr>
              <td className="fw-bold" colSpan="5">{description}</td>
              <td className="fw-bold" colSpan="5">{technician?.firstName} {technician?.lastName}</td>
              <td className="fw-bold" colSpan="5">
                <span className="fw-bold">Completed:</span>
                {formatDate(completeDate)}
              </td>
            </tr>
            {
              timeSheets?.length
                ? timeSheets.map(({id, technician, clockIn, totalTime, job, notes}) => (
                  <tr key={`timeSheet-${id}`}>
                    <td colSpan="3">{technician.firstName} {technician.lastName}</td>
                    <td colSpan="3">{formatDate(clockIn)}</td>
                    <td colSpan="3">{totalTime.substring(0, totalTime.lastIndexOf(':'))}</td>
                    <td colSpan="3">{job?.name}</td>
                    <td colSpan="3">{notes}</td>
                  </tr>
                ))
                : (
                  <tr>
                    <td className="text-center" colSpan="15">No Time Entered</td>
                  </tr>
                )
            }
          </Fragment>
        ))
      }
      </tbody>
    </Table>
  );
}