import React, {useContext} from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

import SessionContext from '../../context/session';
import WorkOrderContext from '../../context/WorkOrderContext';

import {PRIORITY_VALUE_NAME_MAP} from '../../lib/priorities';
import {UNSPECIFIED_DIVISION} from '../../hooks/useDivisions';

import FormattedDate from '../FormattedDate';

export default function PrintCoreDetails() {
  const {isTeacher} = useContext(SessionContext);
  const {school, priority, workOrderType, division, requestDate, date} = useContext(WorkOrderContext);

  return (
    <Card>
      <Card.Header>Work Order Information</Card.Header>
      <Card.Body className="p-1">
        <Table size="sm" borderless>
          <tbody>
          <tr>
            <td className="fw-bold">Client</td>
            <td>{school?.name}</td>
          </tr>
          {
            !isTeacher && (
              <>
                <tr>
                  <td className="fw-bold">Priority</td>
                  <td>{PRIORITY_VALUE_NAME_MAP.get(priority)}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Type</td>
                  <td>{workOrderType?.name}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Division</td>
                  <td>{(division || UNSPECIFIED_DIVISION)?.name}</td>
                </tr>
              </>
            )
          }
          <tr>
            <td className="fw-bold">Submitted Date</td>
            <td><FormattedDate date={date}/></td>
          </tr>
          <tr>
            <td className="fw-bold">Requested Completion Date</td>
            <td><FormattedDate date={requestDate}/></td>
          </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}