import {gql, useQuery} from '@apollo/client';

const IMAGES_QUERY = gql`
    query images($ids: [Int!]!) {
        images(ids: $ids) {
            id
            uri
        }
    }
`;

//YELLOWTAG:TJH - we should start sharing source with the mobile app.
//  See mobile/src/hooks/useImages.js
export default function useImages(pImages) {
  const ids = pImages?.map(({id}) => id);
  const {data, loading, error} = useQuery(
    IMAGES_QUERY, {variables: {ids}, fetchPolicy: 'network-only', skip:!pImages?.length}
  );
  const {images} = data || {};

  return {images, imagesLoading: loading, imagesError: error};
}
