import {useContext} from 'react';
import {gql, useQuery} from '@apollo/client';

import SessionContext from '../context/session';

const GET_WORK_ORDER_QUERY = gql`
    query workOrders($ids:[Int!]!){
        workOrders(ids:$ids){
            id
            number
            abbreviation
            vNumber
            date
            requestDate
            name
            request
            isAssigned
            priority
            laborCosts
            materialCosts
            customerMaterialCosts
            purchaseCount
            school{
                id
                code
                name
            }
            status {
                id
                name
                meta
            }
            assignment{
                id
                closeComments
                closeDate
                labor
                materials
                total
            }
            division{
                id
                name
            }
            requester{
                id
                lastName
                firstName
                title
                department
                phone
                email
            }
            workOrderType{
                id
                name
            }
            images {
                id
            }
        }
    }
`;

const TEACHER_WORK_ORDER_QUERY = gql`
    query workOrders($ids:[Int!]!){
        workOrders(ids:$ids){
            id
            number
            abbreviation
            vNumber
            date
            requestDate
            request
            school{
                id
                code
                name
            }
            status {
                id
                name
            }
            requester{
                id
                lastName
                firstName
                title
                department
                phone
                email
            }
            images {
                id
            }
        }
    }
`;

export default function useWorkOrders(ids) {
  const {isTeacher} = useContext(SessionContext);
  let isNewWorkOrder = ids?.length === 1 && Number.isNaN(ids[0]);
  let graphqlQuery = GET_WORK_ORDER_QUERY;
  if(isTeacher){
      graphqlQuery = TEACHER_WORK_ORDER_QUERY;
  }
  const {data, loading, error, refetch} = useQuery(graphqlQuery,
    {variables: {ids}, fetchPolicy: 'cache-and-network', skip: isNewWorkOrder || !ids?.length});

  if (error && !(data && data.length)) {
    throw error;
  }

  const init = isNewWorkOrder ? {workOrders: [{}]} : {workOrders: []};
  const {workOrders} = data || init;

  return {workOrders, workOrderLoading: loading, refetchWorkOrders:refetch};
}