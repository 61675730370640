import {
  NumberParam,
  NumericArrayParam,
  StringParam,
  BooleanParam,
  useQueryParams,
  encodeQueryParams
} from 'use-query-params';
import queryString from 'query-string';

import {MomentParam} from './customQueryParams';

const PARAM_CONFIG_MAP = {
  startDate: MomentParam,
  endDate: MomentParam,
  clientIDs: NumericArrayParam,
  statusIDs: NumericArrayParam,
  typeIDs: NumericArrayParam,
  divisionIDs: NumericArrayParam,
  technicianIDs: NumericArrayParam,
  requesterIDs: NumericArrayParam,
  workOrderIDs: NumericArrayParam,
  isAssigned: BooleanParam,
  keywords: StringParam,
  isAllTasksComplete: BooleanParam,
  isTimeCharged: BooleanParam,
  page: NumberParam
};

export default function useWorkOrderQueryParams() {
  return useQueryParams(PARAM_CONFIG_MAP);
}

export function toUrl(query, {pathname}) {
  const qs = queryString.stringify(encodeQueryParams(PARAM_CONFIG_MAP, query));
  return `${pathname}?${qs}`;
}

export const BASE_QUERY = Object.freeze({
  page: 1,
  startDate: undefined,
  endDate: undefined,
  clientIDs: undefined,
  statusIDs: undefined,
  typeIDs: undefined,
  divisionIDs: undefined,
  technicianIDs: undefined,
  requesterIDs: undefined,
  workOrderIDs: undefined,
  isAssigned: undefined,
  keywords: undefined,
  isAllTasksComplete: undefined,
  isTimeCharged: undefined
});

export function isNewQueryMoreInclusive(oldQuery, newQuery) {
  return collectionsExpand(oldQuery, newQuery)
    || dateRangeExpands(oldQuery, newQuery)
    || booleansExpand(oldQuery, newQuery);
}

function collectionsExpand(oldQuery, newQuery) {
  return ['clientIDs', 'statusIDs', 'typeIDs', 'divisionIDs', 'requesterIDs', 'workOrderIDs']
    .find(key => {
      const oldCollection = oldQuery[key];
      const newCollection = newQuery[key];
      return (oldCollection && !newCollection)
        || (oldCollection
          && newCollection
          && (oldCollection.length < newCollection.length));
    });
}

function dateRangeExpands(oldQuery, newQuery) {
  return oldQuery.endDate
    && newQuery.endDate
    && oldQuery.endDate.diff(oldQuery.startDate) < newQuery.endDate.diff(newQuery.startDate);
}

function booleansExpand(oldQuery, newQuery) {
  return ['isAssigned', 'isAllTasksComplete', 'isTimeCharged']
    .find(attr => ('boolean' === typeof oldQuery[attr]) && (oldQuery[attr] !== newQuery[attr]));
}
