import React from 'react';

import useVendorSearch from '../hooks/useVendorSearch';
import ACMQuerySelect from './ACMQuerySelect';

export default function VendorLookup({vendor, disabled, required, onVendorSelect, filter}) {
  const searchVendors = useVendorSearch(filter);

  return (
    <ACMQuerySelect placeholder="Select Vendor" selected={vendor} fetch={searchVendors} isDisabled={disabled}
                    required={required} onSelect={onVendorSelect} renderLabel={({name}) => name}/>
  );
}
