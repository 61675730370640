import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {faKey, faQuestion, faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import zxcvbn from 'zxcvbn';

const STRENGTH_TEXT = ['Very Weak', 'Weak', 'Good', 'Strong', 'Very Strong'];
const STRENGTH_CLASS = ['danger', 'warning', 'warning', 'success', 'success'];

export default function ResetPasswordForm({onSubmit}) {
  const [disabled, setDisabled] = useState(true);
  const [strength, setStrength] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmed, setConfirmed] = useState(null);

  function checkStrength({target}) {
    const password = target.value;
    const {score} = zxcvbn(password);

    setPassword(password);
    setStrength(score);
    setConfirmed(null);
  }

  function checkConfirm({target}) {
    let confirmed = null;
    if (target.value) {
      confirmed = password === target.value;
    }
    setConfirmed(confirmed);
    setDisabled(!confirmed);
  }

  function handleSubmit(event) {
    event.preventDefault();

    setDisabled(true);
    onSubmit(password);
  }

  let matchString = null;
  if (null !== confirmed) {
    matchString = confirmed
      ? 'match'
      : 'do not match';
  }
  const transition = {transition: 'all .3s ease-in-out'};
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="passwordInput">
        <InputGroup>
          <InputGroup.Text id="password" as="span"
                              className={`flex-column justify-content-center px-2 bg-secondary border rounded-left`}
                              style={transition}>
            <FontAwesomeIcon className={`text-${STRENGTH_CLASS[strength]}`} icon={faKey}/>
          </InputGroup.Text>
          <Form.Control placeholder="enter a new password" as="input" type="password" aria-describedby="password"
                        onChange={checkStrength}/>
        </InputGroup>
        <Form.Text className="text-muted">
          Strength:&nbsp;
          <span className={`text-${STRENGTH_CLASS[strength]}`}>{STRENGTH_TEXT[strength]}</span>
        </Form.Text>
      </Form.Group>
      <Form.Group controlId="confirmationInput">
        <InputGroup>
          <InputGroup.Text id="confirmation" as="span"
                              className={`flex-column justify-content-center px-2 bg-secondary border rounded-left`}
                              style={transition}>
            <FontAwesomeIcon className={confirmed ? 'text-primary' : ''} icon={confirmed ? faCheck : faQuestion}/>
          </InputGroup.Text>
          <Form.Control placeholder="confirm your new password" as="input" type="password"
                        aria-describedby="confirmationInput" onChange={checkConfirm}/>
        </InputGroup>
        <Form.Text className="text-muted">
          Passwords {matchString}
        </Form.Text>
      </Form.Group>
      <div className="d-grid">
        <Button type="submit" disabled={disabled}>Reset Password</Button>
      </div>
    </Form>
  );
}
