import {createContext} from 'react';
import moment from 'moment';
import {Views} from 'react-big-calendar';

function NO_OP() {}

export default createContext({
  startDateTime: moment().startOf('week'),
  endDateTime: moment().endOf('week'),
  calendarDate: moment(),
  calendarView: Views.WEEK,
  earliestPayrollDate: moment(0),
  selectedTimeSheet: null,
  popupPortalRef: null,
  getDefaultSchool: NO_OP,
  setDefaultSchool: NO_OP,
  getDefaultJob: NO_OP,
  setDefaultJob: NO_OP,
  setStartDate: NO_OP,
  setEndDate: NO_OP,
  setCalendarDate: NO_OP,
  setCalendarView: NO_OP,
  setSelectedTimeSheet: NO_OP
});