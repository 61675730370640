import React from 'react';
import useBanks from '../hooks/useBanks';
import ACMSelect from './ACMSelect';

export default function BankPicker({bank, onBankSelect, ...props}) {
  const {banks, banksLoading} = useBanks();
  return (
    <ACMSelect placeholder="Bank" selected={bank || null} values={banks}
               isLoading={banksLoading} onSelect={onBankSelect} renderLabel={({name}) => name} {...props}/>
  );
}
