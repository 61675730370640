import moment from 'moment';
import {useState} from 'react';

export default function usePurchaseState(purchase) {
  const [isSplit, setIsSplit] = useState(purchase.isSplit || false);
  const [job, setJob] = useState(purchase.job);
  const [purchaseType, setPurchaseType] = useState(purchase.purchaseType);
  const [vendor, setVendor] = useState(purchase.vendor);
  const [date, setDate] = useState(purchase.date ? moment(purchase.date) : moment());
  const [amount, internalSetAmount] = useState(purchase.amount && Math.abs(purchase.amount));
  const [notes, setNotes] = useState(purchase.notes || '');
  const [task, setTask] = useState(purchase.task);
  const [technician, setTechnician] = useState(purchase.technician);
  const [isReturn, setIsReturn] = useState(purchase.amount && purchase.amount < 0);

  function setAmount(amount) {
    internalSetAmount(amount && Math.abs(amount));
  }

  const isValid = date && amount && purchaseType && notes && (isSplit || job);
  const existing = purchase && !!purchase.id;
  return {
    ...purchase,
    isSplit, setIsSplit,
    job, setJob,
    purchaseType, setPurchaseType,
    vendor, setVendor,
    date, setDate,
    amount, setAmount,
    notes, setNotes,
    task, setTask,
    technician, setTechnician,
    isReturn, setIsReturn,
    isValid, existing
  };
}
