import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import PurchaseFilterForm from '../../components/PurchaseFilterForm';
import PurchaseFilterPills from '../../components/PurchaseFilterPills';

export default function Header({onNewPurchase}) {
  const [showFilters, setShowFilters] = useState(false);
  return (
    <Container className="flex-shrink-0" fluid>
      <Row className="p-4 bg-light flex-shrink-0">
        <Col sm={10}><h4 className="fw-bold">Purchases</h4></Col>
      </Row>
      <Row>
        <Col sm={2}>
          <Form.Check id="show-filters" type="switch" label="Show Filters" checked={showFilters}
                      onChange={() => setShowFilters(!showFilters)}/>
        </Col>
        <Col sm={8}>
          <Container>
            <PurchaseFilterPills/>
          </Container>
        </Col>
        <Col sm={2}>
          <Button variant="primary" onClick={onNewPurchase}>New Purchase</Button>
        </Col>
      </Row>
      <Row>
        <Card className="m-2 flex-shrink-1 bg-light" border="primary" as={Col}>
          <Collapse in={showFilters}>
            <div>
              <Card.Body>
                <PurchaseFilterForm/>
              </Card.Body>
            </div>
          </Collapse>
        </Card>
      </Row>
    </Container>
  );
}
