import React from 'react';

import SFMFilterPill from './SFMFilterPill';

export default function TechnicianPills({technicians, onRemoveTechnician}) {
  if(!technicians?.length){
    return null;
  }

  return technicians.map(tech => (
    <SFMFilterPill key={`tech-${tech.id}`} onRemove={() => onRemoveTechnician(tech)}>
      {`${tech.firstName} ${tech.lastName}`}
    </SFMFilterPill>
  ));
}