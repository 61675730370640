import {gql, useQuery} from '@apollo/client';

import PurchaseList from '../graphql/purchase-list';

const GET_PURCHASE_QUERY = gql`
    query getPurchase($id:Int!){
        getPurchase(id:$id){
            ...PurchaseListPurchase
        }
    }
    ${PurchaseList.fragments.purchase}
`;

export default function usePurchase(id) {
  const {data, loading, error} = useQuery(GET_PURCHASE_QUERY, {variables: {id}, fetchPolicy: 'network-only'});

  if (error && !(data && data.length)) {
    throw error;
  }

  const {getPurchase: purchase} = data || {};

  return {purchase, purchaseLoading: loading};
}
