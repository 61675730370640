import {gql, useQuery} from '@apollo/client';

const JOBS_QUERY = gql`
    query jobs($ids:[Int!]!){
        jobs(ids:$ids){
            id
            name
            isSFMPurchaseAssignable
            school {
                id
                code
            }
        }
    } `;

export default function useJobs(jobIDs) {
  const {data, loading, error} = useQuery(JOBS_QUERY, {
    variables: {ids: jobIDs},
    skip: !(jobIDs && jobIDs.length)
  });

  if (error) {
    throw error;
  }

  const {jobs} = data || {};
  return {jobs, jobsLoading: loading};
}
