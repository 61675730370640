import React from 'react';
import {faEdit} from '@fortawesome/free-regular-svg-icons';

import IconButton from './IconButton';

export default function EditButton({id, ...props}) {
  return (
    <IconButton id={id} overlayTip="Edit" icon={faEdit} {...props}/>
  );
}
