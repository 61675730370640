import React from 'react';

import SFMFilterPill from './SFMFilterPill';

export default function ClientPills({clients, onRemoveClient}){
  if(!clients){
    return null;
  }

  return clients.map(client => (
    <SFMFilterPill key={`client-${client.id}`} onRemove={() => onRemoveClient(client)}>
      {client.name}
    </SFMFilterPill>
  ));
}