import {NumericArrayParam, NumberParam, useQueryParams, encodeQueryParams} from 'use-query-params';
import queryString from 'query-string';

import {MomentParam} from './customQueryParams';

const PARAM_CONFIG_MAP = {
  technicianIDs: NumericArrayParam,
  startDate: MomentParam,
  endDate: MomentParam,
  page: NumberParam
};

export default function useLocationsQueryParams() {
  return useQueryParams(PARAM_CONFIG_MAP);
}

export function toUrl(query, {pathName}) {
  const qs = queryString.stringify(encodeQueryParams(PARAM_CONFIG_MAP, query));
  return `${pathName}?${qs}`;
}

export const BASE_QUERY = Object.freeze({
  page: 1,
  technicianIDs: undefined,
  startDate: undefined,
  endDate: undefined
});