import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useReactToPrint} from 'react-to-print';

import WorkOrderContext from '../../context/WorkOrderContext';
import TasksContext from '../../context/TasksContext';
import StatusHistoryContext from '../../context/StatusHistoryContext';
import CommentsContext from '../../context/CommentsContext';

import useAddWorkOrder from '../../hooks/useAddWorkOrder';
import useImages from '../../hooks/useImages';
import useStatusHistory from '../../hooks/useStatusHistory';
import useTasks from '../../hooks/useTasks';
import useUpdateWorkOrder from '../../hooks/useUpdateWorkOrder';
import useWorkOrderState from '../../hooks/useWorkOrderState';
import useWorkOrderComments from '../../hooks/useWorkOrderComments';

import EditWorkOrderForm from './EditWorkOrderForm';
import PrintedWorkOrder from './PrintedWorkOrder';

export default function EditWorkOrder({workOrder, onFinished}) {
  const tasksContext = useTasks(workOrder?.id);
  const workOrderState = useWorkOrderState(workOrder, tasksContext?.tasks);
  const statusHistoryContext = useStatusHistory(workOrder?.id);
  const commentsContext = useWorkOrderComments(workOrder?.id);
  const {images, imagesError, imagesLoading} = useImages(workOrder?.images);
  const [validated, setValidated] = useState(false);
  const [proposedWOIs, setProposedWOIs] = useState([]);
  const [deletedWOIs, setDeletedWOIs] = useState([]);
  const printingRef = useRef();
  const handlePrint = useReactToPrint({content: () => printingRef.current});

  const options = {onCompleted: onFinished};
  const [addWorkOrder, {addWorkOrderLoading, addWorkOrderError}] = useAddWorkOrder(options);
  const [updateWorkOrder, {updateWorkOrderLoading, updateWorkOrderError}] = useUpdateWorkOrder(options);

  useEffect(() => {
    if (!images) {
      return;
    }
    setProposedWOIs(pIs => [...images, ...pIs]);
  }, [images, setProposedWOIs]);

  const {isValid} = workOrderState;
  const error = addWorkOrderError || updateWorkOrderError || imagesError;

  if (error) {
    console.error(error);
    throw error;
  }

  const handleOnImageChange = useCallback(newImages => {
    setProposedWOIs(wois => [...wois, ...newImages]);
  }, [setProposedWOIs]);

  const handleDeleteImage = useCallback(image => {
    setProposedWOIs(wois => wois.filter(woi => woi !== image));
    if (image.id) {
      setDeletedWOIs(i => [...i, image]);
    }
  }, [setProposedWOIs, setDeletedWOIs]);

  async function handleSubmit(e) {
    e.preventDefault();
    setValidated(true);
    if (!isValid) {
      return;
    }

    const {id} = workOrder || {};
    const {
      school, costCenter, requestDate, request, name, workOrderType, division, status, priority,
      tasks, comments
    } = workOrderState;
    const newWorkOrder = {
      id, school, costCenter, requestDate, request, name, workOrderType, division, status, priority,
      images: proposedWOIs.filter(p => !p.id)
    };
    if (id) {
      updateWorkOrder({...newWorkOrder, deletedImageIDs: deletedWOIs.map(dP => dP.id)});
    } else {
      const taskRecords = tasks.map(({technician, description}) => ({techID: technician.id, description}));
      addWorkOrder({...newWorkOrder, tasks: taskRecords, comments});
    }
  }

  const loading = imagesLoading || addWorkOrderLoading || updateWorkOrderLoading;

  return (
    <WorkOrderContext.Provider value={workOrderState}>
      <TasksContext.Provider value={tasksContext}>
        <StatusHistoryContext.Provider value={statusHistoryContext}>
          <CommentsContext.Provider value={commentsContext}>
            <EditWorkOrderForm images={proposedWOIs} validated={validated} loading={loading}
                               onImagesChanged={handleOnImageChange} onImageDeleted={handleDeleteImage}
                               onSubmit={handleSubmit} onCancel={onFinished} onPrint={handlePrint}/>
            <div className="d-none">
              <PrintedWorkOrder ref={printingRef}/>
            </div>
          </CommentsContext.Provider>
        </StatusHistoryContext.Provider>
      </TasksContext.Provider>
    </WorkOrderContext.Provider>
  );
}
