import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import RouterButton from './RouterButton';

export default function SFMNotice({show, msg, ...props}) {
  return !!show && (
    <Row className="bg-warning p-2" style={{color: 'white'}}>
      <Col sm={12}>
        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" style={{marginRight: 12}}/>
        {msg}
        <RouterButton variant="outline-light" style={{marginLeft: 12}} {...props}>
          View
        </RouterButton>
      </Col>
    </Row>
  );
}
