import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Link} from 'react-router-dom';
import Button from 'react-bootstrap/Button';

export default function Header({vendor, onShowAddAlias}) {
  const {id, name} = vendor || {};
  let title = '';
  if(name){
    title = `Aliases for ${name}`;
  }

  return (
    <Row className={`bg-light flex-shrink-0 mb-3`}>
      <Col sm={12}>
        <Breadcrumb>
          <Link to="/app/vendors" className="breadcrumb-item">Vendors</Link>
          <Link to={`/app/purchases/vendor/${id}`} className="breadcrumb-item">{name}</Link>
          <Breadcrumb.Item active>Aliases</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Col className="ps-4" sm={10}>
        <h4 className="fw-bold">{title}</h4>
      </Col>
      <Col sm={2}>
        <Button variant="primary" type="button" onClick={onShowAddAlias}>Add Alias</Button>
      </Col>
    </Row>
  );
}
