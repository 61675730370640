import React, {Component} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import queryString from 'query-string';
import {QueryParamProvider} from 'use-query-params';
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6';
import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client';
import moment from 'moment';

import ErrorModal from './components/ErrorModal';
import Session from './components/Session';
import {apolloLink} from './controller/apollo-link';
import './App.scss';

const {parse, stringify} = queryString;

moment.updateLocale('en', {
  week: {
    dow: 1,
    doy: 4
  }
});

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {error: null};
    this.apolloClient = new ApolloClient({
      link: apolloLink,
      cache: new InMemoryCache()
    });
  }

  // noinspection JSUnusedGlobalSymbols
  static getDerivedStateFromError(error) {
    return {error: error};
  }

  render() {
    const {error} = this.state;
    if (error) {
      return (<ErrorModal message={error.message}/>);
    }

    return (
      <Router>
        <QueryParamProvider adapter={ReactRouter6Adapter}
                            options={{searchStringToObject: parse, objectToSearchString: stringify}}>
          <ApolloProvider client={this.apolloClient}>
            <Session/>
          </ApolloProvider>
        </QueryParamProvider>
      </Router>
    );
  }
}
