import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import useVendorPurchaseStats from '../../hooks/useVendorPurchaseStats';

import ConnectionPagination from '../../components/ConnectionPagination';
import DeleteVendorDialog from '../../components/DeleteVendorDialog';
import EditVendorDialog from '../../components/EditVendorDialog';
import MessageModal from '../../components/MessageModal';
import Header from './Header';
import UnknownVendorNotice from './UnknownVendorNotice';
import VendorList from './VendorList';

export default function VendorPage() {
  const {vendorsConnection, vendorsLoading, vendorsError, refetchVendors, pageSize, setPageSize}
    = useVendorPurchaseStats();
  const [uploadResponse, setUploadResponse] = useState();
  const [showEditVendor, setShowEditVendor] = useState(false);
  const [vendorToDelete, setVendorToDelete] = useState(null);

  if (vendorsError) {
    throw vendorsError;
  }

  function handleDeleteVendorClose() {
    setVendorToDelete(null);
    refetchVendors();
  }

  function handleCloseEditVendor() {
    setShowEditVendor(false);
    refetchVendors();
  }

  const {vendors} = vendorsConnection || {};
  return (
    <>
      <Container className="d-flex flex-column h-100" fluid>
        <Header onNewVendor={() => setShowEditVendor(true)}/>
        <UnknownVendorNotice/>
        <Row className="flex-shrink-1 flex-grow-1 overflow-auto" style={{minHeight: '33vh'}}>
          <VendorList vendors={vendors} loading={vendorsLoading} onDelete={setVendorToDelete}/>
        </Row>
        {vendorsConnection && <ConnectionPagination connection={vendorsConnection} pageSize={pageSize}
                                                    onChangePageSize={setPageSize}/>}
      </Container>
      {uploadResponse && (
        <MessageModal heading="Info" size="md" onDismissed={() => setUploadResponse(null)}>
          {uploadResponse}
        </MessageModal>
      )}
      {showEditVendor && (<EditVendorDialog onClose={handleCloseEditVendor}/>)}
      {vendorToDelete && (<DeleteVendorDialog vendor={vendorToDelete} onClose={handleDeleteVendorClose}/>)}
    </>
  );
}
